import React, { useState, useRef, useEffect } from "react";
import "../App.css";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import screenful from "screenfull";
import Controls from "../components/Controls";
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        width: "100%",
        position: "relative",
        // "&:hover": {
        //   "& $controlsWrapper": {
        //     visibility: "visible",
        //   },
        // },
        [theme.breakpoints.up('md')]: {
            minHeight: '407px',
        },

    },

    controlsWrapper: {
        visibility: "hidden",
        position: "absolute",
        marginBottom: '5px',
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    topControls: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2),
    },
    middleControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomWrapper: {
        display: "flex",
        flexDirection: "column",

        // background: "rgba(0,0,0,0.6)",
        // height: 60,
        padding: theme.spacing(2),
    },

    bottomControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // height:40,
    },

    button: {
        margin: theme.spacing(1),
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,
    },
    root: {
        fontSize: '16px',
        color: '#fff',
        background: '#2a2a2a',
        width: '100%',
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,

    },
    videoImage: {
        paddingRight: '10px',
    },
    circularProgress: {
        color: '#bfbfbf',
        width: '4rem !important',
        height: '4rem !important',
        [theme.breakpoints.down('xs')]: {
            width: '2rem !important',
            height: '2rem !important',
        },

    }
}));




const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};

let count = 0;

const GuestVideo = ({ url, id }) => {

    const classes = useStyles();


    const [videoReady, setVideoReady] = useState(false);
    const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
    const [seekCheck, setSeek] = useState(false)

    const [state, setState] = useState({
        pip: false,
        playing: false,
        controls: false,
        light: false,
        muted: false,
        played: 0,
        duration: 0,
        playbackRate: 1.0,
        volume: 1,
        loop: false,
        seeking: false,
    });
    const playerRef = useRef(null);
    const playerContainerRef = useRef(null);
    const controlsRef = useRef(null);
    //const canvasRef = useRef(null);
    const {
        playing,
        light,
        muted,
        loop,
        playbackRate,
        pip,
        played,
        volume,
    } = state;



    useEffect(() => {
        setLoading(true)
        setSeek(false)
        setVideoReady(true)

        return () => { setVideoReady(false) };
    }, [url])


    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing });


    };

    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    };

    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    };

    const [loading, setLoading] = useState(true)
    const handleProgress = (changeState) => {
        if (changeState.playedSeconds < changeState.loadedSeconds)
            setLoading(false)
        else if (changeState.playedSeconds > changeState.loadedSeconds) setLoading(true)

        if (count > 3) {
            controlsRef.current.style.visibility = "hidden";
            count = 0;
        }
        if (controlsRef.current.style.visibility === "visible") {
            count += 1;
        }
        if (!state.seeking) {
            setState({ ...state, ...changeState });
        }

    };



    const handleSeekChange = (e, newValue) => {
        setState({ ...state, played: parseFloat(newValue / 100) });
    };

    const handleSeekMouseDown = (e) => {
        setState({ ...state, seeking: true });
    };

    const handleSeekMouseUp = (e, newValue) => {
        setState({ ...state, seeking: false });
        playerRef.current.seekTo(newValue / 100, "fraction");
    };

    const handleDuration = (duration) => {
        setState({ ...state, duration });
    };

    const handleVolumeSeekDown = (e, newValue) => {
        setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });

    };
    const handleVolumeChange = (e, newValue) => {
        setState({
            ...state,
            volume: parseFloat(newValue / 100),
            muted: newValue === 0 ? true : false,
        });
    };

    const toggleFullScreen = () => {
        screenful.toggle(playerContainerRef.current);
    };

    const handleMouseMove = () => {
        if (seekCheck) {
            controlsRef.current.style.visibility = "visible";
            count = 0;
        }
    };

    const hanldeMouseLeave = () => {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
    };

    const handleDisplayFormat = () => {
        setTimeDisplayFormat(
            timeDisplayFormat === "normal" ? "remaining" : "normal"
        );
    };


    const handlePlaybackRate = (rate) => {
        setState({ ...state, playbackRate: rate });
    };

    const hanldeMute = () => {
        setState({ ...state, muted: !state.muted });
    };

    const currentTime =
        playerRef && playerRef.current
            ? playerRef.current.getCurrentTime()
            : "00:00";

    const duration =
        playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
    const elapsedTime =
        timeDisplayFormat === "normal"
            ? format(currentTime)
            : `-${format(duration - currentTime)}`;

    const totalDuration = format(duration);

    function ready() {
        setLoading(false)

        if (videoReady)
            setTimeout(() => setState({ ...state, playing: false }), 100);
        if (videoReady) {
            setTimeout(() =>
                setVideoReady(false), 100);

        }

        else setSeek(true)

    }
    function onBufferEnd() {
        setLoading(true)

    }

    return (

        <Grid item xs={12} lg={12} style={{
            background: 'rgb(42, 42, 42)'
        }}>
            <div
                onMouseMove={handleMouseMove}
                onMouseLeave={hanldeMouseLeave}
                ref={playerContainerRef}
                className={classes.playerWrapper}   >

                <ReactPlayer
                    onReady={ready}
                    width="100%"
                    height="100%"
                    url={`${url}`}
                    config={{ file: { attributes: { controlsList: 'nodownload' } }, style: { display: 'block' } }}
                    onContextMenu={e => e.preventDefault()}
                    ref={playerRef}
                    pip={pip}
                    playing={playing}
                    controls={false}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onProgress={handleProgress}
                    onBuffer={onBufferEnd}
                />


                {loading ? <div style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '100%'
                }}>
                    <Grid item xs={12} style={{
                        display: 'block',
                        left: 'calc(50%)',
                        marginLeft: '-17.5px',
                        marginTop: '-30px',
                        position: 'absolute',
                        top: 'calc(50%)',
                    }}>
                        <CircularProgress className={classes.circularProgress} />
                    </Grid>
                </div> : null}
                <Controls
                    ref={controlsRef}
                    onSeek={handleSeekChange}
                    onSeekMouseDown={handleSeekMouseDown}
                    onSeekMouseUp={handleSeekMouseUp}
                    onDuration={handleDuration}
                    onRewind={handleRewind}
                    onPlayPause={handlePlayPause}
                    onFastForward={handleFastForward}
                    playing={playing}
                    played={played}
                    elapsedTime={elapsedTime}
                    totalDuration={totalDuration}
                    onMute={hanldeMute}
                    muted={muted}
                    onVolumeChange={handleVolumeChange}
                    onVolumeSeekDown={handleVolumeSeekDown}
                    onChangeDispayFormat={handleDisplayFormat}
                    playbackRate={playbackRate}
                    onPlaybackRateChange={handlePlaybackRate}
                    onToggleFullScreen={toggleFullScreen}
                    volume={volume}
                />
            </div>

        </Grid>



    );
}
GuestVideo.propTypes = {
    url: PropTypes.string.isRequired
};

export default GuestVideo;