import React, { forwardRef, useRef, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import { Tooltip } from 'antd';
import Context from '../Context'

const useStyles = makeStyles((theme) => ({
    controlVisibilty: {
        visibility: "hidden",
        [theme.breakpoints.down('xs')]: {
            visibility: "visible",
        },
    },
    controlsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: "#000033",
        position: "absolute",
        left: 0,
        right: '0px',
        bottom: '0px',
        height: '34px'
    },

    button: {
        margin: theme.spacing(1),
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,

    },
    videoTime: {
        boxSizing: 'content-box',
        color: 'white',
        fontFamily: 'WistiaPlayerInterNumbersSemiBold, Helvetica, sans-serif',
        fontSize: '13px',
        letterSpacing: '0.5px',
        lineHeight: '34px',
        paddingLeft: '5px',
        pointerEvents: 'none',
        position: 'relative',
        textAlign: 'center',
        width: ' 12%',
        fontWeight: '800',
        [theme.breakpoints.down('xs')]: {
            width: '38%',
            marginRight: '5px'
        },
    },
    prettoSlider: {
        paddingBottom: '8px',
        color: 'rgb(255, 255, 255)',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '15px',
        },
    },
    player: {
        display: 'block',
        left: 'calc(50%)',
        marginLeft: '-62.5px',
        marginTop: '-65px',
        position: 'absolute',
        top: 'calc(50%)',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-30.5px',
            marginTop: '-44px',
        },
    },
    playerIcon: {
        "&:hover": {
            background: '#000033',
        },
        background: '#000033',
        left: ' 0px',
        position: 'absolute',
        top: '30px',
        width: '125px',
        backgroundPosition: '0 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        border: 0,
        borderRadius: 0,
        outline: 'none',
        [theme.breakpoints.down('xs')]: {
            width: '60px',
            height: '45px'
        },

    },
    volumeWrap: {
        background: 'rgba(0, 0, 0, 0.7)',
        padding: '15px 6px 15px 6px',
        height: '110px',
        transition: 'opacity 170ms ease 0s',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    volumeGrid: {
        height: '110px',
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '3.2%',
        bottom: '39px',
        position: 'absolute',
        visibility: 'hidden',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
}));

const PrettoSlider = withStyles({

})(Slider);



function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip open={false} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}



const Controls = forwardRef(
    (
        {
            onSeek,
            onSeekMouseDown,
            onSeekMouseUp,
            onDuration,
            onRewind,
            onPlayPause,
            onFastForward,
            playing,
            played,
            elapsedTime,
            totalDuration,
            onMute,
            muted,
            onVolumeSeekDown,
            onChangeDispayFormat,
            playbackRate,
            onPlaybackRateChange,
            onToggleFullScreen,
            volume,
            onVolumeChange,
            course_id
        },
        ref
    ) => {
        const classes = useStyles();


        const controlsRef = useRef(null);
        const volumeRef = useRef(null);


        const handleMouseMove2 = () => {
            controlsRef.current.style.visibility = "visible";
            controlsRef.current.style.height = '110px';
            controlsRef.current.style.bottom = '35px';
            volumeRef.current.style.height = '110px';
            volumeRef.current.style.padding = '15px 6px 15px 6px';
        };
        const hanldeMouseLeave = () => {
            controlsRef.current.style.visibility = "hidden";
            controlsRef.current.style.height = 0;
            controlsRef.current.style.bottom = 0;
            volumeRef.current.style.height = 0;
            volumeRef.current.style.padding = 0;

        };


        const [courseType, setCourseType] = useState(null);

        const { user } = useContext(Context);

        useEffect(async () => {
            if (user && user.user_type === 'USR' && course_id) {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseType/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            setCourseType(json.type)
                        }
                    )
            }
        }, [])



        return (
            <div ref={ref} className={classes.controlVisibilty} style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                height: '100%'
            }}
                onMouseLeave={hanldeMouseLeave}
            >
                <Grid onClick={onPlayPause} style={{ height: '100%' }}>
                    <Grid item xs={12} className={classes.player} >
                        {playing ? (
                            null
                        ) : (

                            <Grid item xs={6}>
                                <IconButton className={classes.playerIcon} onClick={onPlayPause} >

                                    <PlayArrowIcon style={{ fontSize: 50, color: '#fff' }} />
                                </IconButton>
                            </Grid>
                        )
                        }

                    </Grid >
                </Grid>

                <Grid
                    ref={controlsRef}
                    className={classes.volumeGrid}
                    onMouseLeave={hanldeMouseLeave}>
                    <div
                        ref={volumeRef}
                        className={classes.volumeWrap}
                        onMouseLeave={hanldeMouseLeave}
                    >
                        <Slider
                            min={0}
                            max={100}
                            value={muted ? 0 : volume * 100}
                            onChange={onVolumeChange}
                            aria-labelledby="input-slider"
                            className={classes.volumeSlider}
                            onMouseDown={onSeekMouseDown}
                            onChangeCommitted={onVolumeSeekDown}
                            orientation='vertical'
                            style={{ color: '#fff' }}
                        />
                    </div>
                </Grid>
                <Grid item className={classes.controlsWrapper}>

                    <IconButton
                        style={{ color: '#fff' }}
                        onMouseMove={hanldeMouseLeave}
                        onClick={onPlayPause}
                        className={classes.bottomIcons}
                        size='small'
                    >
                        {playing ? (
                            <PauseIcon />
                        ) : (
                            <PlayArrowIcon />
                        )}
                    </IconButton>
                    {/* <Button
                        onMouseMove={hanldeMouseLeave}
                        variant="text"
                        size='small'
                        onClick={
                            onChangeDispayFormat
                            //     () =>
                            //   setTimeDisplayFormat(
                            //     timeDisplayFormat == "normal" ? "remaining" : "normal"
                            //   )
                        }
                    >
                       
                    </Button> */}
                    <div className={classes.videoTime}>
                        {elapsedTime}/{totalDuration}
                    </div>
                    {courseType && courseType === 'Mandatory' ?

                        <div style={{ width: '76%', marginLeft: '8px', marginRight: '13px' }}>
                            <PrettoSlider
                                className={classes.prettoSlider}
                                min={0}
                                max={100}
                                ValueLabelComponent={(props) => (
                                    <ValueLabelComponent {...props} value={elapsedTime} />
                                )}
                                aria-label="custom thumb label"
                                value={played * 100}
                                onDuration={onDuration}
                                onMouseMove={hanldeMouseLeave}
                            // onChange={onSeek}
                            // onChangeCommitted={onSeekMouseUp}
                            />
                        </div>
                        : <div style={{ width: '76%', marginLeft: '8px', marginRight: '13px' }}>
                            <PrettoSlider
                                className={classes.prettoSlider}
                                min={0}
                                max={100}
                                ValueLabelComponent={(props) => (
                                    <ValueLabelComponent {...props} value={elapsedTime} />
                                )}
                                aria-label="custom thumb label"
                                value={played * 100}
                                onChange={onSeek}
                                onMouseDown={onSeekMouseDown}
                                onChangeCommitted={onSeekMouseUp}
                                onDuration={onDuration}
                                onMouseMove={hanldeMouseLeave}
                            />
                        </div>}

                    <IconButton
                        style={{ color: "#fff" }}
                        onMouseMove={handleMouseMove2}
                        // onClick={() => setState({ ...state, muted: !state.muted })}
                        onClick={onMute}
                        size='small'
                        className={`${classes.bottomIcons} ${classes.volumeButton}`}
                    >
                        {muted ? (
                            <VolumeMute fontSize="medium" />
                        ) : volume > 0.5 ? (
                            <VolumeUp fontSize="medium" />
                        ) : (
                            <VolumeDown fontSize="medium" />
                        )}
                    </IconButton>
                    <IconButton
                        style={{ color: '#fff' }}
                        onClick={onToggleFullScreen}
                        className={classes.bottomIcons}
                        size="small"
                        onMouseMove={hanldeMouseLeave}

                    >
                        <FullScreen fontSize="medium" />
                    </IconButton>

                </Grid>

            </div >
        );
    }
);

Controls.propTypes = {
    onSeek: PropTypes.func,
    onSeekMouseDown: PropTypes.func,
    onSeekMouseUp: PropTypes.func,
    onDuration: PropTypes.func,
    onRewind: PropTypes.func,
    onPlayPause: PropTypes.func,
    onFastForward: PropTypes.func,
    onVolumeSeekDown: PropTypes.func,
    onChangeDispayFormat: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onToggleFullScreen: PropTypes.func,
    onMute: PropTypes.func,
    playing: PropTypes.bool,
    played: PropTypes.number,
    elapsedTime: PropTypes.string,
    totalDuration: PropTypes.string,
    muted: PropTypes.bool,
    playbackRate: PropTypes.number,
};
export default Controls;