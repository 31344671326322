import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import { useHistory } from "react-router-dom"
import { useStyles } from "../../../components/MainTheme";
import Grid from '@material-ui/core/Grid';


export default function RelatedCoursCard(props) {

    const history = useHistory();
    const classes = useStyles();
    const [productId, setProductId] = useState(null);
    const [relatedCourses, setRelatedCourses] = useState([]);
    const [postTitles, setPostTitles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);


    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getProductId/${props.course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setProductId(json)
                    }
                }
            )

    }


    useEffect(async () => {

        if (productId) {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getRelatedCoursesFromAE/${productId}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else {
                            setRelatedCourses(json)
                        }
                    }
                )

        }

    }, [productId])

    useEffect(() => {

        if (relatedCourses.length > 0) {
            let post_title = relatedCourses.splice(0, 4);
            let guid = relatedCourses.splice(0, 4);
            setPostTitles(post_title)
            setImageUrls(guid)
        }
    }, [relatedCourses])
    
    return (
        <>
        {postTitles.length > 0 ?
        <Grid item xs={12} sm={12} lg={12}>
          <Typography  variant="h5" component="h2">
          Ecco alcuni corsi che potrebbero piacerti
            </Typography>
            </Grid> : null}
            {postTitles.map((item, index) => (
                <Grid item xs={12} sm={4} lg={3} key={index}>

                    <Card key={index} className={classes.cardHeight} >
                        <CardActionArea target="_blank" href={`https://www.alteredu.it/corsi/${item.post_name}/?utm_source=academy&utm_medium=click-correlati`}>

                            <CardMedia
                                className={classes.media}
                                image={imageUrls.filter((_, ind) => ind === index).map((item) => { return item.guid })}
                               />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Corso
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {item.post_title}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{ marginTop: 'auto' }}>
                            <Button block={true} size="small" type="default" target="_blank" href={`https://www.alteredu.it/corsi/${item.post_name}/?utm_source=academy&utm_medium=click-correlati`}>
                            scopri il corso
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </>
    );

}

RelatedCoursCard.propTypes = {
    children: PropTypes.node,
};
