import React from "react";
import PropTypes from "prop-types";
import { List } from 'antd';

export default function ListItems(props) {

    return (
        <List
            size="small"
            header={<div>{props.listTitle}</div>}
            bordered
            dataSource={props.listItems}
            renderItem={item => <List.Item>{item}</List.Item>}
        />
    );
}

ListItems.propTypes = {
    children: PropTypes.node,
};
