import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Progress, Modal, Result } from 'antd';
import { ExclamationCircleOutlined, SmileOutlined, FrownOutlined, LockOutlined } from '@ant-design/icons';
import CourseBought from "./CourseBought.js";

function SectionPage(props) {

    const { course_id } = useParams()
    const { course_name } = useParams()

    const classes = useStyles();
    const [sections, setSections] = useState([]);
    const [coursePicture, setCoursePicture] = useState(null);
    const history = useHistory();

    const [courseTitle, setCourseTitle] = useState(null);
    const [courseDescription, setCourseDescription] = useState(null);

    const [image, setImage] = useState(null)

    const { confirm } = Modal;


    useEffect(() => {
        getData()
    }, [])






    async function getData() {


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCoursePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else if (json.picture_path) {
                        setCoursePicture(json.picture_path.replace('\\', "/"))
                    }
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdminSections/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setSections(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/courseTitleAdmin/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCourseTitle(json.title)
                        setCourseDescription(json.description)
                    }
                }
            )


    }
    const styles = {
        paperContainer: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: '#391085',
            height: '350px',
            marginBottom: '2rem',
            padding: '10px 70px 10px 70px',
            borderRadius: '0',
            color: '#fff',
            marginTop: '-3rem',
            fontSize: '40px',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#000033'
        }
    };
    function goToSection(item) {
        history.push({
            pathname: `/admin/dashbord/${course_name}/${course_id}/${item.section_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
        })
    }

    return (
        <div>

            <Paper style={styles.paperContainer}>
                <Grid item style={{ textAlign: 'center' }}>
                    {courseTitle ? <Typography variant="h4" color="inherit">{courseTitle}</Typography> : null}
                    {courseDescription ?
                        <Grid item sx={12} sm={12} lg={12}>
                            <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                <div dangerouslySetInnerHTML={{ __html: courseDescription }} >
                                </div> </Typography>
                        </Grid> : null}
                </Grid>
            </Paper>

            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={12} lg={8}>
                        <Breadcrumb />
                        <Paper>
                            <Grid item style={{ display: 'flex', padding: '20px 30px', marginTop: '1rem', justifyContent: 'space-between' }}>
                                <Typography variant='h5' style={{
                                    color: '#252525',
                                    fontWeight: '600',
                                }}>Sezioni</Typography>
                                <span style={{
                                    fontSize: '22px'
                                }}>{sections.length}</span>
                            </Grid>
                            <List component="nav">
                                {sections.map((item, index) => (
                                    <ListItem key={index} button onClick={() => goToSection(item)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div className={classes.videoImage}>
                                            <img alt='' src={coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${coursePicture}` : null}
                                                style={{
                                                    display: 'block',
                                                    width: '125px',
                                                    marginRight: '20px',
                                                    height: '80px'
                                                }}></img>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                            <Typography variant='h6' style={{
                                                marginTop: '0px',
                                                marginBottom: '.25rem',
                                                fontWeight: '500',
                                                color: '#000000',
                                            }} >
                                                {item.section_name}
                                            </Typography>

                                        </div>
                                    </ListItem>

                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid container spacing={3} item xs={12} sm={12} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                        {courseTitle ?
                            <Grid item sm={8} lg={12}>
                                <Paper style={{ padding: '16px', textAlign: 'center' }}>
                                    <div className={classes.videoImage}>
                                        <img alt='' src={coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${coursePicture}` : null}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                height: '100%'
                                            }}></img>
                                    </div>
                                    <Typography variant='h6' style={{ marginTop: '1rem' }}>
                                        {courseTitle}
                                    </Typography>

                                </Paper>
                            </Grid> : null}
                    </Grid>

                </Grid>
            </Container>
        </div >
    );
}

export default withRouter(SectionPage);
