import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "../components/MainTheme.js";
import Context from '../Context'
import { Button, Alert } from 'antd';
import { useForm } from "react-hook-form";
import InputAdornment from '@material-ui/core/InputAdornment';
import { UserOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function RestorePassword(props) {

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const email = params.get('email');

    const history = useHistory();

    const classes = useStyles();
    const [error, seterror] = useState(false);
    const [success, setSuccess] = useState(false);


    const [isPassword, setIsPassword] = useState("password");


    const { register, handleSubmit, getValues, reset, formState: { errors, isSubmitting } } = useForm()

    const onSubmit = async data => {

        const formData = new FormData();

        formData.append('password', data.password)
        formData.append('password_confirmation', data.confirme_password)
        formData.append('email', email)
        formData.append('token', token)

        let result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/restorePassword`, {
            method: "POST",
            body: formData,
        });

        result = await result.json();



        if (result.msg === "success") {
            setSuccess(true)
            reset();
            seterror(false)

        } else {
            seterror(true)
            setSuccess(false)
        }

    }

    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    function forgotPassword() {
        history.push('/password-dimenticata')
    }

    return (
        <Container component="main" maxWidth="sm">
            <Grid container spacing={3} justify='center' style={{ marginTop: "5rem" }}>

                <Grid item xs={12} sm={12} lg={12} >
                    <Paper >
                        <Typography style={{ padding: '10px 0 10px 10px', backgroundColor: '#e0e0e0' }}>

                            Reset password
                        </Typography>

                        {success ?
                            <Grid item xs={12} sm={12} lg={12} style={{ padding: '30px 30px 30px 30px' }}>

                                <Alert message="La tua password è stata cambiata!" type="success" showIcon />

                                <Grid item xs={12} sm={12} lg={12} style={{ marginTop: '1rem' }}>
                                    <Link href="/entra" variant="body2">
                                        Per favore Accedi?
                                    </Link>

                                </Grid>

                            </Grid>
                            : null}
                        {!success ?
                            <form className={classes.form} style={{ padding: '0px 30px 30px 30px' }} noValidate onSubmit={handleSubmit(onSubmit)}>

                                {error ?
                                    <Alert message="La richiesta è scaduta" type="error" showIcon
                                        action={
                                            <Button size="small" type="ghost" onClick={forgotPassword}>
                                                vuoi farne un'altra?
                                            </Button>
                                        } />
                                    : null}

                                <TextField
                                    value={email}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    size='small'
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    disabled
                                    type='email'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <UserOutlined fontSize="small" />
                                            </InputAdornment>
                                        ),


                                    }}
                                />

                                <Grid item xs={12} sm={5} lg={12} style={{
                                    display: 'grid', gridGap: '20px', marginTop: '1rem'
                                }}>
                                    <TextField
                                        {...register('password', { required: true })}
                                        variant="outlined"
                                        size="small"
                                        id="password"
                                        label='Password'
                                        type={isPassword}
                                        InputLabelProps={{
                                            style: { color: '#3f51b5' },
                                            classes: {
                                                asterisk: classes.asterisk
                                            }

                                        }}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {isPassword === "password" ?
                                                        <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                            showPassword()} /> :
                                                        <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                            showPassword()} />}
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                disabled: classes.profileText,
                                                input: classes.profileText,

                                            }

                                        }}
                                        error={errors?.password?.type === "required" && true}
                                        helperText={errors?.password?.type === "required" && 'Il campo è obbligatorio'}
                                    />
                                    <TextField
                                        {...register('confirme_password', {
                                            required: true,
                                            validate: {
                                                passwordMatch: value => (value === getValues().password) || 'The passwords do not match',
                                            }
                                        })}
                                        required
                                        variant="outlined"
                                        size="small"
                                        id="confirme_password"
                                        label='Conferma password'
                                        type={isPassword}
                                        InputLabelProps={{
                                            style: { color: '#3f51b5' },
                                            classes: {
                                                asterisk: classes.asterisk
                                            }

                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {isPassword === "password" ?
                                                        <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                            showPassword()} /> :
                                                        <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                            showPassword()} />}
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                disabled: classes.profileText,
                                                input: classes.profileText,

                                            }

                                        }}

                                        error={(errors?.confirme_password?.type === 'passwordMatch' && true) || (errors?.confirme_password?.type === "required" && true)}
                                        helperText={(errors?.confirme_password?.type === 'passwordMatch' && errors?.confirme_password?.message) ||
                                            (errors?.confirme_password?.type === "required" && 'Il campo è obbligatorio')}
                                    />
                                </Grid>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    block={true}
                                    size='middle'
                                    className={classes.submit}
                                    loading={isSubmitting}
                                    style={{ width: '60%' }}
                                >
                                    Reset password
                                </Button>

                            </form> : null}

                    </Paper>
                </Grid>
            </Grid>
        </Container>

    );
}