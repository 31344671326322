import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withRouter, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../MainTheme.js";
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';




function GuestBreadcrumb(props) {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { module_id } = useParams()
    const { video_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()
    
    const { history, location: { pathname, state } } = props

    var index;
    const pathnames = pathname.split("/").filter(x => x)
    const classes = useStyles();


    if (section_id) {
        index = pathnames.indexOf(`${section_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }

    if (course_id) {
        index = pathnames.indexOf(`${course_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }
    if (module_id) {
        index = pathnames.indexOf(`${module_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }
    if (video_id) {
        index = pathnames.indexOf(`${video_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }


    if(pathnames.indexOf('ppt-page'))
    {
        index = pathnames.indexOf('ppt-page');
        if (index > -1) {
            pathnames.splice(index, 1);
        }
    }

    if(pathnames.indexOf('pdf-page'))
    {
        index = pathnames.indexOf('pdf-page');
        if (index > -1) {
            pathnames.splice(index, 1);
        }
    }

    if(pathnames.indexOf('video-page'))
    {
        index = pathnames.indexOf('video-page');
        if (index > -1) {
            pathnames.splice(index, 1);
        }
    }


    function goToPage(goTo) {

        if (goTo === `/corsi/${course_name}`)
            history.push({
                pathname: `/corsi/${course_name}/${course_id}`,
                state: state
            })

        else if (goTo === `/corsi/${course_name}/${section_name}`)
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}`,
                state: state
            })


        else {
            history.push({
                pathname: `/corsi`,
                state: state
            })
        }
    }

    return (
        <Grid item xs={10} sm={12} lg={12}>
                <Grid item xs={12} lg={12}>
                        < Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} style={{ marginBottom: '1rem' }}
                            aria-label="breadcrumb" >
                            {pathnames.map((name, index) => {


                                const goTo = `/${pathnames.slice(0, index + 1).join('/')}`
                                const isLast = index === pathnames.length - 1
                                const f_name = name.replace('-', ' ')

                                return isLast ? (<Typography key={index} color="textPrimary">
                                    {index === 0 ? <HomeIcon className={classes.icon} /> : null}     {f_name}</Typography>) :

                                    (<Link style={{  cursor: 'pointer' }} key={index} color="inherit"
                   onClick={() => goToPage(goTo)}> {index === 0 ? <HomeIcon className={classes.icon} /> : null} {f_name} </Link>)

                            })
 
                            }

                        </Breadcrumbs>
                </Grid>
        </Grid >
    );


                        }
export default withRouter(GuestBreadcrumb)