import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from '@mui/material';
import { Table, Space, Input, Button, Popconfirm, Tag } from 'antd';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import Marquee from 'react-fast-marquee';
import { Alert } from 'antd';

function Courses() {
    const history = useHistory();
    const moment = require('moment');

    const [data, setData] = useState([]);
    const [courseCategories, setCourseCategories] = useState([]);
    const [coursePublished, setCoursePublished] = useState(false);
    const [courseUnPublished, setCourseUnPublished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorTime, setErrorTime] = useState(false);
    const [errorProductId, setErrorProductId] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countCourses, setCountCourses] = useState([]);


    function AlertPopUp(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    useEffect(() => {
        getData()
    }, [])



    async function getData() {
        setLoading(true)
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/courses`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => {
                    setData(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCategoriesNameCourse`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => {
                    setCourseCategories(json)
                    setLoading(false)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/countUserSubscribedInCourse`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => {
                    setCountCourses(json)
                    setLoading(false)
                }
            )
    }

    async function deleteCourse(id) {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete/${id}`, {
            method: "DELETE"
        }).then(() => getData())

    }


    const downloadQuizCsv = async (course) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download/test/csv/${course.id}`, {
                method: "GET",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // const contentDisposition = response.headers.get('Content-Disposition');
            // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // const matches = filenameRegex.exec(contentDisposition);
            // const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'export_quiz.csv';
            const filename = course.title + '.csv';

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error downloading CSV:", error);
        }
    };

    async function downloadCourseCsv(course) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download/csv/${course.id}`, {
                method: "GET"
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // const contentDisposition = response.headers.get('Content-Disposition');
            // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // const matches = filenameRegex.exec(contentDisposition);
            // const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'export_course.csv';

            const filename = course.title + '.csv';
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error downloading CSV:", error);
        }
    }

    function goToCourseDetails(item) {
        history.push({
            pathname: `/admin/corsi/${item.title.toLowerCase().replaceAll(' ', "-")}/${item.id}/dettagli-corso`
        })
    }

    function goToAddSections(item) {
        history.push({
            pathname: `/admin/corsi/${item.title.toLowerCase().replaceAll(' ', "-")}/${item.id}`
        })
    }

    function goToEditCoure(item) {
        history.push({
            pathname: `/admin/corsi/${item.title.toLowerCase().replaceAll(' ', "-")}/${item.id}/aggiorna-corso`
        })
    }

    async function publishCourse($course_id) {

        const formData = new FormData();
        formData.append('course_id', $course_id);

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/publishCourse`, {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setCoursePublished(true)
                        getData()
                    } else if (json.msg === 'error_time') {
                        setErrorTime(true);
                    } else {
                        setErrorProductId(true);
                    }

                })
    }

    function goToUsersSubscribed(item) {
        history.push({
            pathname: `/admin/corsi/${item.product_id}/studenti-iscritti`
        })
    }

    async function unPublishCourse($course_id) {
        const formData = new FormData();
        formData.append('course_id', $course_id);

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/unPublishCourse`, {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setCourseUnPublished(true)
                        getData()
                    }

                })
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCoursePublished(false);
        setCourseUnPublished(false)
        setErrorTime(false)
        setErrorProductId(false)
    }


    const columns = [
        {
            title: 'Corso',
            dataIndex: 'id',
            key: 'id',
            render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
        },
        {
            title: 'Nome del Corso',
            dataIndex: 'title',
            key: 'title',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        {/* <Button size="small" style={{ width: 90 }}
                            onClick={() => { clearFilters() }}>
                            Reset
                        </Button> */}
                        {/* <Button
                            type="link"
                            size="small"
                            onClick={() => { }}
                        >
                            Filter
                        </Button> */}
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.title.toLowerCase().includes(value.toLowerCase())
            },
            render: (title, record) => <Link onClick={() => goToAddSections(record)}>  {title}</Link>,
            width: '250px'
        },

        {
            title: 'Tipologia',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'Corsi sola visualizzazione', value: 'Normal' },
                { text: 'Formazione Obbligatoria', value: 'Mandatory' },

            ],
            onFilter: (value, record) => {
                return record.type === value
            },
            width: '10%',
            render: type => <>{
                type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (conTimer)'
            }</>,
        },
        {
            title: "Categoria",
            dataIndex: "id",
            key: "id",
            render: (_, record) => (
                <>
                    {courseCategories.filter(item => item.course_id === record.id).map(item => (
                        <Tag color="geekblue" key={item.id}>
                            {item.category}
                        </Tag>
                    ))}
                </>
            ),

        },
        {
            title: "Utenti Iscritti",
            dataIndex: "id",
            key: "id",
            render: (title, record) => <Link onClick={() => goToUsersSubscribed(record)}>
                {countCourses[record.product_id]}</Link>,

        },
        {
            title: "Course id",
            dataIndex: "id",
            key: "id",
            render: (title, record) => <> {record.product_id}</>,

        },
        {
            title: 'Data Creazione',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => <> {moment(created_at).format('DD/MM/YYYY, h:mm:ss a')}</>,
            sorter: (record1, record2) => {
                return record1.created_at > record2.created_at
            }
        },
        {
            title: 'Visualizza e Modifica',
            key: 'visualizza_e_Modifica',
            render: (title, record) => <Button onClick={() => goToEditCoure(record)} icon={<EditOutlined style={{ fontSize: "20px" }} />} />,
        },
        {
            title: 'Descrizione Corso',
            key: 'Descrizione_Corso',
            render: (title, record) => <Button onClick={() => goToCourseDetails(record)} icon={<EyeOutlined style={{ fontSize: "20px" }} />} />,
        },
        {
            title: 'Pubblica corso',
            key: 'Pubblica_corso',
            filters: [
                { text: 'Pubblica', value: 0 },
                { text: 'Non pubblicato', value: 1 },
            ],
            onFilter: (value, record) => {
                return record.published === value
            },
            render: (text, record) => <>
                {record.published === 0 ?
                    <Popconfirm
                        onConfirm={() => publishCourse(record.id)}
                        title="Sei sicuro?" okText="Si" cancelText="No">
                        <Button type="link" style={{ padding: "0px" }}>Pubblica</Button>
                    </Popconfirm> :
                    <Popconfirm
                        onConfirm={() => unPublishCourse(record.id)}
                        title="Sei sicuro?" okText="Si" cancelText="No">
                        <Button type="link" style={{ padding: "0px" }}>Non pubblicato</Button>
                    </Popconfirm>
                }

            </>
        },
        {
            title: 'Export course',
            key: 'Download',
            render: (text, record) => <>
                <Button icon={<DownloadOutlined style={{ fontSize: "18px" }} onClick={() => downloadCourseCsv(record)} />} />
            </>
        },
        {
            title: 'Export quiz',
            key: 'Download',
            render: (text, record) => <>
                <Button icon={<DownloadOutlined style={{ fontSize: "18px" }} onClick={() => downloadQuizCsv(record)} />} />
            </>
        },
        {
            title: 'Tipo di visualizzazione utente',
            key: 'Tipo_di_visualizzazione_utente',
            filters: [
                { text: 'Utente registrato', value: 'registered_user' },
                { text: 'Ospite', value: 'guest' },
            ],
            onFilter: (value, record) => {
                return record.user_type === value
            },
            render: (user_type, record) => <>  {record.user_type === 'guest' ? 'Ospite' : 'Utente registrato'}</>,
        },
        {
            title: 'Elimina',
            key: 'Elimina',
            render: (text, record) => <>
                <Popconfirm
                    onConfirm={() => deleteCourse(record.id)}
                    title="Sei sicuro?" okText="Si" cancelText="No">
                    <Button icon={<DeleteOutlined style={{ fontSize: "18px" }} />} />
                </Popconfirm>
            </>
        },
    ];


    return (

        <Container maxWidth="lg">
            <Grid container spacing={5} >
                <Snackbar open={coursePublished} autoHideDuration={3000} onClose={handleClose} >
                    <AlertPopUp onClose={handleClose} severity="success">
                        Corso pubblicato
                    </AlertPopUp>
                </Snackbar>
                <Snackbar open={courseUnPublished} autoHideDuration={3000} onClose={handleClose} >
                    <AlertPopUp onClose={handleClose} severity="success">
                        Corso non pubblicato
                    </AlertPopUp>
                </Snackbar>
                <Snackbar open={errorTime} autoHideDuration={3000} onClose={handleClose} >
                    <AlertPopUp onClose={handleClose} severity="error">
                        Error durata corso
                    </AlertPopUp>
                </Snackbar>
                <Snackbar open={errorProductId} autoHideDuration={3000} onClose={handleClose} >
                    <AlertPopUp onClose={handleClose} severity="error">
                        Error product id
                    </AlertPopUp>
                </Snackbar>
                <Grid item xs={8} sm={12} lg={12}>
                    <Breadcrumb />
                    <Space direction="horizontal">
                        <Alert
                            type="info"
                            banner
                            message={
                                <Marquee pauseOnClick={true} gradient={false}>
                                    Messaggio di prova clicca sul pulsante in basso per conferma.
                                </Marquee>
                            }
                        />
                    </Space>
                    <Paper style={{ marginTop: '1rem' }}>
                        <Table
                            size="small"
                            bordered
                            rowKey={record => record.id}
                            loading={loading} columns={columns} dataSource={data}
                            pagination={{
                                current: page,
                                pageSize: pageSize,
                                onChange: (page, pageSize) => {
                                    setPage(page)
                                    setPageSize(pageSize)
                                }
                            }}

                        />
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    );
}

export default Courses;
