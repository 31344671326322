import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function TableCategories(props) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pageCategory, setPageCategory] = useState(1);
    const [pageSizeCategory, setPageSizeCategory] = useState(10);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {

        setSelectedRowKeys(props.selectedRowKeys)

    }, [props.selectedRowKeys])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCategories`).then(
            response => response.json()).then(
                json => setCategories(json)
            )
    }

    const CategoryColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (value, item, index) => <> {(pageCategory - 1) * pageSizeCategory + index + 1} </>,

        },
        {
            title: 'Nome categoria',
            dataIndex: 'category',
            key: 'category',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.category.toLowerCase().includes(value.toLowerCase())
            },
            render: category => <>{category}</>,

        },
    ]

    const rowSelectionCategory = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            props.categoriesID(selectedRows.map((item) => { return item.id }))
            props.categoriesTitle(selectedRows.map((item) => { return item.category }))
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    return (
        <Table
            size="small"
            bordered
            rowKey={record => record.id}
            loading={props.loading} columns={CategoryColumns} dataSource={categories}
            rowSelection={{
                type: 'checkbox',
                ...rowSelectionCategory,
            }}
            pagination={{
                showSizeChanger: true,
                current: pageCategory,
                pageSize: pageSizeCategory,
                onChange: (pageCategory, pageSizeCategory) => {
                    setPageCategory(pageCategory)
                    setPageSizeCategory(pageSizeCategory)
                }
            }}
        />
    );
}

TableCategories.propTypes = {
    children: PropTypes.node,
};
