import React, { useState, useEffect } from "react";
import { DataGrid } from '@material-ui/data-grid';
import { withRouter } from "react-router-dom";
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

const columns = [
    { field: 'id', type: 'number', headerName: 'id', width: 100 },
    { field: 'first_name', headerName: 'First name', width: 200 },
    { field: 'last_name', headerName: 'Last name', width: 200 },
    { field: 'email', headerName: 'Email', width: 350 },
    { field: 'country', headerName: 'Country', width: 160 },
    { field: 'postcode', headerName: 'Post code', width: 160 },
    { field: 'city', headerName: 'City', width: 160 },
    { field: 'state', headerName: 'State', width: 160 },

];



function StudentsCourse(props) {
    const { post_title } = props.location.state
    const classes = useStyles();
    const [noCourse, setNoCourses] = useState(false);

    const [users, setUsers] = useState([]);


    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }

    useEffect(() => {
        getData()

    }, [])


    async function getData() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserParInCourse/${post_title}`).then(
            response => response.json()).then(
                json => {
                    if (json.length > 0) setUsers(json)
                    else setNoCourses(true)
                }
            )

    }
    return (
        <Container maxWidth="lg" >
            <Breadcrumb />
            <Grid container spacing={3} justify='center' >
                <Grid item xs={12} lg={12}>
                    <Paper style={{ textAlign: 'center', padding: '10px' }}  >
                        <Typography variant="h5" className={classes.title}>
                            {post_title}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={12}>

                    {users.length > 0 ?
                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid rows={users} columns={columns} pageSize={100} rowsPerPageOptions={[100, 300, 500]} />
                        </div> : null}

                    {noCourse ? <Alert severity="info">No students!</Alert> : null}
                </Grid>

            </Grid>
        </Container>
    );
}

export default withRouter(StudentsCourse);