import { withRouter, useParams, useHistory } from "react-router-dom"
import React, { useState, ReactElement, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import { Button, Collapse } from "antd";
import Typography from '@material-ui/core/Typography';


function ReadPdf(props) {

  const classes = useStyles();

  const { module_id } = useParams()
  const { course_id } = useParams()
  const { course_name } = useParams()
  const { section_name } = useParams()
  const { section_id } = useParams();
  const [url, setUrl] = useState(null)
  const { Panel } = Collapse;

  const history = useHistory();
  const [modules, setModules] = useState(null);
  const [description, setDescription] = useState(null);



  useEffect(() => {
    getData()
  }, [])

  async function getData() {

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePathAdmin/${module_id}`).then(
      response => response.json()).then(
        path => setUrl(`${path.module_path}`)
      )
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`)
      .then((response) => response.json())
      .then((json) => setModules(json));

  }

  useEffect(() => {

    setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
      return item.description;
    }))
  }, [modules]);

  function nextModule() {
    var index;
    var id;
    var type;
    var module_name;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
    modules && modules.map((item, i) => {
      if (i === index) {
        id = item.id
        type = item.type
        module_name = item.module_name
      }
      return true;
    })

    if (type === 'pdf') {
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
      window.location.reload(false);
    }

    if (type === 'video' || type === 'video_to_server')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
    if (type === 'ppt')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });

    //  window.location.reload(false);
  }
  function prevModule() {
    var index;
    var id;
    var type;
    var module_name;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
    modules && modules.map((item, i) => {
      if (i === index) {
        id = item.id
        type = item.type
        module_name = item.module_name
      }
      return true;
    })


    if (type === 'pdf') {
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
      window.location.reload(false);
    }

    if (type === 'video' || type === 'video_to_server')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
    if (type === 'ppt')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });

    // window.location.reload(false);
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} justify='center' >
        <Grid item xs={12} md={8} lg={9} >
          <Breadcrumb />
        </Grid>
        {url ?
          <Grid item xs={12} lg={9} style={{ display: 'flex', justifyContent: 'end' }}>
            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
            >precedente</Button>}
            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
              : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
          </Grid> : null}
        <Grid item xs={12} md={12} lg={9} >

          {url ?
            <iframe className={classes.iframePPt} src={url} frameborder="0" allowfullscreen="true"
              mozallowfullscreen="true" webkitallowfullscreen="true">

            </iframe> : null}
        </Grid>
        {url ?
          <Grid item xs={12} lg={9} style={{ display: 'flex', justifyContent: 'end' }}>
            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
            >precedente</Button>}
            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
              : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
          </Grid> : null}
        {url && description && (description[0] != null) ?
          <Grid item xs={12} md={12} lg={9} style={{ marginTop: '2rem' }}>
            <Collapse accordion>
              <Panel header="Informazioni aggiuntive" key="1">
                <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                  {description}
                </Typography>
              </Panel>

            </Collapse>

          </Grid>
          : null}
      </Grid>
    </Container>
  );
}

export default withRouter(ReadPdf);
