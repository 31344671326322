import "../App.css";
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from "../components/MainTheme.js";
import { Upload, Button, Image, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ControlledEditor from "../components/EditorContainer"
import { useForm } from "react-hook-form";
import { Space, Input, Radio } from 'antd';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';

function AddCourse() {

  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [courseType, setCourseType] = useState('Normal');
  const [userType, setUserType] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [releaseCertificat, setReleaseCertificat] = useState(false);
  const [hasECP, setHasECP] = useState(false);
  const [error_2, setError] = useState(false);
  const [errorCertificate, setErrorCertificate] = useState(false);
  const [certificateType, setCertificateType] = useState(null);
  const [customUrl, setCustomUrl] = useState(null);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm()

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onSubmit = async data => {

    setLoading(true)

    if (!displayImage) {
      error()
      setLoading(false)

    } else if (!certificateType) {
      setErrorCertificate(true)
      setLoading(false)
    }

    else {

      const formData = new FormData();
      formData.append('title', data.title)
      formData.append('product_id', data.product_id)
      formData.append('type', courseType)
      formData.append('certificate_type', certificateType)
      if (customUrl)
        formData.append('custom_url', customUrl)

      // if (releaseCertificat) {
      //   formData.append('has_certificate', releaseCertificat)
      //   formData.append('ecp', data.ecp)
      //   if (data.authorisation_number)
      //     formData.append('authorisation_number', data.authorisation_number)
      // }

      if (image)
        formData.append('file', image)
      if (userType)
        formData.append('userType', userType)
      if (description && description.trim().length !== 7)
        formData.append('description', description)


      await fetch(`${process.env.REACT_APP_API_BASE_URL}/addcourse`, {
        method: "POST",
        body: formData
      }).then((response) => response.json())
        .then((json) => {
          if (json.result === 'course_added') {
            history.push("/admin/corsi")
          } else {
            setError(true)
          }
          setLoading(false)
        })
    }

  }

  const handleChangeType = (event) => {
    setCourseType(event.target.value)
  };

  const handleChangeUserType = (event) => {
    setUserType(event.target.value)
  };

  const file = {
    beforeUpload: file => {
      setImage(file)
      setDisplayImage(URL.createObjectURL(file))
      return false;
    }
  };

  function removedImage() {
    setDisplayImage(null)
  }

  const handleCallback = (childData) => {
    setDescription(childData)
  }

  async function error() {

    await message.error({
      content: 'Immagine del Corso richiesta',
      style: {
        marginTop: '10vh',
      },
    })

  }

  function ChangeCertificatAdd(e) {
    setReleaseCertificat(e.target.checked)
  }


  function ChangeHasECP(e) {
    setHasECP(e.target.checked)
  }


  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
    setErrorCertificate(false);
  }

  const onChange = (e) => {
    setCertificateType(e.target.value);
  };

  const onChangeCustomUrl = (e) => {
    setCustomUrl(e.target.value);
  };

  return (

    <Container maxWidth="lg">
      <Grid item xs={12} sm={12} lg={12}>

        <Snackbar open={error_2} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
            Course already added
          </Alert>
        </Snackbar>

        <Snackbar open={errorCertificate} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
            check certificate
          </Alert>
        </Snackbar>

        <Paper style={{ padding: '16px' }}>
          <Typography variant="h6" className={classes.title} style={{ marginBottom: '1rem' }}>
            Inserisci Corso
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item sx={12} sm={12} lg={12}>
                <TextField
                  {...register('title', { required: true })}
                  size="small"
                  fullWidth
                  variant='outlined'
                  required
                  id="standard-helperText"
                  label="Nome del Corso"
                  error={errors?.title?.type === "required" && true}
                  helperText={errors?.title?.type === "required" && 'Il campo è obbligatorio'}
                />
              </Grid>
              <Grid item sx={12} sm={12} lg={12}>
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item sx={12} sm={12} lg={12}>
                    <InputLabel htmlFor="age-native-helper">Tipologia Corso</InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      value={courseType}
                      onChange={handleChangeType}
                      inputProps={{
                        name: 'type',
                      }}
                    >
                      <option value='Normal'>Corsi sola visualizzazione</option>
                      <option value='Mandatory'>Formazione Obbligatoria (con Timer)</option>
                    </NativeSelect>
                    <FormHelperText>Seleziona la tipologia</FormHelperText>
                  </Grid>
                  <Grid item sx={12} sm={12} lg={12}>
                    <TextField
                      {...register('product_id', { required: true })}
                      required
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Product id"
                      id="standard-size-small"
                      size="small"
                      variant='outlined'
                      error={errors?.product_id?.type === "required" && true}
                      helperText={errors?.product_id?.type === "required" && 'Il campo è obbligatorio'}
                    />
                    <FormHelperText>inserisci il valore 0 per i corsi demo</FormHelperText>
                  </Grid>
                </Space>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputLabel htmlFor="age-native-helper">Descrizione Corso</InputLabel>
                <ControlledEditor description={handleCallback} />
              </Grid>
              <Grid item sx={12} sm={12} lg={12}>
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item sx={12} sm={12} lg={12}>
                    <InputLabel htmlFor="age-native-helper">Tipo di visualizzazione utente</InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      onChange={handleChangeUserType}
                      inputProps={{
                        name: 'type',
                      }}
                    >
                      <option value='registered_user'>Utente registrato</option>
                      <option value='guest'>Ospite</option>
                    </NativeSelect>
                    <FormHelperText>seleziona tipo </FormHelperText>
                  </Grid>
                  {/* <Space direction="vertical">
                    <Checkbox onChange={(e) => ChangeCertificatAdd(e)} >Attestato o Certificato base</Checkbox>
                    {releaseCertificat ? <Checkbox onChange={(e) => ChangeHasECP(e)} >Certificato ECP</Checkbox> : null}
                    {hasECP && releaseCertificat ?
                     <Space direction="vertical">
                       <Input placeholder="ECP" type='number'
                       {...register('ecp', { required: true })} onChange={(e) => setValue('ecp', e.target.value)}/>
                       {errors.ecp && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input placeholder="Authorisation number" 
                       {...register('authorisation_number', { required: true })} onChange={(e) => setValue('authorisation_number', e.target.value)}/>
                       {errors.authorisation_number && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                    </Space>: null}

                      </Space> */}

                  <Space direction="vertical">
                    <Radio.Group onChange={onChange} value={certificateType}>
                      <Space direction="vertical">
                        <Radio value={'corsi normati'}>corsi normati</Radio>
                        <Radio value={'certificato di partecipazione'}>certificato di partecipazione</Radio>
                        <Radio value={'certificazione delle competenze'}>certificazione delle competenze</Radio>
                        <Radio value={'acquista corso completo'}>acquista corso completo</Radio>
                        {certificateType === 'acquista corso completo' ? <Input placeholder="Url" key={1} allowClear onChange={onChangeCustomUrl} /> : null}
                      </Space>
                    </Radio.Group>
                  </Space>
                </Space>
              </Grid>
              {displayImage ?
                <Grid item xs={12} sm={12} lg={12}>
                  <Image
                    width={300}
                    src={displayImage}
                  />
                </Grid> : null}
              <Grid item sx={12} sm={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Upload
                  {...file}
                  listType="picture"
                  maxCount={1}
                  accept='image/*'
                  onRemove={removedImage}
                >
                  <Button icon={<UploadOutlined />} >Immagine del Corso</Button>
                </Upload>


                <Button
                  htmlType="submit"
                  type="primary"
                  className={classes.button}
                  style={{
                    width: '120px',
                  }}
                  loading={loading}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>

        </Paper>
      </Grid>

    </Container>

  );
}

export default AddCourse;
