import React, {useState, useEffect} from "react";
import { useStyles } from "../../../components/MainTheme";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';
import {LockOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default function SectionList(props) {

 
    const classes = useStyles();
    const history = useHistory();
    const [allowedSections, setAllowedSections] = useState([])


    useEffect( () => {
      getData()
    }, [])

    async function getData()
    {
        

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllowedSections/${props.course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAllowedSections(json)
                    })

    }


        function goToSection(item) {
            history.push({
                pathname: `/home/${props.course_name}/${props.course_id}/${item.section_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
        }
   
    return (
        <Paper>
        <Grid item style={{ display: 'flex', padding: '20px 30px', justifyContent: 'space-between' }}>
            <Typography variant='h5' style={{
                color: '#252525',
                fontWeight: '600',
            }}>Sezioni</Typography>
            <span style={{
                fontSize: '22px'
            }}>{props.sections.length}</span>
        </Grid>
       <List component="nav">
        {props.sections.map((item, index) => (
            <ListItem key={index} button onClick={() => goToSection(item)}
                style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                }}
                disabled={(index <= allowedSections.length) || index === 0 ? false : true}>
                <div className={classes.videoImage}>
                    <img alt='' src={props.coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${props.coursePicture}` : null}
                        style={{
                            display: 'block',
                            width: '125px',
                            marginRight: '20px',
                            height: '80px'
                        }}></img>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                    <Typography variant='h6' style={{
                        marginTop: '0px',
                        marginBottom: '.25rem',
                        fontWeight: '500',
                        color: '#000000',
                    }} >
                        {item.section_name}
                    </Typography>
                    {props.sectionStatus.filter(function (val) {
                        return (val.section_id === item.id && val.finished_section === 1);
                    }).length > 0 ?
                        <DoneIcon style={{ color: '#33eb91' }} />
                        : null}
                       
                        {(index <= allowedSections.length) || index === 0 ? false : 
                        <LockOutlined style={{ fontSize: '25px' }} />}
                </div>
            </ListItem>

        ))}
    </List>
</Paper>
    );
}

SectionList.propTypes = {
    children: PropTypes.node,
};
