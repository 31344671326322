import { withRouter, useParams, useHistory } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import Context from '../Context'
import { Button, message, Collapse } from "antd";
import { Typography } from "@material-ui/core";
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';

function ReadPdf(props) {

    const classes = useStyles();
    const { user } = useContext(Context)
    const { module_id } = useParams()
    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()
    const history = useHistory();

    const [url, setUrl] = useState(null)
    const [modules, setModules] = useState(null);
    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);
    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [updated, setUpdated] = useState(true)
    const [countQuestions, setQuestion] = useState(null);

    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [moduleTime, setModuleTime] = useState(0)

    const [courseType, setCourseType] = useState(null);
    const [check, setCheck] = useState(false)
    const [moduleStatus, setModuleStatus] = useState([])
    const [percent, setPercent] = useState(0);

    localStorage.setItem(`${user.id}_url`, JSON.stringify(props.location.pathname));




    useEffect(() => {
        getData()

    }, [])

    async function getData() {


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setUrl(`${path.module_path}`)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json)
                }

            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScore/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setScoreAnswers(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    if (json > 0) {
                        getScore()
                    }
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)

                }
            )

        setUserStatus()

    }

    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);

    useEffect(() => {

        if (courseType && courseType.type === "Normal") {
            updateStatus()
        }
    }, [courseType]);

    async function setUserStatus() {
        if (user) {
            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserStatus`, {
                method: "POST",
                body: formData
            })
        }

    }

    async function updateStatus() {
        //finished reading
        if (user) {
            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatus`, {
                method: "POST",
                body: formData
            }).then(() => getAllowedResources())

        }

    }

    async function getAllowedResources() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else {
                            setModuleStatus(json)
                        }
                    }
                )
    }

    useEffect(() => {

        if (moduleStatus && modules && moduleStatus.length > 0 && modules.length > 0) {
            let moduleStatusLength = moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length

            let per = ((moduleStatusLength) * 100) / modules.length
            setPercent(Math.floor(per))
        }
    }, [moduleStatus]);

    function nextModule() {
        if ((courseType && courseType.type === 'Normal' && moduleStatus.filter(function (val) {
            return (val.module_id === parseInt(module_id) && val.finished_reading === 1);
        }).length > 0)
            || (courseType && courseType.type === 'Mandatory' && moduleStatus.filter(function (val) {
                return (val.module_id === parseInt(module_id) && val.finished_reading === 1 && val.time === 0);
            }).length > 0 && JSON.parse(localStorage.getItem(`${user.id}_${module_id}_scnd`) === '0'))) {
            var index;
            var id;
            var type;
            var module_name;
            index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
            modules && modules.map((item, i) => {
                if (i === index) {
                    id = item.id
                    type = item.type
                    module_name = item.module_name
                }
                return true;
            })

            if (type === 'pdf')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
            if (type === 'video' || type === 'video_to_server')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
            if (type === 'ppt') {
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
                window.location.reload(false);
            }

        } else error()

    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })

        if (type === 'pdf')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });



        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
        if (type === 'ppt') {
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            window.location.reload(false);
        }

    }
    async function updateStatusTime() {

        if (courseType && check && courseType.type === 'Mandatory' && user && user.user_type === 'USR' && minutes >= 0) {

            const minutes_to_save = minutes * 60
            if (seconds === 0 && minutes !== 0) {
                var total_time = minutes_to_save + 59
            }
            else {
                total_time = minutes_to_save + seconds
            }

            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)
            formData.append('time', total_time)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatusTime`, {
                method: "POST",
                body: formData
            })
        }


    }

    function updateTime() {

        if (minutes === 0 && seconds === 0) {
            updateStatusTime()
            updateStatus()
            setUpdated(false)
        }
        else {
            if (seconds === 0) {
                setMinutes(minutes => minutes - 1);
                setSeconds(59);
            } else {
                setSeconds(seconds => seconds - 1);

            }
        }
    }


    useEffect(() => {
        if ((seconds === 30 || seconds === 59) && url && check && courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && user && minutes >= 0) {
            updateStatusTime()
            getAllowedResources()
        }
    }, [seconds])



    const error = () => {
        message.error({
            content: 'Devi finire di visualizzare la risorsa prima di procedure al modulo successive ',
            style: {
                marginTop: '5vh',
            },
        });
    };

    useEffect(() => {
        if (courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && updated) {
            const token = setTimeout(updateTime, 1000)
            return function cleanUp() {
                clearTimeout(token);
            }
        }
    })

    async function getModuleTime(time) {
        if (time.length === 0) {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTime/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(time / 60)


                    }
                ).then(() => setCheck(true))
        }
    }

    async function getScore() {



        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setTestScore(json)
                }
            )

    }

    useEffect(() => {

        if (user.user_type === 'USR' && url)
            setUserStatus()
        async function getModuleTimeLeft() {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTimeLeft/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.length === 0) {
                            getModuleTime(time)
                        }

                        else {
                            const minutes_left = Math.floor(time / 60)
                            const seconds_left = time % 60
                            setMinutes(minutes_left)
                            setSeconds(seconds_left)
                            // if (time === 0) {
                            //      setSeconds(JSON.parse(localStorage.getItem(`${user.id}_${module_id}_scnd`)))
                            // } else { setSeconds(59) }

                            setCheck(true)
                        }
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTime/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleTime(time / 60)


                    }
                )
        }
        if (courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && user && url) {

            getModuleTimeLeft()
            getAllowedResources()

        }
    }, [courseType, url])

    function passeTest() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}`,
        })
    }

    function testResult() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/test-risultati`,

        })
    }

    function nextSection() {
        history.push({
            pathname: `/home/${course_name}/${course_id}`,
        })
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} justify='center' >
                <Grid item xs={12} md={8} lg={9} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb />
                    {courseType && courseType.type === 'Mandatory' && minutes >= '0' ?
                        <Grid item xs={4} sm={3} lg={4} style={{ textAlign: 'center' }}>
                            <Typography variant='h6' style={{ paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'gold' }}>
                                {minutes < 10 ? 0 : null}{minutes}:{seconds < 10 ? 0 : null}{seconds} / {moduleTime < 10 ? 0 : null}{moduleTime} minuti
                            </Typography>
                        </Grid> : null}
                </Grid>
                {url ?
                    <Grid item xs={12} sm={6} lg={9} style={{ display: 'flex', justifyContent: 'end', marginRight: "3rem" }}>

                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null :
                            <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
                            ><LeftOutlined />Precedente</Button>}

                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ?
                            null : <Button type="primary" onClick={nextModule}>Prossimo modulo<RightOutlined /></Button>}

                        {answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                            <Button type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} onClick={passeTest}
                                disabled={percent !== 100 ? true : false}>Avvia test</Button> :
                            answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                        {countQuestions === 0 ?
                            <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                    </Grid> : null}
                <Grid item xs={12} md={12} lg={9} >

                    {url ?
                        <iframe className={classes.iframePPt} src={url} frameborder="0" allowfullscreen="true"
                            mozallowfullscreen="true" webkitallowfullscreen="true">

                        </iframe> : null}
                </Grid>
                {url ?
                    <Grid item xs={12} sm={6} lg={9} style={{ display: 'flex', justifyContent: 'end', marginRight: "3rem" }}>

                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
                        ><LeftOutlined />Precedente</Button>}

                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                            : <Button type="primary" onClick={nextModule}>Prossimo modulo<RightOutlined /></Button>}

                        {answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                            <Button type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                onClick={passeTest} disabled={percent !== 100 ? true : false}>Avvia test</Button> :
                            answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                        {countQuestions === 0 ?
                            <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                    </Grid> : null}
                {url && description && (description[0] !== null) ?
                    <Grid item xs={12} md={12} lg={9} style={{ marginTop: '2rem' }}>
                        <Collapse accordion>
                            <Panel header="Informazioni aggiuntive" key="1">
                                <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                    {description}
                                </Typography>
                            </Panel>

                        </Collapse>

                    </Grid>
                    : null}
            </Grid>
        </Container>
    );
}

export default withRouter(ReadPdf);
