import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Progress} from 'antd';
import { useStyles } from "../../../components/MainTheme";

export default function ModuleCard(props) {
   
    const classes = useStyles();


    return (
        <Card>
        {props.coursePicture ? 
        <CardMedia
            style={{ minHeight: '200px' }}
            className={classes.media}
            image={props.coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${props.coursePicture.replace('\\', "/")}` : null}
            
        /> : null}
        <CardContent style={{ paddingTop: '30px' }}>

            <Typography gutterBottom variant="h6" component="h2">
                {props.moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length
                } di {props.modules.length} moduli completati
            </Typography>
            <Progress percent={props.percent} />
        </CardContent>

    </Card>
    );
}

ModuleCard.propTypes = {
    children: PropTypes.node,
};
