import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Context from '../Context'
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Progress, Modal, Result } from 'antd';
import { ExclamationCircleOutlined, SmileOutlined, FrownOutlined, LockOutlined } from '@ant-design/icons';
import SectionList from "./Components/Lists/SectionList";
import RelatedCoursCard from "./Components/Cards/RelatedCourseCard";
import SimpleCertificate from "./Components/Certificates/SimpleCertificate";
import RequestCertificate from "./Components/Certificates/RequestCertificate";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { useStyles } from "../components/MainTheme.js";

function UserModule() {

    const { user } = useContext(Context)
    const { course_id } = useParams()
    const { course_name } = useParams()

    const classes = useStyles();

    const [sections, setSections] = useState([]);
    const [coursePicture, setCoursePicture] = useState(null);
    const history = useHistory();

    const [courseTitle, setCourseTitle] = useState(null);
    const [courseDescription, setCourseDescription] = useState(null);
    const [sectionStatus, setSectionStatus] = useState([]);
    const [passFinalTest, setPassFianlTest] = useState(false)
    const [percent, setPercent] = useState(0);
    const [minutes, setMinutes] = useState(null)
    const [countQuestions, setQuestion] = useState(null);

    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [passedTest, setPassedTest] = useState(null)
    const [didNotPassed, setDidNotPassed] = useState(null)
    const [timesPasseTest, setTimesPasseTest] = useState(null);
    const [courseType, setCourseType] = useState(null);

    const [courseTotalTime, setCourseTotalTime] = useState(null);
    const [courseTimeLeft, setCourseTimeLeft] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [courseHoursLeft, setCourseHoursLeft] = useState(null);
    const [courseMinutesLeft, setCourseMinutesLeft] = useState(null);
    const [courseSecondsLeft, setCourseSecondsLeft] = useState(null);

    const [requestSent, setRequestSent] = useState(false);
    const [errorNetwork, setErrorNetwork] = useState(false);
    const [sending, setSending] = useState(false);
    const [checkCertificate, setCheckCertificate] = useState([]);
    const [certificateType, setCertificateType] = useState(null);



    const { confirm } = Modal;

    const moment = require('moment');


    useEffect(() => {
        getData()
    }, [])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setRequestSent(false)
        setErrorNetwork(false);
    }


    async function getCourseTotalTime() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTotalTime/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setCourseTotalTime(json)
                }
            });

    }

    async function getCourseTimeLeft() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTimeLeft/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setCourseTimeLeft(json)
                    var dur = moment.duration(json, 'seconds')
                    var hours = Math.floor(dur.asHours());
                    var mins = Math.floor(dur.asMinutes()) - hours * 60;
                    var sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;
                    setCourseHoursLeft(hours)
                    setCourseMinutesLeft(mins)
                    setCourseSecondsLeft(sec)

                }
            });

    }

    async function RequestCertificat() {

        // if (checkCertificate.length > 0) {
        //     setShowModal(true)
        // }
        // else {

        //     setSending(true) 
        //     const formData = new FormData();

        //     formData.append('course_id', course_id)
        //     formData.append('first_name', user.first_name)
        //     formData.append('last_name', user.last_name)
        //     formData.append('email', user.email)
        //     formData.append('score', `${Math.round((answerScore * 100) / testScore)}`)

        //     await fetch(`${process.env.REACT_APP_API_BASE_URL}/requestCertificat`, {
        //         method: "POST",
        //         body: formData
        //     }).then((response) => response.json()).then((rpns) => {
        //         if (rpns[0].msg === "success") {
        //             setRequestSent(true)
        //             getData()
        //             setSending(false)
        //         } else if (rpns[0].msg === "network_error") {
        //             setSending(false)
        //             setErrorNetwork(true)
        //         } else {
        //             setSending(false)
        //             setErrorNetwork(true)
        //         }
        //     })
        // }

        //   setSending(true) 

    }


    useEffect(() => {
        if (courseType && courseType === "Mandatory") {
            getCourseTotalTime()
            getCourseTimeLeft()

        }
    }, [courseType])

    useEffect(() => {

        if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70)) {
            addUserTestFaild()
            checkTimesPasseTest()
        }

        if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70))
            deleteUserTestFaild()

    }, [testScore, answerScore])

    async function checkTimesPasseTest() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkTimesPasseTestFinal/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setTimesPasseTest(json)
                    }
                }
            )
    }


    async function addUserTestFaild() {

        const formData = new FormData();
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/addUserTestFaildFinal`, {
            method: "POST",
            body: formData
        })
    }
    async function deleteUserTestFaild() {

        const formData = new FormData();
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteUserTestFaildFinal`, {
            method: "DELETE",
            body: formData
        })
    }


    async function getData() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getSections/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setSections(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCoursePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else if (json.picture_path) {
                        setCoursePicture(json.picture_path.replace('\\', "/"))
                    }
                }
            )
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/courseTitleDescription/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCourseTitle(json.title)
                        setCourseDescription(json.description)
                    }
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestionFinal/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)
                    if (json > 0) {
                        checkIfOtherTestPassed()
                        getScore()
                    }
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleFinalUser/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setPassedTest(json.if_pass_test)
                    setDidNotPassed(json.if_didnt_pass_test)
                }
            }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTitlePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCourseType(json.type)
                    }
                }
            )


        if (user) {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getSectionStatus/${course_id}`)
                .then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setSectionStatus(json)
                        }
                    )
        }

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificate/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setCheckCertificate(json)
                }
            }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificateType/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setCertificateType(json)
                }
            }
            )
    }


    async function getScore() {

        if (user) {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScoreFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setScoreAnswers(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setTestScore(json)
                    }
                )

        }
    }

    async function checkIfOtherTestPassed() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkIfOtherTestPassed/${course_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else if (json.msg === 'pass_final_test') {
                            setPassFianlTest(true)
                        }
                    }
                )
    }

    useEffect(() => {

        calculPercent()

    }, [sectionStatus])



    useEffect(() => {

        async function getUserTime() {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else if (json.msg !== 'not found') setMinutes(json)
                    }
                )
        }
        if (percent === 100) {
            getUserTime()
        }

    }, [percent])

    function calculPercent() {

        let moduleStatusLength = sectionStatus.filter(function (val) { return (val.finished_section === 1); }).length

        let sectionsLength = sections.length

        let per = ((moduleStatusLength) * 100) / sectionsLength

        setPercent(Math.floor(per))

    }

    function passeTest() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/0/test`,

        })
    }



    function showConfirm() {

        if (countQuestions && countQuestions > 0 && minutes) {
            if (passFinalTest) {
                confirm({
                    title: 'Importante!',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <div>
                            <p>Il tempo a disposizione è di: {minutes} minuti</p>
                            <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                            <p>Puoi selezionare una o più risposte che repute esatte</p>
                        </div>
                    ),
                    cancelText: 'Cancella',
                    onOk() {
                        passeTest()
                    },


                })
            } else canNotPassTest()

        } else setShowModal(true)

    }

    function canNotPassTest() {
        Modal.info({
            title: 'Importante!',
            content: (
                <div>
                    <p>Devi superare prima I test intermedi</p>
                </div>
            ),
            onOk() { },
        });
    }

    function info() {
        Modal.info({
            title: 'Importante!',
            content: (
                <div>
                    <p>Il test finale sarà presto disponibile</p>
                </div>
            ),
            onOk() { },
        });
    }

    function showConfirm2() {

        if (timesPasseTest < 3 && timesPasseTest && minutes) {
            confirm({
                title: 'Importante!',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <div>

                        <p>Questo è il: {timesPasseTest + 1} tentative che effettui per il test</p>
                        <p>Il tempo a disposizione è di: {minutes} minuti</p>
                        <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                        <p>Puoi selezionare una o più risposte che repute esatte</p>
                    </div>
                ),
                cancelText: 'Cancella',
                onOk() {
                    passeTest()
                },


            })
        } else if (timesPasseTest) {
            Modal.warning({
                title: 'Importante!',
                content: (
                    <div>
                        <p>Hai sbagliato il test 3 volte</p>
                        <p>non hai più tentative a disposizione</p>
                        <p>contattaci per richiedere lo sblocco </p>
                    </div>
                ),
            })
        }
    }

    function testResult() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/0/test-risultati`,

        })
    }
    const styles = {
        paperContainer: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: '#391085',
            height: '350px',
            marginBottom: '2rem',
            padding: '10px 70px 10px 70px',
            borderRadius: '0',
            color: '#fff',
            marginTop: '-3rem',
            fontSize: '40px',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#000033'
            // backgroundImage: `${coursePicture ? `url(${process.env.REACT_APP_API_BASE_URL_IMAGE}/${coursePicture})` : null}`
        }
    };

    const handleShowModal = (data) => {
        setShowModal(data);
    };

    return (
        <div>
            <Paper style={styles.paperContainer}>
                <Snackbar open={requestSent} autoHideDuration={6000} onClose={handleError} >
                    <Alert onClose={handleError} severity="success">
                        La tua richiesta di certificato è stata inviata riceverai una risposta nelle prossime 24/48h
                    </Alert>
                </Snackbar>
                <Snackbar open={errorNetwork} autoHideDuration={2000} onClose={handleError} >
                    <Alert onClose={handleError} severity="error">
                        Qualcosa è andato storto, riprova
                    </Alert>
                </Snackbar>
                {/* <SimpleCertificate checkCertificate={checkCertificate} course_id={course_id} showModal={showModal} closeModal={handleShowModal}/> */}
                <RequestCertificate course_id={course_id} showModal={showModal} closeModal={handleShowModal} />
                <Grid item style={{ textAlign: 'center' }}>
                    {courseTitle ? <Typography variant="h4" color="inherit">{courseTitle}</Typography> : null}
                    {courseDescription ?
                        <Grid item sx={12} sm={12} lg={12}>
                            <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                <div dangerouslySetInnerHTML={{ __html: courseDescription }} >
                                </div> </Typography>
                        </Grid> : null}
                </Grid>
            </Paper>

            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={12} lg={8}>
                        <Breadcrumb />

                        <SectionList sections={sections} sectionStatus={sectionStatus} coursePicture={coursePicture} course_name={course_name} course_id={course_id} />


                    </Grid>
                    <Grid container spacing={3} item xs={12} sm={12} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                        {courseTitle ?
                            <Grid item sm={8} lg={12}>
                                <Paper style={{ padding: '16px', textAlign: 'center' }}>
                                    <div className={classes.videoImage}>
                                        <img alt='' src={coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${coursePicture}` : null}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                height: '100%'
                                            }}></img>
                                    </div>
                                    <Typography variant='h6' style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                        {courseTitle}
                                    </Typography>
                                    <Progress percent={percent} />
                                    {answerScore.length === 0 ?
                                        <Tooltip arrow={true}
                                            disableHoverListener={percent === 100 ? true : false} placement="top"
                                            title={percent === 100 ? "Avvia test " : "Devi visualizzare tutti I Contenuti prima  di effettuare il test finale"}>
                                            <span>
                                                <Button
                                                    onClick={() => showConfirm()}
                                                    type='primary' style={{
                                                        marginTop: '1rem',

                                                    }} disabled={percent === 100 ? false : true} >
                                                    {countQuestions && countQuestions > 0 && minutes ? 'Vai al test finale' : 'RICHIEDI CERTIFICATO'}
                                                </Button>
                                            </span>
                                        </Tooltip> : null}
                                </Paper>
                            </Grid> : null}

                        {courseType && courseType === "Mandatory" && courseTotalTime ?
                            <Grid item sm={8} lg={12}>

                                <Paper style={{ padding: "6px", textAlign: "center" }}>
                                    <Typography variant="h6">Durata corso</Typography>
                                    <div style={{ textAlign: "center", padding: '2px', background: "limegreen" }}></div>
                                    <Typography variant="h6">
                                        {/* {moment.utc(courseTotalTime*1000).format('HH:mm:ss')} */}
                                        {(((courseTotalTime.hours / 3600) > 9) ? (courseTotalTime.hours / 3600) : ("0" + (courseTotalTime.hours / 3600))) + ":" + (((courseTotalTime.minutes / 60) > 9) ? (courseTotalTime.minutes / 60) : ("0" + (courseTotalTime.minutes / 60))) + ":" + "00"}
                                    </Typography>
                                </Paper>
                            </Grid>

                            : null}

                        {courseType && courseType === "Mandatory" && courseHoursLeft ?
                            <Grid item sm={8} lg={12}>

                                <Paper style={{ padding: "6px", textAlign: "center" }}>
                                    <Typography variant="h6">Tempo rimanente</Typography>
                                    <div style={{ textAlign: "center", padding: '2px', background: "limegreen" }}></div>
                                    <Typography variant="h6">
                                        {/* {moment.utc(courseTimeLeft*1000).format('HH:mm:ss')} */}
                                        {((courseHoursLeft > 9) ? courseHoursLeft : ("0" + courseHoursLeft)) + ":" + ((courseMinutesLeft > 9) ? courseMinutesLeft : ("0" + courseMinutesLeft)) + ":" + ((courseSecondsLeft > 9) ? courseSecondsLeft : ("0" + courseSecondsLeft))}
                                    </Typography>
                                </Paper>
                            </Grid>

                            : null}

                        {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) && passedTest ?
                            <Grid item lg={12}>
                                <Paper >
                                    <Result style={{ padding: '27px', wordBreak: 'break-word' }}
                                        status='success'
                                        icon={<SmileOutlined />}
                                        title={`${passedTest}`}
                                        subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                        extra={<Button type="primary" onClick={testResult}>Controlla I risultati</Button>}
                                    />
                                </Paper>
                            </Grid> : null}
                        {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70) && didNotPassed ?
                            <Grid item lg={12}>
                                <Paper >
                                    <Result style={{ padding: '27px', wordBreak: 'break-word' }}
                                        status='error'
                                        icon={<FrownOutlined />}
                                        title={`${didNotPassed}`}
                                        subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                        extra={<Button
                                            onClick={() => showConfirm2()}
                                            type='primary' style={{
                                                marginTop: '1rem',

                                            }} disabled={percent === 100 ? false : true} >
                                            Riprova test
                                        </Button>}

                                    />
                                </Paper>
                            </Grid> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} lg={11}>
                        <Grid container spacing={3} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                            <RelatedCoursCard course_id={course_id} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div >
    );
}

export default withRouter(UserModule);
