import React, { useContext, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Context from "../Context";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MuiAlert from "@material-ui/lab/Alert";
import { Button } from "antd";

function GuestHome() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);

  const [noCourse, setNoCourse] = useState(false);
  const [image, setImage] = useState(null);

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) getData();
    return () => {
      isMounted = false;
    };
  }, []);

  async function getData() {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getGuestCourses`)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        if (json.length === 0) setNoCourse(true);
      });

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getBackgroundImageGuest/home`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.image_path) setImage(json.image_path.replace("\\", "/"));
      });
  }

  const styles = {
    paperContainer: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundColor: "#6c6e7d",
      height: "350px",
      marginTop: "-3rem",
      marginBottom: "3rem",
      borderRadius: "0",
      color: "#fff",
      fontSize: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `${image
          ? `url(${process.env.REACT_APP_API_BASE_URL_IMAGE}/${image})`
          : null
        }`,
    },
  };

  function goToCourse(item) {
    history.push({
      pathname: `/corsi/${item.title.toLowerCase().replaceAll(" ", "-")}/${item.id
        }`,
    });
  }
  function Alert(props) {
    return <MuiAlert elevation={2} variant="outlined" {...props} />;
  }
  return (
    <div>
      <Paper style={styles.paperContainer}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h3" color="inherit">
              {" "}
              Ospite{" "}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={4} lg={3} key={index}>
              <Card
                key={index}
                className={classes.cardHeight}
                onClick={() => goToCourse(item)}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_API_BASE_URL_IMAGE
                      }/${item.picture_path.replace("\\", "/")}`}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Corso
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ marginTop: "auto" }}>
                  <Button block={true} size="small" type="default">
                    Partecipa al corso
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}

          {noCourse ? (
            <Alert severity="info">
              La pagina che hai richiesto non è disponibile
            </Alert>
          ) : null}
        </Grid>
      </Container>
    </div>
  );
}

export default GuestHome;
