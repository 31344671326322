import "../App.css";
import React, { useState, useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import TextField from '@mui/material/TextField';
import Typography from "@material-ui/core/Typography";
import { withRouter, useHistory, useParams } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useForm } from "react-hook-form";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, InputNumber } from "antd";
import { EditOutlined } from "@ant-design/icons";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

function AddQuestion(props) {
    const { course_id } = useParams();
    const { section_id } = useParams();
    const { course_name } = useParams();
    const { section_name } = useParams();

    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [saved, setSaved] = useState(false);
    const [notSaved, setNotSaved] = useState(false);
    const [errorScore, setErrorScore] = useState(false);
    const [ScoreNotSame, setScoreNotSame] = useState(false);
    const [errorCorrectAnswer, setErrorCorrectAnswer] = useState(false);
    const [loading, setLoading] = useState(false);
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const [testTitle, setTestTitle] = useState(null);
    const [testTitleAdded, setTestTilteAdded] = useState(false);
    const [answersType, setAnswersType] = useState(null);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const checkbx = useRef(null);

    const [answers, setAnswers] = useState([
        { answer: "", score: "0", correct_answer: false },
        { answer: "", score: "0", correct_answer: false },
    ]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleChangeInput = (index, event) => {
        const values = [...answers];
        if (event.target.id === "correct_answer") {
            if (event.target.checked === true) values[index][event.target.id] = true;
            else {
                values[index][event.target.id] = false;
            }
        } else {
            values[index][event.target.id] = event.target.value;
        }

        setAnswers(values);
    };
    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData();
        return () => {
            isMounted = false;
        };
    }, []);

    async function getData() {
        if (section_id === "0")
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleFinal/${course_id}`)
                .then((response) => response.json())
                .then((json) => {
                    if (json.title) setTestTitle(json.title);
                    else setTestTilteAdded(true)
                });
        else
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitle/${course_id}/${section_id}`)
                .then((response) => response.json())
                .then((json) => {
                    if (json.title) setTestTitle(json.title);
                    else setTestTilteAdded(true)
                });
    }

    const handleAddAnswer = () => {
        setAnswers([...answers, { answer: "", score: "0", correct_answer: false }]);
    };

    const handleRemoveAnswer = (index) => {
        if (index !== 0 && index !== 1) {
            const values = [...answers];
            values.splice(index, 1);
            setAnswers(values);
        }
    };

    function cancel() {
        history.goBack(1);
    }

    const [finished, setFinished] = useState(false);

    const addtest = async (data, e) => {
        
        var score_sum = answers.reduce((a, v) => a = a + parseInt(v.score), 0);

        setLoading(true);

        if (parseInt(data.question_score) !== score_sum) {
            setScoreNotSame(true);
            setLoading(false);
        }
        else if (
            answers
                .filter((item) => item.answer === "")
                .map((item, index) => index)
                .toString()
        ) {
            setError(true);
            setLoading(false);
        } else if (
            answers.filter((item) => item.score === "0").map((item, index) => index)
                .length === answers.length
        ) {
            setErrorScore(true);
            setLoading(false);
        } else if (
            answers
                .filter((item) => item.correct_answer === true)
                .map((item, index) => index).length === 0
        ) {
            setErrorCorrectAnswer(true);
            setLoading(false);
        } else if (!finished) {
            const formData = new FormData();
            formData.append("course_id", course_id);
            formData.append("section_id", section_id);
            formData.append("question", data.question);
            formData.append("score", data.question_score);
            formData.append("answers", JSON.stringify(answers));
            if(answersType)
            formData.append("answers_type", answersType);

            if (section_id === "0") {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/addQuestionFinal`, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.msg === "success") {
                            reset();
                            e.target[7].value = 0;
                            e.target[12].value = 0;
                            setAnswers([
                                { answer: "", score: "0", correct_answer: false },
                                { answer: "", score: "0", correct_answer: false },
                            ]);
                            setLoading(false);
                            setSaved(true);
                        } else {
                            setLoading(false);
                            setNotSaved(true);
                        }
                    });
            } else {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/addQuestion`, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.msg === "success") {
                            reset();
                            e.target[7].value = 0;
                            e.target[12].value = 0;
                            setAnswers([
                                { answer: "", score: "0", correct_answer: false },
                                { answer: "", score: "0", correct_answer: false },
                            ]);
                            setLoading(false);
                            setSaved(true);
                        } else {
                            setLoading(false);
                            setNotSaved(true);
                        }
                    });
            }

           
        } else {
            const formData = new FormData();
            formData.append("course_id", course_id);
            formData.append("section_id", section_id);
            formData.append("question", data.question);
            formData.append("score", data.question_score);
            formData.append("answers", JSON.stringify(answers));
            if(answersType)
              formData.append("answers_type", answersType);

            if (section_id === "0") {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/addQuestionFinal`, {
                    method: "POST",
                    body: formData,
                }).then(() => setLoading(false), history.goBack(1));
            } else {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/addQuestion`, {
                    method: "POST",
                    body: formData,
                }).then(() => setLoading(false), history.goBack(1));
            }
        }
    };

    const style = {
        padding: "20px",
    };

    const gridStyle = {
        display: "flex",
        justifyContent: "space-between",
    };

    const handleError = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setError(false);
        setErrorScore(false);
        setErrorCorrectAnswer(false);
        setSaved(false);
        setNotSaved(false);
        setScoreNotSame(false);

    };

    function addTestTitle() {
        if (section_id === "0")
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/0/nome-del-test`,
            });
        else
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/nome-del-test`,
            });
    }

    function updateTestTitle() {
        if (section_id === "0")
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/0/aggiorna-nome-del-test`,
            });
        else
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/aggiorna-nome-del-test`,
            });
    }

    const handleChangeType = (event) => {
        setAnswersType(event.target.value)
      };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify="flex-end">
                <Breadcrumb />

                {testTitle ? (
                    <Grid item sx={12} sm={12} lg={12}>
                        <Paper>
                            <div style={{ textAlign: "center", background: "limegreen" }}>
                                <Typography variant="h5">Nome del test</Typography>
                            </div>
                        </Paper>
                        <Paper
                            style={{
                                padding: "10px 15px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="h6"> {testTitle} </Typography>
                            <Button
                                icon={
                                    <EditOutlined
                                        onClick={() => updateTestTitle()}
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            />
                        </Paper>
                    </Grid>
                ) : null}
                <Grid item xs={12} lg={12}>
                    {testTitleAdded ? (
                        <Button
                            type="primary"
                            className={classes.button}
                            onClick={() => addTestTitle()}
                        >
                            {" "}
                            Aggiungi un titolo al test{" "}
                        </Button>
                    ) : null}
                    <Paper className={maxHeightPaper} style={style}>
                        <Typography variant="h6" className={classes.title}>
                            Domande
                        </Typography>
                        <form
                            className={classes.root}
                            onSubmit={handleSubmit(addtest)}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                style={{ marginBottom: "20px", display: "flex" }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    lg={10}
                                    style={{ marginRight: "2rem" }}
                                >
                                    <TextField
                                        {...register("question", { required: true })}
                                        required
                                        fullWidth
                                        id="outlined-full-width"
                                        label="Domande"
                                        placeholder="Domande"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size="small"
                                        variant="outlined"
                                        error={errors?.question?.type === "required" && true}
                                        helperText={
                                            errors?.question?.type === "required" &&
                                            "Il campo è obbligatorio"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} lg={1}>
                                    <TextField
                                        size="small"
                                        required
                                        label="Punteggio"
                                        placeholder="Punteggio"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={0}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        type="number"
                                        variant="outlined"
                                        id="score"
                                        {...register("question_score", { required: true })}
                                        error={errors?.question_score?.type === "required" && true}
                                        helperText={
                                            errors?.question_score?.type === "required" &&
                                            "Il campo è obbligatorio"
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={12} sm={12} lg={12}>
                <InputLabel htmlFor="age-native-helper">Choose answers type</InputLabel>
                <NativeSelect
                  required
                  onChange={handleChangeType}
                  inputProps={{
                    name: 'type',
                  }}
                >
                  <option value='OneAnswer'>One answer</option>
                  <option value='MultipleAnswers'>Multiple answers</option>
                </NativeSelect>
              </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                style={{ display: "flex", justifyContent: "space-between", marginTop: '1rem' }}
                            >
                                <Typography variant="h6" className={classes.title}>
                                    Risposte
                                </Typography>
                                <Typography className={classes.title}>
                                    Risposte esatte
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {answers.map((item, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            lg={12}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <Typography
                                                color="primary"
                                                style={{ marginRight: "1rem" }}
                                            >
                                                {index + 1}
                                            </Typography>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={10}
                                                lg={10}
                                                style={{ marginRight: "2rem" }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    label={index + 1}
                                                    placeholder="Risposte"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    id="answer"
                                                    onChange={(event) => handleChangeInput(index, event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} lg={1}>
                                                <TextField
                                                   required
                                                    size="small"
                                                    label="Punteggio"
                                                    placeholder="Punteggio"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    defaultValue={0}
                                                    type="number"
                                                    variant="outlined"
                                                    id="score"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    onChange={(event) => handleChangeInput(index, event)}
                                                />
                                               
                                            </Grid>
                                            <Grid item xs={12} sm={2} lg={1}>
                                                <Checkbox
                                                    checked={answers[index].correct_answer}
                                                    id="correct_answer"
                                                    ref={checkbx}
                                                    onChange={(event) => handleChangeInput(index, event)}
                                                    color="primary"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                                {index >= 2 ? (
                                                    <IconButton onClick={() => handleRemoveAnswer(index)}>
                                                        <RemoveIcon />
                                                    </IconButton>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    lg={12}
                                    style={{
                                        marginBottom: "3rem",
                                        marginRight: "2rem",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Fab
                                        size="small"
                                        style={{ background: "#1890ff", color: "#fff" }}
                                        aria-label="add"
                                        onClick={() => handleAddAnswer()}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                                <Snackbar
                                    open={saved}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="success">
                                        Domande salvate correttamente
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={notSaved}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="error">
                                        Qualcosa è andato storto, riprova
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={error}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="error">
                                        Aggiungi una risposta
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={ScoreNotSame}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="error">
                                        domanda e risposte non hanno lo stesso punteggio
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={errorScore}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="error">
                                        Aggiungi almeno un punteggio ad una risposta
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={errorCorrectAnswer}
                                    autoHideDuration={2000}
                                    onClose={handleError}
                                >
                                    <Alert onClose={handleError} severity="error">
                                        Imposta almeno una risposta corretta
                                    </Alert>
                                </Snackbar>
                            </Grid>

                            <Grid item xs={12} lg={12} style={gridStyle}>
                                <div>
                                    <Button
                                        style={{ width: "120px" }}
                                        className={classes.button}
                                        onClick={cancel}
                                        disabled={loading}
                                    >
                                        Cancella
                                    </Button>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Button
                                        onClick={() => setFinished(true)}
                                        loading={loading}
                                        style={{ width: "120px" }}
                                        type="primary"
                                        className={classes.button}
                                        htmlType="submit"
                                    >
                                        Salva
                                    </Button>
                                    <Button
                                        loading={loading}
                                        onClick={() => setFinished(false)}
                                        type="primary"
                                        className={classes.button}
                                        htmlType="submit"
                                    >
                                        Salva e continua
                                    </Button>
                                </div>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(AddQuestion);
