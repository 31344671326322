import React, { useState, useEffect } from 'react';
import { Modal, Button, Space, Form, InputNumber } from 'antd';
import { useForm } from 'react-hook-form';

const ModalAddCourseTotalTime = (props) => {

  const [form] = Form.useForm();
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const moment = require('moment');
  require("moment-duration-format");


  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setIsModalVisible(props.showModal)
    if (props.defaultValue) {
      setHours(props.defaultValue.hours / 3600)
      setMinutes(props.defaultValue.minutes / 60)

      // setMinutes(moment(moment.utc(props.defaultValue.minutes * 1000).format('HH:mm:ss'), 'HH:mm:ss'))
    }
  }, [props])


  const onSubmit = async (data) => {

    setLoading(true)

    // var hours =   data.course_time.hours() 
    // var minutes =  data.course_time.minutes()
    // var seconds = data.course_time.seconds()
    // var total_in_second = hours*3600 + minutes * 60 + seconds;

    var hour_in_seconds = moment.duration(data.course_hours, "hours").asSeconds()
    var min_in_seconds = moment.duration(data.course_minutes, "minutes").asSeconds()
    // var total_in_seconds = hour_in_seconds + min_in_seconds;

    const formData = new FormData();
    formData.append("hours", hour_in_seconds);
    formData.append("minutes", min_in_seconds);
    formData.append("course_id", props.course_id);

    if (!props.defaultValue) {
      // both mandatory and normal course
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/addCourseTotalTime`, {
        method: "POST",
        body: formData,
      }).then(() => {
        handleCancel();
      }).then(() => setLoading(false));
    } else {

      await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateCourseTotalTime`, {
        method: "POST",
        body: formData,
      }).then(() => {
        handleCancel();
      }).then(() => setLoading(false));
    }

  };

  const handleCancel = () => {
    reset()
    setHours(null)
    setMinutes(null)
    props.closeModal(false)
    props.saved()
  };





  return (
    <Modal title="Durata corso" visible={isModalVisible} onOk={form.submit} onCancel={handleCancel}
      footer={[
        <Button key="back" disabled={loading} onClick={handleCancel}>
          Cancella
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          Salva
        </Button>]}>
      <Form form={form} onFinish={handleSubmit(onSubmit)}>
        <Space direction="horizontal" style={{ display: 'flex' }}>
          <InputNumber min={0} defaultValue={hours} addonBefore="ORE" {...register('course_hours', { required: true })} onChange={(hours) => {
            setValue('course_hours', hours)
          }} />
          <InputNumber min={0} max={59} defaultValue={minutes} addonBefore="Minuti" {...register('course_minutes')} onChange={(minutes) => {
            setValue('course_minutes', minutes)

          }} />
          {errors.course_time && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}

        </Space>
      </Form>
    </Modal>
  );
};

export default ModalAddCourseTotalTime;