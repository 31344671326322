import "../App.css";
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withRouter, useParams, useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import VideoAdmin from "../AdminPages/VideoAdmin";
import Video2 from "../AdminPages/Video2";
import Typography from '@material-ui/core/Typography';
import { Button, Collapse } from "antd";
import VimeoPlayer from "react-player/vimeo";
import YouTubePlayer from "react-player/youtube";
import {
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';


const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        width: "100%",

        position: "relative",
        // "&:hover": {
        //   "& $controlsWrapper": {
        //     visibility: "visible",
        //   },
        // },
    },

    controlsWrapper: {
        visibility: "hidden",
        position: "absolute",
        marginBottom: '5px',
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    topControls: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2),
    },
    middleControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomWrapper: {
        display: "flex",
        flexDirection: "column",

        // background: "rgba(0,0,0,0.6)",
        // height: 60,
        padding: theme.spacing(2),
    },

    bottomControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // height:40,
    },

    button: {
        margin: theme.spacing(1),
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,
    },
    root: {
        fontSize: '16px',
        color: '#fff',
        background: '#2a2a2a',
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '346px',

    },
    listItem: {
        '&:hover': {
            backgroundColor: '#595959'
        }
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,

    },
    videoImage: {
        paddingRight: '10px',
    }
}));
function VideoPageAdmin(props) {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { module_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()

    const history = useHistory();
    const [modules, setModules] = useState(null);
    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);

    const [urlVideo, setVideoUrl] = useState(null);
    // const [videos, setVideos] = useState([]);
    // const [videoId, setVideoId] = useState(module_id);
    //const [coursePicture, setCoursePicture] = useState([]);


    useEffect(() => {
        getData()

    }, [])

    async function getData() {
        // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getVideoUrlAdmin/${videoId}`).then(
        //     response => response.json()).then(
        //         json => setVideoUrl(json.url)
        //     )
        // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getvideosAdmin/${section_id}`).then(
        //     response => response.json()).then(
        //         json => setVideos(json)

        //     )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePathAdmin/${module_id}`)
            .then((response) => response.json())
            .then((path) => setVideoUrl(path.module_path));


        // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCoursePicture/${course_id}`).then(
        //     response => response.json()).then(
        //         json => setCoursePicture(json)

        //     )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`)
            .then((response) => response.json())
            .then((json) => setModules(json));

    }

    // function changeVideo(id) {
    //     window.history.replaceState({ section_id }, null, `./${id}`)
    //     setVideoId(id)
    // }
    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);

    function handler() {
        getData()
    }

    function nextModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })

        if (type === 'pdf') {
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            //window.location.reload(false);
        }

        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
        if (type === 'ppt')
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })


        if (type === 'pdf') {
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            // window.location.reload(false);
        }

        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
        if (type === 'ppt')
            history.push({
                pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
    }

    return (

        <Container maxWidth="lg">
            <Grid container spacing={3} >
                <Grid container justify='center' item xs={12} lg={12} >
                    <Grid item xs={12} lg={8}>
                        <Breadcrumb />
                    </Grid>
                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginBottom: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
                            ><LeftOutlined />precedente</Button>}
                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button type="primary" onClick={nextModule}>Prossimo modulo<RightOutlined /></Button>}
                        </Grid> : null}
                    <Grid item xs={12} lg={8}>

                        {urlVideo && urlVideo.includes('drive.google') ? <VideoAdmin url={urlVideo} id={module_id} handler={handler} /> : null}
                        {urlVideo && urlVideo.includes('modules') ? <VideoAdmin url={`${process.env.REACT_APP_API_BASE_URL}/downloadAdmin/${urlVideo}`} id={module_id} handler={handler} /> : null}


                        {urlVideo && urlVideo.includes('vimeo') ?
                            <VimeoPlayer url={urlVideo} width='820px' height='500px' /> : null}

                        {urlVideo && urlVideo.includes('youtu.be') ?
                            <YouTubePlayer url={urlVideo} controls width='798px' height='500px' /> : null}


                        {/* <Video2 url={urlVideo} id={module_id} handler={handler} /> */}

                    </Grid>
                    {/* <Grid item xs={12} lg={4} style={{
                        flex: '1',
                        background: '#2a2a2a',
                        // height: '99.9%'
                    }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            padding: '20px 30px',
                            background: '#252525',
                            color: '#fff',
                            fontSize: '15px'
                        }}>
                            <Typography variant='h6' style={{ color: "#fff", fontWeight: 'bold' }}>Lessons</Typography>
                            <span >{videos.length} Lessons</span>
                        </div>
                        <List className={classes.root} >
                            <li className={classes.listSection} >
                                <ul className={classes.ul}>
                                    {videos.map((item, index) => (
                                        <ListItem
                                            className={classes.listItem}
                                            key={index} button onClick={() => changeVideo(item.id)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'end',

                                            }}>
                                            <div className={classes.videoImage}>
                                                {videoId === item.id ?
                                                    <Typography style={{
                                                        color: 'rgb(255, 199, 2)',
                                                        fontSize: '1rem',
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                        </svg>
                                                    </Typography> :
                                                    <Typography style={{
                                                        color: '#fff',
                                                        fontSize: '1rem',
                                                        fontWeight: 700,
                                                        marginRight: '10px'
                                                    }}>
                                                        {index + 1}
                                                    </Typography>}

                                            </div>
                                            <div className={classes.videoImage}>
                                                <img alt="" src={coursePicture.length > 0 ? '/' + coursePicture[0].replace('\\', "/") : null} style={{
                                                    display: 'block',
                                                    width: '70px',
                                                    height: 'auto',
                                                    maxWidth: 'none',
                                                    margin: '0 0 0 0',
                                                }}></img>
                                            </div>
                                            <Typography variant='h6' style={{
                                                marginTop: '0px',
                                                marginBottom: '.25rem',
                                                fontWeight: '500',
                                                color: '#fff',
                                            }} >
                                                {item.video_name}
                                            </Typography>

                                        </ListItem>
                                    ))}
                                </ul>
                            </li>

                        </List>
                    </Grid> */}
                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
                            ><LeftOutlined />precedente</Button>}
                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button type="primary" onClick={nextModule}>Prossimo modulo<RightOutlined /></Button>}
                        </Grid> : null}
                    {urlVideo && description && (description[0] !== null) ?
                        <Grid item xs={12} lg={8} style={{ marginTop: '2rem' }}>
                            <Collapse accordion>
                                <Panel header="Informazioni aggiuntive" key="1">
                                    <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                    </Typography>
                                </Panel>

                            </Collapse>



                        </Grid>
                        : null}


                </Grid>
            </Grid>

        </Container >
    );
}

export default withRouter(VideoPageAdmin);
