import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Context from '../Context'
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from 'antd';
import CardHome from "./Components/Cards/HomeCard";


function Home() {

    const history = useHistory();

    const { user } = useContext(Context)

    const [noCourse, setNoCourse] = useState(false)
    const [noAssignedCourse, setNoAssignedCourse] = useState(false)
    const [image, setImage] = useState(null)

    var resume_course_url = JSON.parse(localStorage.getItem(`${user.id}_url`));

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getBackgroundImage/home`).then(
            response => response.json()).then(
                (json) => {
                    if (json.image_path)
                        setImage(json.image_path.replace('\\', "/"))
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserData`).then(
            response => response.json()).then(
                json => setUserData(json)
            )

        // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getOnlineUsers`).then(
        //     response => response.json()).then(
        //         json => console.log(json)
        //     )


    }

    const styles = {
        paperContainer: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: '#6c6e7d',
            height: '350px',
            marginTop: '-3rem',
            marginBottom: '3rem',
            borderRadius: '0',
            color: '#fff',
            fontSize: '40px',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            backgroundImage: `${image ? `url(${process.env.REACT_APP_API_BASE_URL_IMAGE}/${image})` : null}`
        }
    };
    function resume() {
        history.push(
            {
                pathname: `${resume_course_url}`,

            }
        )
    }

    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }

    const handleNoCourses = (data) => {
        setNoCourse(data)
    }
    const handleNoAssignedCourses = (data) => {
        setNoAssignedCourse(data)
    }


    return (
        <div>
            <Paper style={styles.paperContainer} >
                <Grid item xs={12} style={{ textAlign: 'center' }}>

                    {user ? <Grid item xs={12}>
                        <Typography variant="h3" color="inherit"> {userData.name} {userData.surname} </Typography>
                    </Grid> : null}

                    {/* {resume_course_url ?
                        <Grid item xs={12}>
                            <Button onClick={() => resume()} type='primary' style={{ backgroundColor: '#faad14', borderColor: '#faad14' }}>
                            Riprendi corso 
                            </Button>
                        </Grid> : null} */}
                </Grid>
            </Paper>
            <Container maxWidth="lg">
                <Grid container spacing={3} style={{ marginBottom: '2rem' }}>
                    <CardHome courses={true} assignedCourses={false} noCourses={handleNoCourses} />
                    <CardHome assignedCourses={true} courses={false} noAssignedCourses={handleNoAssignedCourses} />
                    <CardHome assignedCourses={false} courses={false} stopedCourses={true} noCourses={handleNoCourses} noAssignedCourses={handleNoAssignedCourses} />
                </Grid>

                <Grid container spacing={3}>
                    {noCourse && noAssignedCourse ? <Alert severity="info">La pagina che hai richiesto non è disponibile</Alert> : null}
                </Grid>
            </Container>

        </div >

    );
}

export default Home;
