import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withRouter, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../MainTheme.js";
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Context from '../../Context'



function BreadcrumbSpecfic(props) {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { module_id } = useParams()
    const { video_id } = useParams()
    const { user_id } = useParams()
    const { product_id } = useParams()
    const { history, location: { pathname, state } } = props



    const { user } = useContext(Context)

    var index;
    const pathnames = pathname.split("/").filter(x => x)
    const classes = useStyles();


    if (section_id) {
        index = pathnames.indexOf(`${section_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }

    if (course_id) {
        index = pathnames.indexOf(`${course_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }
    if (module_id) {
        index = pathnames.indexOf(`${module_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }

    if (user_id) {
        index = pathnames.indexOf(`${user_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }

    if (product_id) {
        index = pathnames.indexOf(`${product_id}`);
        if (index > -1) {
            pathnames.splice(index, 1);
        }

    }

 



    function goToPageAdmin(goTo) {

        if (goTo === '/admin-spc/corsi/studenti-iscritti')
            history.push({
                pathname: `/admin-spc/corsi/${product_id}/studenti-iscritti`,
                state: state
            })
            else if (goTo === '/admin-spc/corsi/studenti-iscritti/corsi')
            history.push({
                pathname: `/admin-spc/corsi/${product_id}/studenti-iscritti/${user_id}/corsi`,
                state: state
            })
            else if (goTo === '/admin-spc/corsi/studenti-iscritti/corsi/sezioni')
            history.push({
                pathname: `/admin-spc/corsi/${product_id}/studenti-iscritti/${user_id}/corsi/${course_id}/sezioni`,
                state: state
            })

            else if (goTo === '/admin-spc/studenti-iscritti')
            history.push({
                pathname: `/admin-spc/studenti-iscritti`,
                state: state
            })

            else if (goTo === '/admin-spc/studenti-iscritti/corsi')
            history.push({
                pathname: `/admin-spc/studenti-iscritti/${user_id}/corsi`,
                state: state
            })

            else if (goTo === '/admin-spc/studenti-iscritti/corsi/sezioni')
            history.push({
                pathname: `/admin-spc/studenti-iscritti/${user_id}/corsi/${course_id}/sezioni`,
                state: state
            })
  
        else {
            history.push({
                pathname: `/admin-spc/corsi`,
                state: state
            })
        }
    }
 

    return (
        <Grid item xs={10} sm={12} lg={12}>
            {user ?
                <Grid item xs={12} lg={12}>
                    {user.user_type === 'ADM_LMT' ?
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}  style={{marginBottom: '1rem'}}
                            aria-label="breadcrumb" >
                            {pathnames.filter(name => name !== 'admin-spc').map((name, index) => {
                                const goTo = `/${pathnames.slice(0, index + 2).join('/')}`
                                const isLast = index === pathnames.length - 2
                                const f_name = name.replaceAll('-', ' ')
                                // f_name = name.replaceAll('-spc', '')

                                return isLast ? (<Typography key={index} color="textPrimary" >
                                    {index === 0 ? <HomeIcon className={classes.icon} /> : null} {f_name}</Typography>) :

                                    (<Link style={{  cursor: 'pointer' }}
                                        key={index} color="inherit" onClick={() => goToPageAdmin(goTo)}>
                                        {index === 0 ? <HomeIcon className={classes.icon} /> : null} {f_name} </Link>)

                            })

                            }

                        </Breadcrumbs> :
                       null}
                </Grid> : null}

        </Grid >
    );
}


export default withRouter(BreadcrumbSpecfic)