import React, { useContext, useState, useEffect, useRef } from "react";
import Container from '@material-ui/core/Container';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import { Result } from 'antd';
import { SmileOutlined, LoadingOutlined } from '@ant-design/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, Spin } from 'antd';
import Context from '../Context'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SimpleCertificate from "./Components/Certificates/SimpleCertificate";
import RequestCertificate from "./Components/Certificates/RequestCertificate";
import ReactToPrint from 'react-to-print';




function TestResult() {
    const classes = useStyles();

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()
    const history = useHistory();

    const [questions, setQuestion] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [minutes, setMinutes] = useState('0')
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const [answers, setAnswer] = useState([]);
    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')

    const [passedTest, setPassedTest] = useState(null)
    const [testTitle, setTestTitle] = useState(null)

    const [requestSent, setRequestSent] = useState(false);
    const [errorNetwork, setErrorNetwork] = useState(false);
    const [sending, setSending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checkCertificate, setCheckCertificate] = useState([]);
    const [courseType, setCourseType] = useState(null);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const componentRef = useRef();

    const { user } = useContext(Context)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        if (section_id === '0') {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getPublishedQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setQuestion(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAnswerFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAnswer(json)
                    }
                )


            if (user) {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScoreFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setScoreAnswers(json)
                        }
                    )

                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestionFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setTestScore(json)
                        }
                    )

                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentAnswersFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setUserAnswers(json)
                        }
                    )
            }

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleFinalUser/${course_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setPassedTest(json.if_pass_test)
                        setTestTitle(json.title)
                    }
                }
                )

        }
        else {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTime/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getPublishedQuestion/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setQuestion(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAnswer/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAnswer(json)
                    }
                )


            if (user) {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScore/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setScoreAnswers(json)
                        }
                    )

                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setTestScore(json)
                        }
                    )

                await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentAnswers/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setUserAnswers(json)
                        }
                    )
            }

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleUser/${course_id}/${section_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setPassedTest(json.if_pass_test)
                        setTestTitle(json.title)
                    }
                }
                )
        }

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificate/${course_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setCheckCertificate(json)
                }
            }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json)
                }

            )

    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setRequestSent(false)
        setErrorNetwork(false);
    }

    // async function RequestCertificat() {        

    //     if (checkCertificate.length > 0) {
    //         setShowModal(true)
    //     }
    //     else {

    //         setSending(true) 
    //         const formData = new FormData();

    //         formData.append('course_id', course_id)
    //         formData.append('first_name', user.first_name)
    //         formData.append('last_name', user.last_name)
    //         formData.append('email', user.email)
    //         formData.append('score', `${Math.round((answerScore * 100) / testScore)}`)

    //         await fetch(`${process.env.REACT_APP_API_BASE_URL}/requestCertificat`, {
    //             method: "POST",
    //             body: formData
    //         }).then((response) => response.json()).then((rpns) => {
    //             if (rpns[0].msg === "success") {
    //                 setRequestSent(true)
    //                 getData()
    //                 setSending(false)
    //             } else if (rpns[0].msg === "network_error") {
    //                 setSending(false)
    //                 setErrorNetwork(true)
    //             } else {
    //                 setSending(false)
    //                 setErrorNetwork(true)
    //             }
    //         })
    //     }
    // }
    const answerStyle = {
        backgroundColor: '#f3f3f3', display: 'flex', alignItems: 'center',
        marginBottom: '10px', borderRadius: '12px',
        padding: '0px 15px 0 0', width: 'fit-content',
        marginLeft: '10px',
        marginTop: '15px'
    }

    function Home() {

        if (section_id === '0') {
            history.push({
                pathname: `/home/${course_name}/${course_id}`,
            })
        }
        else {
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}`,
            })
        }
    }

    const handleShowModal = (data) => {
        setShowModal(data);
    };


    return (
        <div >
            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>

                    <Snackbar open={requestSent} autoHideDuration={6000} onClose={handleError} >
                        <Alert onClose={handleError} severity="success">
                            La tua richiesta di certificato è stata inviata riceverai una risposta nelle prossime 24/48h
                        </Alert>
                    </Snackbar>
                    <Snackbar open={errorNetwork} autoHideDuration={2000} onClose={handleError} >
                        <Alert onClose={handleError} severity="error">
                            Qualcosa è andato storto, riprova
                        </Alert>
                    </Snackbar>

                    {/* <SimpleCertificate checkCertificate={checkCertificate} course_id={course_id} showModal={showModal} closeModal={handleShowModal}/> */}
                    <RequestCertificate course_id={course_id} showModal={showModal} closeModal={handleShowModal} />

                    <Grid item xs={12} sm={8} lg={8}>
                        {questions.length > 0 && answers.length > 0 ? <Breadcrumb /> : null}

                        {testTitle && questions.length > 0 && answers.length > 0 ?
                            <Grid container spacing={1} ref={componentRef}>

                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper style={{ textAlign: 'center', padding: '10px' }}>
                                        <Typography variant="h4">  {testTitle}</Typography>
                                    </Paper>

                                </Grid>

                                {questions.map((item, index) => (

                                    <Grid key={index} item xs={12} sm={8} lg={12}>

                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Typography variant="h6" className={classes.title} style={{ marginRight: '1em' }}>
                                                            {index + 1}/{questions.length}
                                                        </Typography>
                                                        <Typography variant="h5" className={classes.title} >
                                                            {item.question}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="h6" className={classes.title} >
                                                        {userAnswers.filter(ans => ans.question_id === item.id).map((item) => { return item.score; })} punti
                                                    </Typography>
                                                </Grid>
                                            </AccordionSummary>
                                            <Divider variant='fullWidth' />
                                            {item.answers_type === 'MultipleAnswers' && answers.filter(id => (id.question_id === item.id) || (id.question_final_id === item.id)).map((item, index) => (
                                                <AccordionDetails key={index} style={{
                                                    padding: '0px 15px 0px 0px',
                                                    width: 'fit-content',
                                                    marginLeft: '10px',
                                                    alignItems: 'center'
                                                }}>
                                                    {item.correct_answer === 1 ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CheckCircleIcon style={{ color: 'green' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div> :
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CancelIcon style={{ color: 'red' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div>}
                                                </AccordionDetails>
                                            ))}

                                            {item.answers_type === 'OneAnswer' ?
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"  >
                                                    {answers.filter(id => (id.question_id === item.id) || (id.question_final_id === item.id)).map((item) => (
                                                        <AccordionDetails
                                                            key={item.id}
                                                            style={{
                                                                padding: '0px 15px 0px 0px',
                                                                width: 'fit-content',
                                                                marginLeft: '10px',
                                                                alignItems: 'center'
                                                            }}>
                                                            {item.correct_answer === 1 ?
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <CheckCircleIcon style={{ color: 'green' }} />
                                                                    <div >
                                                                        <FormControlLabel style={answerStyle} value={item.answers} control={<Radio
                                                                            checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                                ? true : false}
                                                                            disabled={true} />} label={item.answers} />
                                                                    </div>
                                                                </div> :
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <CancelIcon style={{ color: 'red' }} />
                                                                    <div >
                                                                        <FormControlLabel style={answerStyle} value={item.answers} control={<Radio
                                                                            checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                                ? true : false}
                                                                            disabled={true} />} label={item.answers} />
                                                                    </div>
                                                                </div>}
                                                        </AccordionDetails>))}
                                                </RadioGroup> : null}
                                        </Accordion>
                                    </Grid>))}
                            </Grid> : <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", minHeight: '100vh' }}>
                                <Spin indicator={antIcon} />
                            </Grid>}

                    </Grid>
                    {testTitle && questions.length > 0 && answers.length > 0 ?
                        <Grid item xs={12} sm={4} lg={3} style={{ marginTop: '3rem' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Button type="primary" style={{ width: '100%' }} onClick={Home}>Corsi</Button>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={12}>
                                    <ReactToPrint
                                        trigger={() => <Button type="primary" style={{ width: '100%' }}>scarica test</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper className={maxHeightPaper} style={{ textAlign: 'center' }}>

                                        <Grid item >
                                            <div style={{ backgroundColor: 'lawngreen' }}>
                                                <Typography variant="h6" >
                                                    Tempo
                                                </Typography>
                                            </div>
                                            <Typography variant="h6" style={{ padding: '8px 0 8px 0px' }}>
                                                {minutes < 10 ? 0 : null}{minutes}:00 Minuti
                                            </Typography>
                                        </Grid>
                                        <Divider variant='fullWidth' />
                                        <Grid item style={{ padding: '8px 0 8px 0px' }} >
                                            <Typography variant="h6" >
                                                Domande:  {questions.length}
                                            </Typography>


                                            <Typography variant="h6" >
                                                Punteggio totale : {answerScore}/{testScore}
                                            </Typography>
                                        </Grid>

                                    </Paper>
                                </Grid>
                                {answerScore.length > 0 && passedTest ?
                                    <Grid item lg={12}>
                                        <Paper >
                                            <Result style={{ padding: '27px', wordBreak: 'break-word' }}
                                                status='success'
                                                icon={<SmileOutlined />}
                                                title={`${passedTest}`}
                                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                                extra={section_id === '0' ? <Button type="primary" loading={sending} onClick={() => setShowModal(true)}>Richiedi certificato</Button> : null}
                                            />
                                        </Paper>
                                    </Grid> : null}


                            </Grid>
                        </Grid> : null}

                </Grid>

            </Container>
        </div>
    );

}

export default withRouter(TestResult);
