import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "../components/MainTheme.js";
import Context from '../Context'
import { Button, Alert } from 'antd';
import { useForm } from "react-hook-form";
import InputAdornment from '@material-ui/core/InputAdornment';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import alteredu from '../alteredu.png'
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Login() {

        const history = useHistory();

        const classes = useStyles();
        const [error, seterror] = useState(false);

        const { setAuth } = useContext(Context);
        const { setUser } = useContext(Context);

        const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm()


        const onSubmit = async data => {

                let items = { email: data.email, password: data.password };
                let result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
                        method: "POST",
                        body: JSON.stringify(items),
                        credentials: 'include',
                        headers: {
                                "Content-Type": "application/json",
                        },
                });

                result = await result.json();

                if (result.msg === "success") {
                        let user_info = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user`).then(response => response.json())
                        if (user_info) {
                                setUser(user_info)
                                setAuth(true)
                        }
                        if (user_info.user_type === 'ADM' && user_info.email_verified === 1) {
                                history.push("/admin/corsi");
                        }
                        else if (user_info.user_type === 'ADM_LMT' && user_info.email_verified === 1) {
                                history.push("/admin-spc/corsi");
                        }
                        else if (user_info.user_type === 'USR' && user_info.email_verified === 1) {
                                history.push("/home");

                        }
                        else if (user_info.email_verified === 0 && user_info.user_type === 'USR') {
                                history.push({
                                        pathname: '/dettagli-account',
                                });

                        }
                } else {
                        seterror(true)
                }

        }

        function loginGuest() {
                history.push("/corsi");
        }

        return (
                <Container component="main" maxWidth="xs">
                        <Grid container spacing={8} justify='center'>
                                <CssBaseline />
                                <Grid item xs={12} sm={12} lg={12} >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                                <img alt='img' src={alteredu} style={{ height: '100px', marginBottom: '1rem' }} />

                                                <Typography component="h1" variant="h5">
                                                        Entra
                                                </Typography>
                                        </div>


                                        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

                                                {error ?
                                                        <Alert message="email o password non corretti, riprova" type="error" showIcon /> : null}
                                                <TextField
                                                        {...register('email', {
                                                                required: true,
                                                                pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: "email non corretti"
                                                                }
                                                        })}
                                                        required
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="email"
                                                        label="Indirizzo email"
                                                        name="email"
                                                        autoComplete="email"
                                                        autoFocus
                                                        type='email'
                                                        InputProps={{
                                                                startAdornment: (
                                                                        <InputAdornment position="start">
                                                                                <UserOutlined fontSize="small" />
                                                                        </InputAdornment>
                                                                ),


                                                        }}
                                                        error={(errors?.email?.type === "required" && true) || (errors?.email?.message)}
                                                        helperText={(errors?.email?.type === "required" && 'Il campo è obbligatorio') || (errors?.email?.message && "invalid email address")}
                                                />
                                                <TextField
                                                        {...register('password', { required: true })}
                                                        required
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        id="password"
                                                        InputProps={{
                                                                startAdornment: (
                                                                        <InputAdornment position="start">
                                                                                <LockOutlined fontSize="small" />
                                                                        </InputAdornment>
                                                                ),


                                                        }}
                                                        autoComplete="current-password"
                                                        error={errors?.password?.type === "required" && true}
                                                        helperText={errors?.password?.type === "required" && 'Il campo è obbligatorio'}
                                                />
                                                {/* <FormControlLabel
                                                        control={<Checkbox value="remember" color="primary" />}
                                                        label="Remember me"
                                                /> */}
                                                <Button
                                                        htmlType="submit"
                                                        type="primary"
                                                        block={true}
                                                        size='middle'
                                                        className={classes.submit}
                                                        loading={isSubmitting}
                                                >
                                                        Entra
                                                </Button>
                                                <Grid container>
                                                        <Grid item xs>
                                                                <Link href="/password-dimenticata" variant="body2">
                                                                        Password dimenticata?
                                                                </Link>
                                                        </Grid>

                                                </Grid>

                                        </form>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={8} >
                                        <Button
                                                onClick={loginGuest}
                                                type="primary"
                                                block={true}
                                                size='middle'
                                                className={classes.submit}
                                                disabled={isSubmitting}
                                        >
                                                Entra come Ospite
                                        </Button>
                                </Grid>
                        </Grid>
                </Container >

        );
}