import React, { Component } from 'react';
import axios from 'axios';
import Pusher from 'pusher-js';
import ChatList from './Components/Chat/ChatList';
import InputChat from './Components/Inputs/InputChat';
import Ch from './Components/Chat/ch';
import ChatBox from './Components/Chat/ChatBox';
import Context from '../Context'

class Chat extends Component {

  static contextType = Context

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      username: '',
      chats: []
    };
  }

  componentDidMount() {

    const { user } = this.context;
    const username = user;

    //  window.prompt('Username: ', 'Anonymous');
    this.setState({ username });
    const pusher = new Pusher('3d53cb4717e2b71b60fc', {
      cluster: 'eu',
      encrypted: true
    });
    const channel = pusher.subscribe('chat');
    channel.bind('message', data => {
      this.setState({ chats: [...this.state.chats, data], test: '' });
    });
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(e) {

    //if (e.keyCode === 13) {
    const payload = {
      username: this.state.username,
      message: e.target.value
    };
    axios.post('http://localhost:5000/message', payload);
    //} 
    // else {
    //   this.setState({ text: e.target.value });
    // }
  }

  render() {
    const { user } = this.context;
    return (
      <div className="App">
        <section>
          {/* <ChatList chats={this.state.chats} user={user} /> */}
          <Ch chats={this.state.chats} user={user} />
          <InputChat
            text={this.state.text}
            username={this.state.username}
            handleTextChange={this.handleTextChange}
          />
          {/* <ChatBox
            text={this.state.text}
            username={this.state.username}
            handleTextChange={this.handleTextChange}
          /> */}
        </section>
      </div>
    );
  }
}

export default Chat;
