import { withRouter, useHistory, useParams } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import { useStyles } from "../components/MainTheme.js";
import { Button, message, Collapse } from "antd";
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';
import CountDown from "../components/CountDown/CountDown";
import Comments from './Comments'

function ReadModule(props) {

    const { user } = useContext(Context)
    const classes = useStyles();

    const { section_id } = useParams()
    const { module_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()
    const history = useHistory();

    const [downloadcheck, setDownload] = useState('');
    const [url, setUrl] = useState(null)

    const [courseType, setCourseType] = useState(null);
    const current_page = JSON.parse(localStorage.getItem(`${user.id}_module_${module_id}`));

    const [minutes, setMinutes] = useState(0)
    const [moduleTime, setModuleTime] = useState(0)
    const [percent, setPercent] = useState(0);
    const [answerScore, setScoreAnswers] = useState([])


    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);

    const [modules, setModules] = useState(null);

    const [check, setCheck] = useState(false)
    const [moduleStatus, setModuleStatus] = useState([])

    const [testScore, setTestScore] = useState('')
    const [deadline, setDeadline] = useState(null)
    const [time, setTime] = useState(null)
    const [countQuestions, setQuestion] = useState(null);


    localStorage.setItem(`${user.id}_url`, JSON.stringify(props.location.pathname));


    useEffect(async () => {

        if (time) {
            const timer = Date.now() + time * 1000;
            setDeadline(timer);

            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)
            formData.append('time', time)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserStatus`, {
                method: "POST",
                body: formData
            })

        }
    }, [time])

    const error = () => {
        message.error({
            content: 'Devi finire di visualizzare la risorsa prima di procedure al modulo successivo',
            style: {
                marginTop: '5vh',
            },
        });
    };

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage,
                    NumberOfPages, Zoom, ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >

                        <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                            <GoToPreviousPage />
                        </div>
                        <div style={{ padding: '0px 2px', maxWidth: '4%' }}>
                            <CurrentPageInput />
                            {/* / <NumberOfPages /> */}
                        </div>
                        <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                            <GoToNextPage />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Zoom />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomOut />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>


                        {downloadcheck && downloadcheck === 1 ?
                            <div style={{ padding: '0px 2px' }}>
                                <Download />
                            </div> : null}

                    </div>
                )
            }
            }
        </Toolbar>
    );


    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [],
        renderToolbar


    });
    async function updateStatusTime() {



        const formData = new FormData();
        formData.append('course_id', course_id)
        formData.append('module_id', module_id)
        var time_left = (deadline - Date.now()) / 1000;

        if ((time_left < 0) || (time_left === 1)) time_left = 0;
        formData.append('time', time_left)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatusTime`, {
            method: "POST",
            body: formData
        })

    }




    useEffect(() => {
        getData()

    }, [])

    useEffect(() => {

        if (user.user_type === 'USR' && url)
            setUserStatus()
        async function getModuleTimeLeft() {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTimeLeft/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => setTime(time)
                )

            // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTime/${course_id}/${module_id}`).then(
            //     response => response.json()).then(
            //         time => {
            //             if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
            //             else setModuleTime(time/60)


            //         }
            //     )
        }
        if (courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && user && url) {

            getModuleTimeLeft()
            getAllowedResources()

        }
    }, [courseType, url])


    async function setUserStatus() {

        if (courseType && courseType.type === 'Normal') {
            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserStatus`, {
                method: "POST",
                body: formData
            })
        }

    }

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setUrl(`${process.env.REACT_APP_API_BASE_URL}/download/${path.module_path}/${course_id}`)
                }
            )
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkDownload/${course_id}/${module_id}`).then(
            response => response.json()).then(
                download => {
                    if (download.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setDownload(download)
                }
            )
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json)
                }

            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScore/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setScoreAnswers(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    if (json > 0) {
                        getScore()
                    }
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)

                }
            )


        getAllowedResources()

    }

    async function getScore() {



        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setTestScore(json)
                }
            )

    }

    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);

    // async function getModuleTime(time) {
    //     if (time.length === 0) {
    //         await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTime/${course_id}/${module_id}`).then(
    //             response => response.json()).then(
    //                 time => {
    //                     if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
    //                     else setMinutes(time/60)


    //                 }
    //             ).then(() => setCheck(true))
    //     }
    // }

    async function updateStatus() {
        //finished reading
        if (user) {
            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatus`, {
                method: "POST",
                body: formData
            }).then(() => getAllowedResources())

        }

    }

    function handlPageChange(event) {
        localStorage.setItem(`${user.id}_module_${module_id}`, JSON.stringify({ page: event.currentPage }))
        //courseType && courseType.type === 'Normal' && 
        if (user && event.currentPage === event.doc['_pdfInfo'].numPages - 1 && user.user_type === 'USR') {
            updateStatus()

        }
    }



    async function getAllowedResources() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )
    }

    useEffect(() => {

        if (moduleStatus && modules && moduleStatus.length > 0 && modules.length > 0) {
            let moduleStatusLength = moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length

            let per = ((moduleStatusLength) * 100) / modules.length
            setPercent(Math.floor(per))
        }
    }, [moduleStatus]);

    function nextModule() {


        if ((courseType && courseType.type === 'Normal' && moduleStatus.filter(function (val) {
            return (val.module_id === parseInt(module_id) && val.finished_reading === 1);
        }).length > 0) ||
            (courseType && courseType.type === 'Mandatory' && moduleStatus.filter(function (val) {
                return (val.module_id === parseInt(module_id) && val.finished_reading === 1 && val.time === 0);
            }).length > 0)) {

            var index;
            var id;
            var type;
            var module_name;
            index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
            modules && modules.map((item, i) => {
                if (i === index) {
                    id = item.id
                    type = item.type
                    module_name = item.module_name
                }
                return true;
            })


            if (type === 'pdf') {
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
                window.location.reload(false);
            }



            if (type === 'video' || type === 'video_to_server')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
            if (type === 'ppt')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
        }
        else {
            error()
        }

    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })


        if (type === 'pdf') {
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            window.location.reload(false);
        }
        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
        if (type === 'ppt')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }


    function passeTest() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}`,
        })
    }

    function nextSection() {
        history.push({
            pathname: `/home/${course_name}/${course_id}`,
        })
    }


    function testResult() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/test-risultati`,

        })
    }

    const handleCompleted = (childData) => {
        updateStatusTime()
        getAllowedResources()
    }


    const handleUpdateTime = () => {

        updateStatusTime()
    }
    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Breadcrumb />
                    {courseType && courseType.type === 'Mandatory' && minutes >= '0' ?
                        <Grid item xs={4} sm={3} lg={3} style={{ textAlign: 'center' }}>
                            <Typography variant='h6' style={{ paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'gold' }}>
                                {deadline ?
                                    <CountDown pause={false} updateTime={handleUpdateTime} completed={handleCompleted} timer={deadline} />
                                    : <CountDown timer={1000} />}
                            </Typography>
                        </Grid> : null}
                </Grid>
                {url ?
                    <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                            : <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}><LeftOutlined />Precedente</Button>}
                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                            : <Button type="primary" onClick={nextModule}><RightOutlined />Prossimo modulo</Button>}
                        {countQuestions > 0 && answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                            <Button type="primary" onClick={passeTest} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Avvia test</Button> :
                            answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                        {countQuestions === 0 ?
                            <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                    </Grid> : null}

                {url ? <Grid item xs={12} lg={10}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '480px',
                        }}>

                            <Viewer
                                fileUrl={url}
                                defaultScale={SpecialZoomLevel.ActualSize}
                                plugins={[
                                    defaultLayoutPluginInstance

                                ]}
                                onPageChange={handlPageChange}
                                initialPage={current_page ? current_page.page : 0}
                            />
                        </div>
                    </Worker>
                </Grid> : null}
                {url ?
                    <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                            : <Button type="primary" onClick={prevModule} style={{ marginRight: '20px' }}><LeftOutlined />Precedente</Button>}
                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                            : <Button type="primary" onClick={nextModule}><RightOutlined />Prossimo modulo</Button>}
                        {answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                            <Button type="primary" onClick={passeTest} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Avvia test</Button> :
                            answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                        {countQuestions === 0 ?
                            <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                    </Grid> : null}

                {url && description && (description[0] !== null) ?
                    <Grid item xs={12} lg={10} style={{ marginTop: '2rem' }}>
                        <Collapse accordion>
                            <Panel header="Informazioni aggiuntive" key="1">
                                <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                </Typography>
                            </Panel>

                        </Collapse>
                    </Grid>
                    : null}

                {/* {user.first_name == 'Liberale' || user.first_name == 'Mesd' || user.first_name == 'Giuseppe'? */}
                <Grid item xs={12} lg={7} style={{ marginTop: '2rem' }}>
                    <Comments />
                </Grid>


            </Grid>

        </Container>

    );
}

export default withRouter(ReadModule);
