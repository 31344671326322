import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Table, Button, Space, Input, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import UpdateCategory from "./UpdateCategory";

function AddCategory() {

    const classes = useStyles();

    const [category, setCategory] = useState(null);
    const [categoryID, setCategoryID] = useState(null);
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [updateCategory, setUpdateCategory] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [errorName, setErrorName] = useState(false);
    const [countCoursesInCategory, setCountCoursesInCategory] = useState(null);


    useEffect(() => {
        getData()
    }, [])



    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCategories`).then(
            response => response.json()).then(
                json => setCategories(json)
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/countCoursesInCategory`).then(
            response => response.json()).then(
                json => setCountCoursesInCategory(json)
            )


    }


    async function addCategory() {
        setLoading(true)

        if (category === '' || !category) {
            setErrorName(true)
            setLoading(false)
        } else {

            const formData = new FormData();

            formData.append('category', category)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/addCategory`, {
                method: "POST",
                body: formData
            }).then(
                response => response.json()).then(
                    json => {
                        if (json.result === 'success') {
                            getData()
                            handleClose()
                        }
                        else {
                            setLoading(false)
                        }
                    }

                )
        }
    }

    const handleClose = () => {
        setCategory(null)
        setErrorName(false)
        setLoading(false)
        setOpen(false)
    };


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
        },
        {
            title: 'Nome categoria',
            dataIndex: 'category',
            key: 'category',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.category.toLowerCase().includes(value.toLowerCase())
            },
            render: category => <>{category}</>,

        },
        {
            title: 'Conteggio',
            dataIndex: 'id',
            key: "id",
            render: (_, record) =>
                <>
                    {countCoursesInCategory && countCoursesInCategory.filter(item => item.category_id === record.id).map(item => (
                        <> {item.total} </>
                    ))}
                </>
        },
        {
            title: 'Elimina',
            dataIndex: 'id',
            render: (_, record) =>
                <Popconfirm title="Sei sicuro?" okText="Si" cancelText="No"
                    onConfirm={() => handleDelete(record.id)}>
                    <Button type="link" style={{ padding: "0px" }}>Elimina</Button>
                </Popconfirm>
        },
    ];

    async function handleDelete(id) {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteCategory/${id}`, {
            method: "DELETE"
        }).then(() => getData())
    }


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setCategoryID(selectedRows[0].id)
        }
    };
    const UpdCatCallback = (childData) => {
        setUpdateCategory(childData)
    }

    const handleCallbackCategory = (childData) => {
        setCategoryID(childData)
        getData()
    }
    return (

        <Container maxWidth="lg" >
            <Grid container spacing={3}>
                <Breadcrumb />

                {categoryID ? <UpdateCategory open={updateCategory} category_id_clear={handleCallbackCategory}
                    category_id={categoryID} close={UpdCatCallback} /> : null}

                <Dialog fullWidth open={open} onClose={handleClose}>
                    <DialogTitle>Aggiungi categoria</DialogTitle>

                    <DialogContent>
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="outlined-full-width"
                                label="Nome categoria"
                                placeholder="Nome categoria"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size='small'
                                variant="outlined"
                                required
                                onChange={(e) => setCategory(e.target.value)}
                                error={errorName}
                                helperText={errorName ? 'Il campo è obbligatorio' : null}
                            />
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={handleClose} >
                            Cancella
                        </Button>
                        <Button
                            loading={loading}
                            form="my-form-id"
                            type="primary"
                            className={classes.button}
                            onClick={addCategory} >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog>



                <Grid item xs={12} lg={12}>
                    <Paper >

                        <Grid item xs={12} lg={12} style={{ padding: '5px 15px 0 0', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                disabled={!categoryID}
                                type="primary"
                                className={classes.button}
                                onClick={() => setUpdateCategory(true)}>
                                Modifica categoria
                            </Button>

                            <Button type="primary" onClick={() => setOpen(true)}>Aggiungi categoria</Button>
                        </Grid>
                        <Table
                            size="small"
                            bordered
                            rowKey={record => record.id}
                            loading={loading} columns={columns} dataSource={categories}
                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            pagination={{
                                showSizeChanger: true,
                                current: page,
                                pageSize: pageSize,
                                onChange: (page, pageSize) => {
                                    setPage(page)
                                    setPageSize(pageSize)
                                }
                            }}


                        />
                    </Paper>
                </Grid>


            </Grid>

        </Container>

    );
}

export default AddCategory;
