import { withRouter, useHistory } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
import { Avatar } from 'antd';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { useForm } from "react-hook-form";
import { Button, Upload, Space, Form, DatePicker, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UserOutlined, PhoneOutlined, HomeOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function UserProfile() {

    const history = useHistory();
    const classes = useStyles();
    const [image, setImage] = useState(null);

    const [userData, setUserData] = useState([]);
    const [error, setError] = useState(false);
    const [birthDate, setBirthDate] = useState("");
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const moment = require('moment');
    const { user } = useContext(Context)
    var date = null;
    const [isPassword, setIsPassword] = useState("password");
    const [gotData, setGotData] = useState(false);

    const [file, setFile] = useState("");

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const { register, handleSubmit, setValue, formState: { errors } } = useForm()
    const [form] = Form.useForm();

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getWpUserMeta`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => setUserData(json
                )).then(() => setGotData(true))
    }


    useEffect(() => {

        async function getUserData() {

            setValue('first_name', userData.first_name[0].meta_value)
            setValue('last_name', userData.last_name[0].meta_value)
            if (userData.birthdate) {
                var darr = userData.birthdate[0].meta_value.split("/");    // ["25", "09", "2019"]
                var ISOFormat = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0]));
                date = ISOFormat.toISOString()
                setBirthDate(moment(date).format('YYYY-MM-DD'))
                setValue('birth_date', moment(date).format('YYYY-MM-DD'))
            }
            if (userData.billing_country)
                setValue('birth_country', userData.billing_country[0].meta_value)
            if (userData.birthcity)
                setValue('birth_city', userData.birthcity[0].meta_value)
            if (userData.billing_phone)
                setValue('phone', userData.billing_phone[0].meta_value)

            setReady(true)
        }
        if (userData && userData.first_name && gotData) {
            getUserData()
        }
        if (gotData && user) {
            setValue('first_name', user.first_name)
            setValue('last_name', user.last_name)
            setReady(true)
        }
    }, [gotData])

    const onSubmit = async data => {


        if (data.password && !data.confirme_password) {
            setError(true)
        }
        else {
            setLoading(true)

            const formData = new FormData();

            formData.append('name', data.first_name)
            formData.append('surname', data.last_name)
            if (data.birth_country)
                formData.append('birth_country', data.birth_country)
            if (data.birth_date)
                formData.append('birth_date', data.birth_date)
            if (data.birth_city)
                formData.append('birth_city', data.birth_city)
            formData.append('phone', data.phone)
            if (file !== '')
                formData.append('file', file)



            await fetch(`${process.env.REACT_APP_API_BASE_URL}/addData`, {
                method: "POST",
                body: formData
            })



            if (data.password && data.password.trim() !== "") {
                const formDataPassword = new FormData();

                formDataPassword.append('password', data.password)

                await fetch(`${process.env.REACT_APP_API_BASE_URL}/changePassword`, {
                    method: "POST",
                    body: formDataPassword
                })
            }


            if (user && user.user_type === 'USR')
                history.replace("/home")

            else if (user && user.user_type === 'ADM')
                history.replace("/admin/corsi");


            setLoading(false)
        }
    }

    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    }


    const Imagefile = {
        beforeUpload: file => {
            setFile(file)
            setImage(URL.createObjectURL(file))
            return false;
        }
    };

    function removedImage() {
        setImage(null)
        setFile(null)
    }


    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    function onChangeBirthDate(date) {
        if (date)
            setValue('birth_date', date.format('YYYY-MM-DD').toString())
    }

    return (
        <Container maxWidth="md" >
            <Grid container spacing={3} justify='center'>
                <Snackbar open={error} autoHideDuration={3000} onClose={handleError} >
                    <Alert onClose={handleError} severity="error">
                        Si è verificato un errore, riprova
                    </Alert>
                </Snackbar>
                <Paper >
                    {ready ?
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography style={{ padding: '10px 0 10px 35px' }} variant="h6" className={classes.title}>
                                    Dettagli account
                                </Typography>
                                <Divider variant='fullWidth' />
                            </Grid>

                            <Form style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '2rem' }} form={form} onFinish={handleSubmit(onSubmit)}>

                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12} lg={7}>
                                        <Space direction="vertical">
                                            <Input prefix={<UserOutlined />} defaultValue={userData && userData.first_name ? userData.first_name[0].meta_value : user.first_name} addonBefore="Nome:" placeholder="Nome" {...register('first_name', { required: true })} onChange={(e) => setValue('first_name', e.target.value)} />
                                            {errors.first_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                                            <Input prefix={<UserOutlined />} addonBefore="Cognome:" defaultValue={userData && userData.last_name ? userData.last_name[0].meta_value : user.last_name} placeholder="Cognome" {...register('last_name', { required: true })} onChange={(e) => setValue('last_name', e.target.value)} />
                                            {errors.last_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                                            <Input prefix={<HomeOutlined />} defaultValue={userData && userData.billing_country ? userData.billing_country[0].meta_value : null} addonBefore="Nazione:" placeholder="Nazione" {...register('birth_country', { required: true })} onChange={(e) => setValue('birth_country', e.target.value)} />
                                            {errors.birth_country && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                                            <Input prefix={<HomeOutlined />} defaultValue={userData && userData.birthcity ? userData.birthcity[0].meta_value : null} addonBefore="Luogo di Nascita:" placeholder="Luogo di Nascita" {...register('birth_city', { required: true })} onChange={(e) => setValue('birth_city', e.target.value)} />
                                            {errors.birth_city && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                                            <Input prefix={<PhoneOutlined />} defaultValue={userData && userData.billing_phone ? userData.billing_phone[0].meta_value : null} addonBefore="Telefono:" placeholder="Telefono" {...register('phone', { required: true })} onChange={(e) => setValue('phone', e.target.value)} />
                                            {errors.phone && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}

                                            <Form.Item label="Data di nascita" >
                                                <DatePicker defaultValue={userData && userData.birthdate ? moment(birthDate) : moment()} {...register('birth_date', { required: true })} onChange={onChangeBirthDate} />
                                                {errors.birth_date && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                                            </Form.Item>
                                        </Space>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={5}>
                                        <Grid item xs={12} lg={12} style={{
                                            textAlign: 'center', padding: '10px'
                                        }} >
                                            <Avatar src={image}
                                                style={{ lineHeight: '60px' }} size={100} icon={<UserOutlined style={{ fontSize: '55px', marginTop: '20px' }} />} />

                                            <div style={{ marginTop: '1rem' }}>
                                                <Upload
                                                    {...Imagefile}
                                                    listType="picture"
                                                    maxCount={1}
                                                    accept='image/*'
                                                    onRemove={removedImage} >
                                                    <Button icon={<UploadOutlined />} >Upload Immagine</Button>
                                                </Upload>
                                            </div>
                                        </Grid>


                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <Space direction="vertical">
                                            <Form.Item
                                                name="password"
                                                hasFeedback
                                            >
                                                <Input.Password  {...register('password')} placeholder="Password" onChange={(e) => setValue('password', e.target.value)} />
                                            </Form.Item>
                                            <Form.Item
                                                name="confirme_pass"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('La password non è corretta'));
                                                        },
                                                    }),
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password placeholder="Conferma password"
                                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={(e) => setValue('confirme_password', e.target.value)}
                                                />
                                            </Form.Item>
                                        </Space>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className={classes.button}
                                            loading={loading}
                                            style={{ width: '120px' }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Form>
                        </Grid> : null}

                </Paper>
            </Grid>
        </Container>
    );
}

export default withRouter(UserProfile);
