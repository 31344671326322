import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Skeleton, Space } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserOutlined } from '@ant-design/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@mui/material/Grid';

const Ch = ({ chats, user }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
        dataLength={chats.length}
        next={loadMoreData}
        hasMore={chats.length < 50}
        scrollableTarget="scrollableDiv"
      >
        {chats.map((chat, index) => {
          return (
            <ListItem key={index}>
              <Grid container>
                {user.first_name != chat.username.first_name ?
                  <Grid item xs={12} lg={12}>
                    <Space align="start">
                      <Avatar shape="square" size={46} icon={<UserOutlined />} />
                      <div style={{ maxWidth: '400px'}}>
                        <ListItemText align="left" style={{color: '#2196f3', fontSize: '14px', margin: '0px'}}>{chat.username.first_name}</ListItemText>
                        <ListItemText align="left" primary={chat.message} style={{ margin: '0px'}}></ListItemText>
                      </div>
                    </Space>

                  </Grid> :
                  <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'end' }}>
                    <ListItemText style={{ maxWidth: '55%', textAlign: 'end'}}>{chat.message}</ListItemText>
                  </Grid>}

              </Grid>
            </ListItem>
          )
        })
        }
      </InfiniteScroll>
    </div>
  );
};
export default Ch;