import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withRouter, useParams, useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import GuestBreadcrumb from "../components/Breadcrumb/GuestBreadcrumb";
import GuestVideo from "./GuestVideo";
//import Context from '../Context'
import { Button, Collapse } from "antd";
import Typography from '@material-ui/core/Typography';


function GuestVideoPage(props) {

    const { module_id } = useParams()
    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()

    const history = useHistory();
    const [modules, setModules] = useState(null);

    const [urlVideo, setVideoUrl] = useState(null);

    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);
    useEffect(() => {
        getData()

    }, [])



    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getGuestModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setVideoUrl(path.module_path)
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getGuestModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )


    }

    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);



    function nextModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })

        if (type === 'pdf')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

        if (type === 'video' || type === 'video_to_server') {
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`
            });
            window.location.reload(false);
        }
        if (type === 'ppt')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })

        if (type === 'pdf')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

        if (type === 'video' || type === 'video_to_server') {
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`
            });
            window.location.reload(false);
        }
        if (type === 'ppt')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }




    return (

        <Container maxWidth="lg">
            <Grid container spacing={1} >
                <Grid container justify='center' item xs={12} lg={12} >
                    <Grid item xs={12} lg={8}>
                        <GuestBreadcrumb />
                    </Grid>
                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginBottom: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                                : <Button size="small" type="primary" style={{ marginRight: '20px' }} onClick={prevModule}>Precedente</Button>}

                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}

                        </Grid> : null}
                    <Grid item xs={12} lg={8}>

                        <GuestVideo url={urlVideo} id={module_id} />

                    </Grid>
                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                                : <Button size="small" type="primary" style={{ marginRight: '20px' }} onClick={prevModule}>Precedente</Button>}
                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}

                        </Grid> : null}
                    {urlVideo && description && (description[0] !== null) ?
                        <Grid item xs={12} lg={8} style={{ marginTop: '2rem' }}>
                            <Collapse accordion>
                                <Panel header="Informazioni aggiuntive" key="1">
                                    <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                        {description}
                                    </Typography>
                                </Panel>

                            </Collapse>

                        </Grid>
                        : null}
                </Grid>
            </Grid>

        </Container >
    );
}

export default withRouter(GuestVideoPage);
