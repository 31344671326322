import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Context from '../Context'
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Result, Progress, Button, Modal } from 'antd';
import { SmileOutlined, ExclamationCircleOutlined, FrownOutlined } from '@ant-design/icons';
import ModuleList from "./Components/Lists/ModuleList";
import ModuleCard from "./Components/Cards/ModuleCard";



function UserSection() {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()

    const { user } = useContext(Context)
    const history = useHistory();
    const [modules, setModules] = useState([]);
    const [countQuestions, setQuestion] = useState(null);

    const [timesPasseTest, setTimesPasseTest] = useState(null);

    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [moduleStatus, setModuleStatus] = useState([]);
    const [minutes, setMinutes] = useState(null)

    const [percent, setPercent] = useState(0);

    const [passedTest, setPassedTest] = useState(null)
    const [didNotPassed, setDidNotPassed] = useState(null)
    const { confirm } = Modal;


    const [coursePicture, setCoursePicture] = useState(null);


    useEffect(() => {
        getData()
        if (user.user_type === 'USR')
            setUserSectionStatus()
    }, [])

    useEffect(() => {

        if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70)) {
            addUserTestFaild()
            checkTimesPasseTest()
        }

        if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70))
            deleteUserTestFaild()

    }, [testScore, answerScore])

    async function checkTimesPasseTest() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkTimesPasseTest/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setTimesPasseTest(json)
                    }
                }
            )
    }

    async function deleteUserTestFaild() {

        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteUserTestFaild`, {
            method: "DELETE",
            body: formData
        })
    }


    async function addUserTestFaild() {

        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/addUserTestFaild`, {
            method: "POST",
            body: formData
        })
    }

    async function setUserSectionStatus() {

        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserSectionStatus`, {
            method: "POST",
            body: formData
        })


    }

    useEffect(() => {


        async function updateSectionStatus() {
            if ((moduleStatus.length > 0)) {
                if (moduleStatus.filter(function (val) {
                    return (val.finished_reading === 1);
                }).length === modules.length) {

                    const formData = new FormData();
                    formData.append('section_id', section_id)
                    formData.append('course_id', course_id)

                    await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateSectionStatus`, {
                        method: "POST",
                        body: formData
                    })


                }
            }
        }

        if (countQuestions && countQuestions > 0) {
            if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) && passedTest) {
                updateSectionStatus()

            }
        } else if (countQuestions === 0) {
            updateSectionStatus()
        }

        calculPercent()

    }, [moduleStatus, countQuestions, passedTest])

    function calculPercent() {

        let moduleStatusLength = moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length

        let per = ((moduleStatusLength) * 100) / modules.length

        setPercent(Math.floor(per))

    }
    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTitlePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCoursePicture(json.picture_path)
                    }
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)
                    if (json > 0) {
                        getScore()
                    }
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleUser/${course_id}/${section_id}`)
            .then((response) => response.json())
            .then(json => {
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else {
                    setPassedTest(json.if_pass_test)

                    setDidNotPassed(json.if_didnt_pass_test)
                }
            }
            )

    }


    useEffect(() => {

        async function getUserTestTime() {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTime/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )
        }
        if (percent === 100) {
            getUserTestTime()

        }

    }, [percent])




    async function getScore() {

        if (user) {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScore/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setScoreAnswers(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setTestScore(json)
                    }
                )
        }
    }



    function passeTest() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/test`,

        })
    }


    function testResult() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/test-risultati`,

        })
    }

    function info() {
        Modal.info({
            title: 'Importante!',
            content: (
                <div>
                    <p>Il test sarà presto disponibile</p>
                </div>
            ),
            onOk() { },
        });
    }

    function showConfirm() {

        if (countQuestions && countQuestions > 0 && minutes) {
            confirm({
                title: 'Importante!',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <div>
                        <p>Il tempo a disposizione è di: {minutes} minuti</p>
                        <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                        <p>Puoi selezionare una o più risposte che repute esatte</p>
                    </div>
                ),
                cancelText: 'Cancella',
                onOk() {
                    passeTest()
                },
            });

        } else info()
    }

    function showConfirm2() {


        if (timesPasseTest < 3 && timesPasseTest !== null && minutes !== null) {
            confirm({
                title: 'Importante!',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <div>
                        <p>Questo è il: {timesPasseTest + 1} tentative che effettui per il test</p>
                        <p>Il tempo a disposizione è di: {minutes} minuti</p>
                        <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                        <p>Puoi selezionare una o più risposte che repute esatte</p>
                    </div>
                ),
                cancelText: 'Cancella',
                onOk() {
                    passeTest()
                },


            })
        } else if (timesPasseTest === 3) {
            Modal.warning({
                title: 'Importante!',
                content: (
                    <div>
                        <p>Hai sbagliato il test 3 volte</p>
                        <p>non hai più tentative a disposizione</p>
                        <p>contattaci per richiedere lo sblocco</p>
                    </div>
                ),
            })
        }
    }


    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='center'>
                <Grid item xs={12} sm={8} lg={8}>
                    <Breadcrumb />

                    <ModuleList modules={modules} course_id={course_id} section_id={section_id}
                        course_name={course_name} section_name={section_name}
                        coursePicture={coursePicture} moduleStatus={moduleStatus} />

                </Grid>
                <Grid container spacing={3} item xs={12} sm={3} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Paper style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant='h6'>
                                {section_name.replaceAll('-', " ")}
                            </Typography>

                            {answerScore.length === 0 && countQuestions && countQuestions > 0 && minutes ?
                                <Tooltip arrow={true}
                                    disableHoverListener={percent === 100 ? true : false} placement="top"
                                    title={percent === 100 ? "Avvia test" : "Devi visualizzare tutti I Contenuti prima di effettuare il test"}>
                                    <span>
                                        <Button
                                            onClick={() => showConfirm()}
                                            type='primary' style={{
                                                marginTop: '1rem',

                                            }}
                                            disabled={percent === 100 ? false : true}
                                        >
                                            Avvia test
                                        </Button>
                                    </span>
                                </Tooltip> : null}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <ModuleCard coursePicture={coursePicture} moduleStatus={moduleStatus} modules={modules} percent={percent} />
                    </Grid>
                    {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) && passedTest ?
                        <Grid item lg={12}>
                            <Paper >
                                <Result style={{ padding: '27px', wordBreak: 'break-all' }}
                                    status='success'
                                    icon={<SmileOutlined />}
                                    title={`${passedTest}`}
                                    subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                    extra={<Button type="primary" onClick={testResult}>Controlla I risultati</Button>}

                                />
                            </Paper>
                        </Grid> : null}
                    {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70) && didNotPassed ?
                        <Grid item lg={12}>
                            <Paper >
                                <Result style={{ padding: '27px', wordBreak: 'break-all' }}
                                    status='error'
                                    icon={<FrownOutlined />}
                                    title={`${didNotPassed}`}
                                    subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                    extra={<Button
                                        onClick={() => showConfirm2()}
                                        type='primary' style={{
                                            marginTop: '1rem',

                                        }} disabled={percent === 100 ? false : true} >
                                        Riprova test
                                    </Button>}

                                />
                            </Paper>
                        </Grid> : null}


                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(UserSection);
