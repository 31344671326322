import "../App.css";
import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import Context from '../Context'
import { withRouter, useParams, useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Video from "./Video";
import { Button, message, Collapse } from "antd";
import Typography from '@material-ui/core/Typography';
import CountDown from "../components/CountDown/CountDown";
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';
import Comments from './Comments'

function VideoPage() {

    const { module_id } = useParams()
    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()

    const { user } = useContext(Context)


    const history = useHistory();
    const [modules, setModules] = useState(null);
    const [finishedVideo, setFinishedVideo] = useState(false);
    const [testScore, setTestScore] = useState('')


    const [urlVideo, setVideoUrl] = useState(null);
    const [courseType, setCourseType] = useState(null);
    const [moduleStatus, setModuleStatus] = useState([])
    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);
    const [percent, setPercent] = useState(0);
    const [answerScore, setScoreAnswers] = useState([])
    const [time, setTime] = useState(null)
    const [videoPaused, setVideoPaused] = useState(true)

    const [deadline, setDeadline] = useState(null)
    const [countQuestions, setQuestion] = useState(null);


    useEffect(() => {
        getData()

    }, [])


    useEffect(async () => {

        if (time) {
            const timer = Date.now() + time * 1000;
            setDeadline(timer);

            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)
            formData.append('time', time)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserStatus`, {
                method: "POST",
                body: formData
            })
        }
    }, [time])

    useEffect(() => {
        if (finishedVideo) {
            onEndedVideo()
        }
    }, [finishedVideo])


    const handleCompleted = (childData) => {
        if (finishedVideo) {
            onEndedVideo()
        }
        getAllowedResources()
        updateStatusTime()
    }

    const handleUpdateTime = () => {

        updateStatusTime()
    }

    const error = () => {
        message.error({
            content: 'Devi finire di visualizzare la risorsa prima di procedure al modulo successivo',
            style: {
                marginTop: '5vh',
            },
        });
    };

    async function onEndedVideo() {

        if (courseType && courseType === 'Mandatory') {
            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatus`, {
                method: "POST",
                body: formData
            })

        }
    }

    async function updateStatusTime() {
        const formData = new FormData();
        formData.append('course_id', course_id)
        formData.append('module_id', module_id)
        var time_left = (deadline - Date.now()) / 1000;
        if ((time_left < 0) || (time_left === 1)) time_left = 0;
        formData.append('time', time_left)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateStatusTime`, {
            method: "POST",
            body: formData
        })
    }

    useEffect(() => {
        if (urlVideo)
            setUserStatus()
        async function getModuleTimeLeft() {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleTimeLeft/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        setTime(time)
                    }
                )
        }
        if (courseType && courseType === 'Mandatory' && user.user_type === 'USR' && user && urlVideo) {
            getModuleTimeLeft()
            getAllowedResources()

        }
    }, [courseType, urlVideo])


    async function setUserStatus() {

        if (user && courseType && courseType === 'Normal') {
            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/setUserStatus`, {
                method: "POST",
                body: formData
            })
        }

    }


    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setVideoUrl(path.module_path)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json.type)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestScore/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setScoreAnswers(json)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    if (json > 0) {
                        getScore()
                    }
                }
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)

                }
            )

        getAllowedResources()

    }


    async function getScore() {



        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setTestScore(json)
                }
            )

    }

    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);

    async function getAllowedResources() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )
    }

    function nextModule() {

        if (((courseType && courseType === 'Normal' && moduleStatus.filter(function (val) {
            return (val.module_id === parseInt(module_id) && val.finished_reading === 1);
        }).length > 0) || finishedVideo)
            || ((courseType && courseType === 'Mandatory' && moduleStatus.filter(function (val) {
                return (val.module_id === parseInt(module_id) && val.finished_reading === 1 && val.time === 0);
            }).length > 0)) || (finishedVideo && time === 0)) {
            var index;
            var id;
            var type;
            var module_name;
            index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
            modules && modules.map((item, i) => {
                if (i === index) {
                    id = item.id
                    type = item.type
                    module_name = item.module_name;
                }
                return true;
            })

            if (type === 'pdf')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });

            if (type === 'video' || type === 'video_to_server') {
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });
                window.location.reload(false);
            }
            if (type === 'ppt')
                history.push({
                    pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
                });

        }
        else error()
    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })


        if (type === 'pdf')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

        if (type === 'video' || type === 'video_to_server') {
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            window.location.reload(false);
        }
        if (type === 'ppt')
            history.push({
                pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }

    useEffect(() => {

        if (moduleStatus && modules && moduleStatus.length > 0 && modules.length > 0) {
            let moduleStatusLength = moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length

            let per = ((moduleStatusLength) * 100) / modules.length
            setPercent(Math.floor(per))
        }
    }, [moduleStatus]);

    const handleCallback = (childData) => {
        setFinishedVideo(childData)
    }

    const handleVideoPaused = (childData) => {
        setVideoPaused(childData)
    }

    function passeTest() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}`,
        })
    }

    function testResult() {
        history.push({
            pathname: `/home/${course_name}/${course_id}/${section_name}/${section_id}/test-risultati`,

        })
    }

    function nextSection() {
        history.push({
            pathname: `/home/${course_name}/${course_id}`,
        })
    }


    return (

        <Container maxWidth="lg">
            <Grid container spacing={1} >
                <Grid container justify='center' item xs={12} lg={12} >
                    <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Breadcrumb />
                        {courseType && courseType === 'Mandatory' ?
                            <Grid item xs={4} sm={3} lg={4} style={{ textAlign: 'center' }}>
                                <Typography variant='h6' style={{ paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'gold' }}>
                                    {deadline ?
                                        <CountDown updateTime={handleUpdateTime} completed={handleCompleted} pause={videoPaused} timer={deadline} />
                                        : <CountDown pause={videoPaused} timer={1000} />}
                                </Typography>
                            </Grid> : null}
                    </Grid>
                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginBottom: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                                : <Button type="primary" style={{ marginRight: '20px' }} onClick={prevModule}><LeftOutlined />Precedente</Button>}

                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button type="primary" onClick={nextModule}>Prossimo modulo <RightOutlined /></Button>}

                            {answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                                <Button type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                    onClick={passeTest} disabled={percent !== 100 ? finishedVideo ? false : true : false}>Avvia test</Button> :
                                answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                            {countQuestions === 0 ?
                                <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                    disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                        </Grid> : null}
                    <Grid item xs={12} lg={8}>
                        {urlVideo && urlVideo.includes('drive.google') ? <Video finishedVideo={handleCallback} videoPaused={handleVideoPaused} url={urlVideo} id={module_id} /> : null}
                        {urlVideo && urlVideo.includes('modules') ? <Video finishedVideo={handleCallback} videoPaused={handleVideoPaused} url={`${process.env.REACT_APP_API_BASE_URL}/download/${urlVideo}/${course_id}`} id={module_id} /> : null}
                    </Grid>

                    {urlVideo ?
                        <Grid item xs={12} lg={8} style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                                : <Button type="primary" style={{ marginRight: '20px' }} onClick={prevModule}><LeftOutlined />Precedente</Button>}
                            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                                : <Button type="primary" onClick={nextModule}>Prossimo modulo<RightOutlined /></Button>}

                            {answerScore.length === 0 && (percent === 100 || (modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length)) ?
                                <Button type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                    onClick={passeTest} disabled={percent !== 100 ? finishedVideo ? false : true : false}>Avvia test</Button> :
                                answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ? <Button onClick={testResult} type="primary" style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }} >Controlla I risultati</Button> : null}
                            {countQuestions === 0 ?
                                <Button type="primary" onClick={nextSection} style={{ marginLeft: '20px', backgroundColor: '#389e0d', borderColor: '#389e0d' }}
                                    disabled={percent !== 100 ? true : false}>Prossima unità</Button> : null}
                        </Grid> : null}
                    {urlVideo && description && (description[0] !== null) ?
                        <Grid item xs={12} lg={8} style={{ marginTop: '2rem' }}>
                            <Collapse accordion>
                                <Panel header="Informazioni aggiuntive" key="1">
                                    <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                    </Typography>
                                </Panel>

                            </Collapse>

                        </Grid>
                        : null}
                    {/* {user.first_name == 'Liberale' || user.first_name == 'Mesd' || user.first_name == 'Giuseppe'? */}
                    <Grid item xs={12} lg={7} style={{ marginTop: '2rem' }}>
                        <Comments />
                    </Grid>


                </Grid>
            </Grid>

        </Container >
    );
}

export default withRouter(VideoPage);
