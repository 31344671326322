import React, { useState, useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import BreadcrumbSpecfic from "../../components/Breadcrumb/BreadcrumbSpecfic";
import Grid from "@material-ui/core/Grid";
import { SearchOutlined } from '@ant-design/icons';
import { Table, Button, Space, Input } from 'antd';
import { Link } from '@mui/material';
import MuiAlert from '@material-ui/lab/Alert';

function UserSubscribedSpecificCourse() {

  const { user_id } = useParams()
  const { product_id } = useParams()
  const [courses, setCourses] = useState([]);
  const [studentInfo, setStudentInfo] = useState(null);
  const [noCourse, setNoCourses] = useState(false);
  
  const history = useHistory();


  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  function Alert(props) {
    return <MuiAlert elevation={2} variant="outlined" {...props} />;
  }
 
  useEffect(() => {
    getData()
  }, [])

  async function getData() { 

    setLoading(true)
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentSpesificCourseForAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          if (json.length > 0) {
            setCourses(json)
            setNoCourses(false)
          }
          else if (json.length === 0) {
            setCourses([])
            setNoCourses(true)
          }
        }
      )

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserProfileAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          setStudentInfo(json[0])
          setLoading(false)
        }
      )

  }


  function goToSections(record) {
    if (record.course && product_id) {
      history.push({
        pathname: `/admin-spc/corsi/${product_id}/studenti-iscritti/${user_id}/corsi/${record.course_id}/sezioni`
      })
    } else if (record.title && product_id) {
      history.push({
        pathname: `/admin-spc/corsi/${product_id}/studenti-iscritti/${user_id}/corsi/${record.id}/sezioni`,

      })
    } else if (record.course && !product_id)
      { 
        history.push({
      pathname: `/admin-spc/studenti-iscritti/${user_id}/corsi/${record.course_id}/sezioni`,

    }) } else if (record.title && !product_id) 
    {
      history.push({
        pathname: `/admin-spc/studenti-iscritti/${user_id}/corsi/${record.id}/sezioni`,
  
      })
    }
  }


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
    },

    {
      title: 'Nome del Corso',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        return <div style={{ padding: 8 }}>
          <Input autoFocus placeholder='Search...'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              // confirm({ closeDropdown: false })
            }}
            onBlur={() => {
              confirm()
            }}
            onPressEnter={() => {
              confirm()
            }}
            style={{ marginBottom: 8, display: 'block' }}
          ></Input>
          <Space>
            <Button
              type="primary"

              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>;
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.title) {
          return record.title.toLowerCase().includes(value.toLowerCase())
        } else if (record.course) {
          return record.course.toLowerCase().includes(value.toLowerCase())
        }

      },
      render: (title, record) => <Link onClick={() => goToSections(record)}>  {record.course ? record.course : record.title}</Link>,
    },

    {
      title: 'Tipologia',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Corsi sola visualizzazione', value: 'Normal' },
        { text: 'Formazione Obbligatoria', value: 'Mandatory' },
      ],
      onFilter: (value, record) => {
        return record.type === value
      },
      width: '20%',
      render: type => <>{type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (conTimer)'}</>,
    },
   
  ];

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} justify='flex-start'>
        <BreadcrumbSpecfic />
        <Grid container spacing={3} item xs={12} lg={9}>
          <Grid item xs={12} lg={12}>
            <Paper>
              <Table
                size="small"
                bordered
                rowKey={record => record.id}
                loading={loading} columns={columns} dataSource={courses}

                pagination={{
                  showSizeChanger: true,
                  current: page,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }
                }}
              />
            </Paper>

            {noCourse ? <Alert severity="info">Non sei registrato in nessun corso!</Alert> : null}
          </Grid>

        </Grid>
        {studentInfo ?
          <Grid item xs={12} lg={3} >
            <Grid item xs={12} lg={12}>
              <Paper style={{ textAlign: 'center', marginBottom: '2rem' }}  >
                <Grid item xs={12} style={{ background: 'lightgreen' }}>
                  <Typography variant='h6'> Nome Corsista </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '10px' }}>
                  <Typography>  {studentInfo.name} {studentInfo.surname}</Typography>
                </Grid>
              </Paper>
            </Grid>

          </Grid> : null}

      </Grid>
    </Container>

  );
}

export default withRouter(UserSubscribedSpecificCourse);