import React, {useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Space, Button, notification } from 'antd';
import { useHistory } from "react-router-dom"
import { useStyles } from "../../../components/MainTheme";
import Grid from '@material-ui/core/Grid';


export default function CardHome(props) {
   
    const history = useHistory();
    const classes = useStyles();
    const [courses, setCourses] = useState([]);
    const [disabled, setDisabled] = useState(false);



    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        if(props.courses)
        {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentcourse`).then( 
                response => response.json()).then(
                    json => {
                        setCourses(json)
                        if (json.length === 0)
                            props.noCourses(true)
                    })
                }else if(props.assignedCourses)
                {
                    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAssignedCourses`).then(
                        response => response.json()).then(
                            json => {
                                setCourses(json)
                                if (json.length === 0)
                                        {props.noAssignedCourses(true)}
                            })
                }
                else if(props.stopedCourses)
                {
                    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStopedCourse`).then(
                        response => response.json()).then(
                            json => {
                                setCourses(json)
                                setDisabled(true)
                                // if (json.length === 0)
                                //        { console.log(json)
                                //         props.noAssignedCourses(true)
                                //         props.noCourses(true)
                                //     }
                            })
                }
                   
                            
    }
    const openNotificationWithIcon = (type) => {
        notification[type]({
          message: 'Il corso che stai seguendo è stato sospeso',
          description:
            "Contattaci all'indirizzo assistenza@alteredu.it per maggiori dettagli.",
        });
      };

      notification.config({
  placement: 'topRight',
  top: 60,
});

    function goToCourse(item) {
        if(disabled)
        {
            openNotificationWithIcon('warning')

        } else
        history.push(
            {
                pathname: `home/${item.title.toLowerCase().replaceAll(' ', "-")}/${item.id}`,

            }
        )
    }

    return (
    <>
        {courses.map((item, index) => (
             <Grid item xs={12} sm={4} lg={3} key={index}>

                <Card key={index} className={classes.cardHeight} onClick={() => goToCourse(item)}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={`${process.env.REACT_APP_API_BASE_URL_IMAGE}/${item.picture_path.replace('\\', "/")}`}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Corso
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {item.title}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{ marginTop: 'auto' }}>
                        <Button block={true} size="small" type="default">
                        Partecipa al corso
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        ))}
        </>
    );
    
}

CardHome.propTypes = {
    children: PropTypes.node,
};
