import "../App.css";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { withRouter, useHistory, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { Popconfirm, Button, Menu, Dropdown } from "antd";
import {
  FilePdfOutlined,
  EditOutlined,
  DeleteOutlined,
  FilePptOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import AddTest from "../AdminPages/AddTest";
import ModalAddModule from "../components/Modal/ModalAddModule";
import ModalUpdateModule from "../components/Modal/ModalUpdateModule";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function SectionModule(props) {
  const { section_id } = useParams();
  const { course_id } = useParams();
  const { course_name } = useParams();
  const { section_name } = useParams();

  const moment = require("moment");

  const classes = useStyles();
  const history = useHistory();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [questionCount, setQuestionCount] = useState(null);
  const [testScore, setTestScore] = useState("");
  const [sectionName, setSectionName] = useState(null);

  const [modules, setModules] = useState([]);
  const [courseType, setCourseType] = useState(null);

  const [moduleId, setModuleId] = useState(null);
  const [disableDelete, setDisableDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showTest, setShowTest] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) getData();
    return () => {
      isMounted = false;
    };
  }, []);

  async function getData() {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getUserCourseType/${course_id}`
    )
      .then((response) => response.json())
      .then((json) => {
        setCourseType(json);
      });

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getSectionNameAdmin/${section_id}`
    )
      .then((response) => response.json())
      .then((json) => setSectionName(json));

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`
    )
      .then((response) => response.json())
      .then((json) => setModules(json));

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getTotalScoreQuestionAdmin/${course_id}/${section_id}`
    )
      .then((response) => response.json())
      .then((json) => setTestScore(json));

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/countQuestions/${course_id}/${section_id}`
    )
      .then((response) => response.json())
      .then((json) => setQuestionCount(json))
      .then(() => setShowTest(true));
  }

  async function deleteComments(item) {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/deleteComments/${item.id}`,
      {
        method: "DELETE",
      }
    ).then(() => setDeleted(true), setDisableDelete(false));

    const newComment = {
      id: "1",
    };

    axios.post(`${process.env.REACT_APP_NODE_API}/test`, newComment);
  }

  async function deleteModule(item) {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/deleteModule/${item.id}`,
      {
        method: "DELETE",
      }
    ).then(() => getData(), setDisableDelete(false));
  }

  function goToVideoPage(item) {
    history.push({
      pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${item.module_name
        .toLowerCase()
        .replaceAll(" ", "-")}/${item.id}`,
    });
  }

  function goToModule(item) {
    history.push({
      pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${item.module_name
        .toLowerCase()
        .replaceAll(" ", "-")}/${item.id}`,
    });
  }

  function goToModulePPT(item) {
    history.push({
      pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${item.module_name
        .toLowerCase()
        .replaceAll(" ", "-")}/${item.id}`,
    });
  }

  function changeColor(e) {
    e.target.style.color = "#1890ff";
  }
  function onMouseLeave(e) {
    e.target.style.color = "rgba(0, 0, 0, 0.87)";
  }

  function addTest() {
    history.push({
      pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/inserisci-domande`,
    });
  }
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setShowModal(true)}>
        Aggiungi modulo
      </Menu.Item>
      <Menu.Item key="2" onClick={() => addTest()}>
        Aggiungi test
      </Menu.Item>
    </Menu>
  );

  const handleCallbackModule = () => {
    setModuleId(null);
    getData();
  };

  const handleShowModal = (data) => {
    setShowModal(data);
    setShowUpdateModal(data);
    setKey(key + 1);
  };

  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleted(false);
  }

  return (
    <Container maxWidth="lg">
      <Breadcrumb />

      <Snackbar open={deleted} autoHideDuration={2000} onClose={handleError}>
        <Alert onClose={handleError} severity="success">
          Comments has been deleted
        </Alert>
      </Snackbar>

      <Grid item xs={12} sm={12} lg={12}>
        <Dropdown overlay={menu} placement="bottomLeft">
          <Button
            size="large"
            className={classes.button}
            type="primary"
            style={{ maxWidth: "160px", marginBottom: "2rem" }}
          >
            Aggiungi risorsa
          </Button>
        </Dropdown>
      </Grid>

      <ModalAddModule
        key={key}
        closeModal={handleShowModal}
        showModal={showModal}
        courseType={courseType}
        saved={getData}
        course_id={course_id}
        section_id={section_id}
        course_name={course_name}
        section_name={section_name}
      />
      <ModalUpdateModule
        closeModal={handleShowModal}
        module_id_clear={handleCallbackModule}
        showModal={showUpdateModal}
        courseType={courseType}
        saved={getData}
        module_id={moduleId}
      />

      <Grid item xs={12} sm={12} lg={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Modulo</TableCell>
                      <TableCell>Abilita lo scaricamento</TableCell>
                      <TableCell>Path</TableCell>
                      {courseType && courseType.type === "Mandatory" ? (
                        <TableCell>Tempo</TableCell>
                      ) : null}
                      <TableCell>Modifica</TableCell>
                      <TableCell>Elimina</TableCell>
                      <TableCell>Delete comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modules.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.type === "pdf" ? (
                            <div onClick={() => goToModule(item)}>
                              <FilePdfOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                          {item.type === "ppt" ? (
                            <div onClick={() => goToModulePPT(item)}>
                              <FilePptOutlined
                                style={{
                                  color: "#ff9800",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                          {item.type === "video" ? (
                            <div onClick={() => goToVideoPage(item)}>
                              <YoutubeOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                          {item.type === "video_to_server" && (
                            <div onClick={() => goToVideoPage(item)}>
                              <YoutubeOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          )}
                        </TableCell>
                        {item.download === 0 ? (
                          <TableCell>falso</TableCell>
                        ) : (
                          <TableCell>Vero</TableCell>
                        )}
                        {courseType && courseType.type === "Mandatory" ? (
                          <TableCell>
                            {moment.utc(item.time * 1000).format("HH:mm:ss")}
                          </TableCell>
                        ) : null}
                        <TableCell>{item.module_path}</TableCell>
                        <TableCell>
                          <Button
                            disabled={showUpdateModal}
                            onClick={() => {
                              setModuleId(item.id);
                              setShowUpdateModal(true);
                            }}
                            icon={<EditOutlined style={{ fontSize: "20px" }} />}
                          />
                        </TableCell>

                        <TableCell>
                          <Popconfirm
                            disabled={disableDelete}
                            onConfirm={() => {
                              setDisableDelete(true);
                              deleteModule(item);
                            }}
                            title="Sei sicuro?"
                            okText="Si"
                            cancelText="No"
                          >
                            <Button
                              disabled={disableDelete}
                              icon={
                                <DeleteOutlined style={{ fontSize: "18px" }} />
                              }
                            />
                          </Popconfirm>
                        </TableCell>

                        <TableCell>
                          <Popconfirm
                            disabled={disableDelete}
                            onConfirm={() => {
                              setDisableDelete(true);
                              deleteComments(item);
                            }}
                            title="Sei sicuro?"
                            okText="Si"
                            cancelText="No"
                          >
                            <Button
                              disabled={disableDelete}
                              icon={
                                <DeleteOutlined style={{ fontSize: "18px" }} />
                              }
                            />
                          </Popconfirm>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Grid item xs={12} lg={12}>
              <Paper style={{ padding: "16px", textAlign: "center" }}>
                <Typography variant="h6">{sectionName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Paper>
                <div style={{ textAlign: "center", background: "limegreen" }}>
                  <Typography variant="h5">Test</Typography>
                </div>
                {questionCount ? (
                  <div style={{ textAlign: "center", padding: "10px 30px" }}>
                    <Typography variant="h6">
                      Domande: {questionCount}
                    </Typography>
                    <Typography variant="h6">Punteggio: {testScore}</Typography>
                  </div>
                ) : (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center", padding: "10px 30px" }}
                  >
                    Nessun test correlato
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {showTest ? (
          <Grid item sx={12} sm={12} lg={12}>
            <AddTest
              course_id={course_id}
              section_id={section_id}
              course_name={course_name}
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default withRouter(SectionModule);
