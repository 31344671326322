import { useState, useContext } from "react";
import axios from 'axios';
import "./Styles/AddComment.scss";
import Context from '../../../Context'
import {useParams} from "react-router-dom"

const AddComment = ({ buttonValue, addComments, replyingTo, replyingId }) => {
 
  const replyingToUser = replyingTo ? `@${replyingTo}, ` : "";
  const [comment, setComment] = useState("");
  const { user } = useContext(Context)
  const moment = require('moment');

  const { module_id } = useParams()

  const clickHandler = () => {
    if (comment === "" || comment === " ") return;

    // const payload = {
    //   username: user.first_name,
    //   message: comment
    // };


    const newComment = {
      id: Math.floor(Math.random() * 100) + 5,
      content: replyingToUser + comment,
      createdAt: moment().valueOf(),
      score: 0,
      username: user.first_name,
      user_id: user.id,
      currentUser: true,
      replies: [],
      type: buttonValue,
      replying_id : replyingId,
      module_id: module_id
    };

  //   console.log('new comment',newComment )
      axios.post(`${process.env.REACT_APP_NODE_API}/message`, newComment);


    addComments(newComment);
    setComment("");
  };

  return (
    <div className="add-comment">
      <div className="profile-pic"></div>
      <textarea
        className="comment-input"
        placeholder="Lascia un commento"
        value={replyingToUser + comment}
        onChange={(e) => {
          setComment(
            e.target.value.replace(replyingTo ? `@${replyingTo}, ` : "", "")
          );
        }}
      />
      <div className="send-btn-container">
        <div className="profile-pic"></div>
        <button className="add-btn" onClick={clickHandler}>
          {buttonValue}
        </button>
      </div>
    </div>
  );
};

export default AddComment;
