import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withRouter, useHistory, useParams } from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Popconfirm } from 'antd';
import UpdateSection from './UpdateSection'
import { Button, Menu, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AddTest from "../AdminPages/AddTest"
import Typography from "@material-ui/core/Typography";
import ModalAddCourseTotalTime from '../components/Modal/ModalAddCourseTotalTime'


function AddModule(props) {

    const { course_id } = useParams()
    const { course_name } = useParams()

    const classes = useStyles();
    const [sections, setSections] = useState([]);

    const [section, setSection] = useState("");

    const [openSec, setOpenSec] = useState(false);

    const [disableDelete, setDisableDelete] = useState(false);
    const [questionCount, setQuestionCount] = useState(null);
    const [testScore, setTestScore] = useState(null);
    const [courseType, setCourseType] = useState(null);


    const [courseName, setCourseName] = useState(null);

    const history = useHistory();
    const moment = require('moment');

    const [openSecUpdate, setOpenSecUpdate] = useState(false);
    const [openCourseTotalTime, setOpenCourseTotalTime] = useState(false);
    const [updateCourseTotalTime, setUpdateCourseTotalTime] = useState(false);
    const [courseTotalTime, setCourseTotalTime] = useState(null);
    const [sectionId, setSectionId] = useState(null);

    const [showTest, setShowTest] = useState(false);

    const [error, setError] = useState(false);
    const [errorTime, setErrorTime] = useState(false);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        getData()

    }, [])


    async function addSection() {
        setDisableDelete(true)

        if (section === '') {
            setError(true)
            setDisableDelete(false)
        }

        else {
            const formData = new FormData();
            formData.append('section_name', section)
            formData.append('course_id', course_id)

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/addSection`, {
                method: "POST",
                body: formData
            }).then(() => {
                handleClose()
                getData()
            })

        }

    }

    async function deleteSection(id) {
        setDisableDelete(true)
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteSection/${id}`, {
            method: "DELETE"
        }).then(() => getData(), setDisableDelete(false))
    }

    async function getCourseTotalTime() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTotalTimeAdmin/${course_id}`)
            .then((response) => response.json())
            .then((json) => setCourseTotalTime(json));

    }


    //     useEffect(()=>{
    //         if(courseType && courseType.type === "Mandatory")
    //         {
    //             getCourseTotalTime()
    //         }
    //     },[courseType])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdminSections/${course_id}`).then(
            response => response.json()).then(
                json => setSections(json)
            );

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseName/${course_id}`)
            .then((response) => response.json())
            .then((json) => setCourseName(json.title));


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/countQuestionsFinal/${course_id}`)
            .then((response) => response.json())
            .then((json) => setQuestionCount(json))
            .then(() => setShowTest(true));

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalScoreQuestionFinalAdmin/${course_id}`)
            .then((response) => response.json())
            .then((json) => setTestScore(json));

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCourseType/${course_id}`)
            .then((response) => response.json())
            .then((json) => {
                setCourseType(json);
            });

        getCourseTotalTime()

    }

    const handleClose = () => {
        setDisableDelete(false)
        setSection('')
        if (error) {
            setError(false)

        }
        if (openSec)
            setOpenSec(false)

        if (openCourseTotalTime)
            setOpenCourseTotalTime(false)

        if (errorTime)
            setErrorTime(false)

        if (updateCourseTotalTime)
            setUpdateCourseTotalTime(false)

    };

    function addTest() {
        history.push({
            pathname: `/admin/corsi/${course_name}/${course_id}/0/inserisci-domande`,
        })
    }



    const menu = (
        <Menu>
            <Menu.Item key='1' onClick={() => setOpenSec(true)}>

                Aggiungi sezione
            </Menu.Item>
            <Menu.Item key='2' onClick={() => addTest()} >

                Aggiungi test finale
            </Menu.Item>

            {!courseTotalTime ?
                <Menu.Item key='3' onClick={() => setShowModal(true)} >
                    Durata corso
                </Menu.Item> : null}

            {courseTotalTime ?
                <Menu.Item key='3' onClick={() => setShowModal(true)} >
                    Modifica durata corso
                </Menu.Item> : null}
        </Menu>
    )

    const UpdSecCallback = (childData) => {
        setOpenSecUpdate(childData)
    }
    const handleCallbackSection = (childData) => {
        setSectionId(childData)
        getData()
    }

    function goToModule(item) {
        history.push({
            pathname: `/admin/corsi/${course_name}/${course_id}/${item.section_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
        })
    }


    const handleShowModal = (data) => {
        setShowModal(data);
    };
    return (
        <Container maxWidth="lg">
            <Breadcrumb />

            {sectionId ? <UpdateSection open={openSecUpdate} section_id_clear={handleCallbackSection}
                section_id={sectionId} close={UpdSecCallback} /> : null}


            <Grid item xs={12} sm={12} lg={12}>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button size='large' className={classes.button} type="primary" style={{ maxWidth: "160px", marginBottom: '2rem' }}>Aggiungi risorsa</Button>
                </Dropdown>
            </Grid>

            <ModalAddCourseTotalTime defaultValue={courseTotalTime} course_id={course_id} closeModal={handleShowModal} showModal={showModal} saved={getCourseTotalTime} />


            <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openSec} onClose={handleClose}>
                <DialogTitle>Crea sezione</DialogTitle>

                <DialogContent>
                    <Grid item xs={12} sm={12} lg={12}>
                        <TextField
                            fullWidth
                            id="outlined-full-width"
                            label="Nome sezione"
                            placeholder="Nome sezione"

                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            variant="outlined"
                            required
                            onChange={(e) => setSection(e.target.value)}
                            error={error}
                            helperText={error ? 'Il campo è obbligatorio' : null}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Cancella
                    </Button>
                    <Button
                        loading={disableDelete}
                        form="my-form-id"
                        type="primary"
                        className={classes.button}
                        onClick={addSection}  >
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={12} lg={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={9}>
                        <div>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell >Sezione</TableCell>
                                            <TableCell >Nome sezione</TableCell>
                                            <TableCell >Modifica nome</TableCell>
                                            <TableCell >Aggiungi modulo</TableCell>
                                            <TableCell >Elimina</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sections.map((item, index) => (
                                            <TableRow key={item.id} >
                                                <TableCell > {index + 1}
                                                </TableCell>

                                                <TableCell >
                                                    {item.section_name}

                                                </TableCell>
                                                <TableCell >

                                                    <Button icon={<EditOutlined style={{ fontSize: "20px" }} />}
                                                        disabled={openSecUpdate} onClick={() => {
                                                            setSectionId(item.id)
                                                            setOpenSecUpdate(true)
                                                        }} />
                                                </TableCell>

                                                <TableCell >
                                                    <Button icon={<PlusCircleOutlined style={{ fontSize: "20px" }} />}
                                                        onClick={() => goToModule(item)} />

                                                </TableCell>

                                                <TableCell>
                                                    <Popconfirm disabled={disableDelete} onConfirm={() => deleteSection(item.id)}
                                                        title="Are you sure？" okText="Yes" cancelText="No">
                                                        <Button disabled={disableDelete} icon={<DeleteOutlined style={{ fontSize: "18px" }} />} />

                                                    </Popconfirm>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid container spacing={3} item xs={12} sm={3} lg={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Grid item xs={12} lg={12}>
                                <Paper style={{ padding: "16px", textAlign: "center" }}>
                                    <Typography variant="h6">{courseName}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Paper>
                                    <div style={{ textAlign: "center", background: "limegreen" }}>
                                        <Typography variant="h5">Test</Typography>
                                    </div>
                                    {questionCount ? (
                                        <div style={{ textAlign: "center", padding: "10px 30px" }}>
                                            <Typography variant="h6">
                                                Domande: {questionCount}
                                            </Typography>
                                            <Typography variant="h6">Punteggio: {testScore}</Typography>
                                        </div>
                                    ) : (
                                        <Typography
                                            variant="h6"
                                            style={{ textAlign: "center", padding: "10px 30px" }}
                                        >
                                            Nessun test correlato
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                        {courseTotalTime && courseType ?
                            <Grid item xs={12} lg={12}>
                                <Grid item xs={12} lg={12}>
                                    <Paper style={{ padding: "6px", textAlign: "center" }}>
                                        <Typography variant="h6">Durata corso</Typography>
                                        <div style={{ textAlign: "center", padding: '2px', background: "limegreen" }}></div>
                                        <Typography variant="h6">
                                            {/* {moment.utc((courseTotalTime.hours*1000) + (courseTotalTime.minutes*1000)).format('HH:mm:ss')} */}
                                            {(((courseTotalTime.hours / 3600) > 9) ? (courseTotalTime.hours / 3600) : ("0" + (courseTotalTime.hours / 3600))) + ":" + (((courseTotalTime.minutes / 60) > 9) ? (courseTotalTime.minutes / 60) : ("0" + (courseTotalTime.minutes / 60))) + ":" + "00"}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid> : null}
                    </Grid>

                </Grid>
                {showTest ? <Grid item sx={12} sm={12} lg={12}>
                    <AddTest course_id={course_id} section_id={0} course_name={course_name} />
                </Grid> : null}
            </Grid >
        </Container >

    );
}

export default withRouter(AddModule);
