import React, { useState, useEffect, useContext } from "react";
import "./Components/Components/Styles/App.scss";
import Comment from "./Components/Components/Comment";
import AddComment from "./Components/Components/AddComment";
import Grid from '@mui/material/Grid';
import Pusher from 'pusher-js';
import Context from '../Context'
import { useParams } from "react-router-dom"
import axios from 'axios';

const Comments = () => {
  const [comments, updateComments] = useState([]);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const { user } = useContext(Context)
  const { module_id } = useParams()
  const { course_id } = useParams()

  const getData = async () => {
    // const res = await fetch("./data/data.json");
    // const data = await res.json();
    // updateComments(data.comments);   

    const pusher = new Pusher('3d53cb4717e2b71b60fc', {
      cluster: 'eu',
      encrypted: true
    });
    const channel = pusher.subscribe('chat');
    channel.bind('message', data => {
      updateComments(comments => [...comments, data]);
    });
  };

  useEffect(() => {

    getComments()


  }, [])

  async function getComments() {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_comments/${course_id}/${module_id}`).then(
      response => response.json()).then(
        comments => {
          updateComments(comments.result)
          localStorage.setItem("comments", JSON.stringify(comments.result));
        }
      )
  }



  useEffect(() => {


    if (localStorage.getItem("comments") !== null) {

      //  if(comments.length > 0){

      // updateComments(JSON.parse(localStorage.getItem("comments")))

      const pusher = new Pusher('3d53cb4717e2b71b60fc', {
        cluster: 'eu',
        encrypted: true
      });

      const channel = pusher.subscribe('chat');


      channel.bind('delete', data_to_delete => {
        let updatedComments = [...JSON.parse(localStorage.getItem("comments"))];

        let updatedReplies = [];
        if (data_to_delete.type === 'comment') {
          updatedComments = updatedComments.filter((data) => data.id !== data_to_delete.id);
        } else if (data_to_delete.type === 'rispondi') {
          updatedComments.forEach((comment) => {
            if (comment.id === data_to_delete.parentComment) {
              updatedReplies = comment.replies.filter((data) => data.id !== data_to_delete.id);
              comment.replies = updatedReplies;
            }
          });
        }

        updateComments(updatedComments);
      });

      channel.bind('message', data => {

        if (data.type === 'invia')
          updateComments(comments => [...comments, data]);
        else if (data.type === 'rispondi') {

          let updatedComments = [...JSON.parse(localStorage.getItem("comments"))];

          updatedComments.forEach((data_from_comment) => {
            if (data_from_comment.id == data.replying_id) {
              data_from_comment.replies = [data];
            }
          });
          updateComments(updatedComments);
        }
      });


      // add reply

      channel.bind('replies', data => {

        let updatedComments = [...JSON.parse(localStorage.getItem("comments"))];

        updatedComments.forEach((data_from_comment) => {
          if (data_from_comment.id == data.reply_id) {
            data_from_comment.replies = [...data.replies];
          }
        });
        updateComments(updatedComments);

      });

      channel.bind('test', data => {

        getComments()

      });


      // delete 

    } else getData()

    // localStorage.getItem("comments") !== null
    //   ? updateComments(JSON.parse(localStorage.getItem("comments")))
    //   : getData();
  }, []);

  useEffect(async () => {

    localStorage.setItem("comments", JSON.stringify(comments));



    const formData = new FormData();

    formData.append('comments', JSON.stringify(JSON.parse(localStorage.getItem("comments"))))

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/save_comments/${course_id}/${module_id}`, {
      method: "POST",
      body: formData
    }).then((response) => response.json())

    deleteModalState
      ? document.body.classList.add("overflow--hidden")
      : document.body.classList.remove("overflow--hidden");



  }, [comments, deleteModalState]);

  // update score
  let updateScore = (score, id, type) => {
    let updatedComments = [...comments];

    if (type === "comment") {
      updatedComments.forEach((data) => {
        if (data.id === id) {
          data.score = score;
        }
      });
    } else if (type === "rispondi") {
      updatedComments.forEach((comment) => {
        comment.replies.forEach((data) => {
          if (data.id === id) {
            data.score = score;
          }
        });
      });
    }
    updateComments(updatedComments);
  };

  // add comments
  let addComments = (newComment) => {
    // let updatedComments = [...comments, newComment];
    // updateComments(updatedComments);
  };

  // add replies
  let updateReplies = (replies, id) => {
    // console.log('updateReplies',replies)
    // console.log('updateReplies_iid',id)
    // let updatedComments = [...comments];
    // updatedComments.forEach((data) => {
    //   if (data.id === id) {
    //     data.replies = [...replies];
    //   }
    // });
    // updateComments(updatedComments);
  };

  // edit comment
  let editComment = (content, id, type) => {
    let updatedComments = [...comments];

    if (type === "comment") {
      updatedComments.forEach((data) => {
        if (data.id === id) {
          data.content = content;
        }
      });
    } else if (type === "rispondi") {
      updatedComments.forEach((comment) => {
        comment.replies.forEach((data) => {
          if (data.id === id) {
            data.content = content;
          }
        });
      });
    }

    updateComments(updatedComments);
  };

  // delete comment
  let commentDelete = (id, type, parentComment) => {

    // let updatedComments = [...comments];
    // let updatedReplies = [];

    // if (type === "comment") {
    //   updatedComments = updatedComments.filter((data) => data.id !== id);
    // } else if (type === "reply") {
    //   comments.forEach((comment) => {
    //     if (comment.id === parentComment) {
    //       updatedReplies = comment.replies.filter((data) => data.id !== id);
    //       comment.replies = updatedReplies;
    //     }
    //   });
    // }

    // updateComments(updatedComments);
  };

  return (

    <Grid item xs={12} sm={12} lg={12}>
      <h4 style={{ marginTop: '1rem' }}>Hai una domanda? Condividila qui in basso</h4>
      <AddComment buttonValue={"invia"} addComments={addComments} />
      {comments.filter(comment => comment.module_id == module_id).map((comment) => (
        <Comment
          key={comment.id}
          commentData={comment}
          updateScore={updateScore}
          updateReplies={updateReplies}
          editComment={editComment}
          commentDelete={commentDelete}
          setDeleteModalState={setDeleteModalState}
          currentUser={comment.user_id === user.id ? true : false}
        />
      ))}
    </Grid>

  );
};

export default Comments;
