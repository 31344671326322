import React from "react";
import { Input, Form, Button } from "antd";
import { useForm } from "react-hook-form";
import Paper from "@material-ui/core/Paper";
import Grid from "@mui/material/Grid";
import { SendOutlined } from "@ant-design/icons";

const InputChat = (props) => {

const [form] = Form.useForm();
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = async (e) => {
    props.handleTextChange(e.message);
    form.resetFields(['message']);
    setValue('message', '')
  };

  return (
    <Grid item xs={12} lg={12} component={Paper}>
      <Form form={form} onFinish={handleSubmit(onSubmit)}>
          <Input.Group compact>
          <Form.Item name="message" style={{ width: "calc(100% - 39px)" }}>
            <Input
              size="large"
              placeholder="message..."
              {...register("message", { required: true })}
              onChange={(e) => setValue("message", e)}
            />
             </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<SendOutlined />}
            ></Button>
          </Input.Group>
        
      </Form>
    </Grid>
  );
};

export default InputChat;
