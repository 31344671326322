import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import TableCategories from "../components/Tables/TableCategories.js";
import TableCourses from "../components/Tables/TableCourses.js";
import ListItems from "../components/Lists/List.js";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Table, Button, Popconfirm, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


export default function Register() {


  const classes = useStyles();
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const [studentNotEnrolled, setStudentNotEnrolled] = useState([]);
  const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [sending, setSending] = useState(false);

  const [coursesID, setCoursesID] = useState([]);
  const [coursesTitle, setCoursesTitle] = useState([]);
  const [categoriesTitle, setCategoriesTitle] = useState([]);
  const [categoriesID, setCategoriesID] = useState([]);


  const moment = require('moment');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, [])

  async function getData() {

    setLoading(true)
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserSentEmail`).then(
      response => response.json()).then(
        json => {
          setStudentNotEnrolled(json)
          setLoading(false)
        }

      )


  }

  const onSubmit = async data => {

    setSending(true)

    const formData = new FormData();

    formData.append('firstName', data.firstName)
    formData.append('lastName', data.lastName)
    formData.append('email', data.email)
    formData.append('courses', JSON.stringify(coursesTitle))
    formData.append('coursesID', JSON.stringify(coursesID))
    formData.append('categoriesID', JSON.stringify(categoriesID))

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
      method: "POST",
      body: formData
    }).then((response) => response.json()).then((rpns) => {
      if (rpns[0].msg === "success") {
        setSuccessSubmit(true)
        reset();
        setCoursesID([])
        setCoursesTitle([])
        setCategoriesTitle([])
        setCategoriesID([])
        getData()
        setSending(false)
        setSelectedRowKeys([])
      } else if (rpns[0].msg === "error") {
        setSending(false)
        setError(true)
      } else {
        setSending(false)
        setErrorNetwork(true)
      }
    })
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const style = {
    padding: '10px',
  }

  const handleCloseSubmit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSubmit(false);
  }
  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
    setErrorNetwork(false);
  }

  async function deleteAdmin(id) {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteAdmin/${id}`, {
      method: "DELETE"
    }).then(() => getData())
  }






  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        return <div style={{ padding: 8 }}>
          <Input autoFocus placeholder='Search...'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              // confirm({ closeDropdown: false })
            }}
            onBlur={() => {
              confirm()
            }}
            onPressEnter={() => {
              confirm()
            }}
            style={{ marginBottom: 8, display: 'block' }}
          ></Input>
          <Space>
            <Button
              type="primary"

              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>;
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase())
      },
      width: '250px'
    },

    {
      title: 'Data invio',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => <> {moment(created_at).format('DD/MM/YYYY, h:mm:ss a')}</>,
      sorter: (record1, record2) => {
        return record1.created_at > record2.created_at
      }
    },

    {
      title: 'Email verificata',
      key: 'email_verified',
      filters: [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 },
      ],
      onFilter: (value, record) => {
        return record.email_verified === value
      },
      render: (_, record) => <>  {record.email_verified === 1 ? 'Si' : 'No'}</>,
    },
    {
      title: 'Resend Email',
      key: 'resend_email',
      render: (_, record) =>
        <Popconfirm
          onConfirm={() => resendEmail(record.id)}
          title="Sei sicuro?" okText="Si" cancelText="No">
          <Button type="link" style={{ padding: "0px" }}>Resend email</Button>
        </Popconfirm>,
    },
    {
      title: 'Elimina',
      key: 'delete',
      render: (_, record) =>
        <Popconfirm
          onConfirm={() => deleteAdmin(record.id)}
          title="Sei sicuro?" okText="Si" cancelText="No">
          <Button type="link" style={{ padding: "0px" }}>Elimina</Button>
        </Popconfirm>,
    },

  ];

  async function resendEmail(id) {
    setSending(true)

    const formData = new FormData();

    formData.append('user_id', id)

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/resendEmail`, {
      method: "POST",
      body: formData
    }).then((response) => response.json()).then((rpns) => {
      if (rpns[0].msg === "success") {
        setSuccessSubmit(true)
        setSending(false)
      } else if (rpns[0].msg === "error") {
        setSending(false)
        setError(true)
      } else {
        setSending(false)
        setErrorNetwork(true)
      }
    })
  }

  const handleCategoriesID = (childData) => {
    setCategoriesID(childData)
  }
  const handleCategoriesTitle = (childData) => {
    setCategoriesTitle(childData)
  }

  const handleCoursesID = (childData) => {
    setCoursesID(childData)
  }
  const handleCoursesTitle = (childData) => {
    setCoursesTitle(childData)
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} justify='center'>

        <Snackbar open={successSubmit} autoHideDuration={4000} onClose={handleCloseSubmit} >
          <Alert onClose={handleCloseSubmit} severity="success">
            Email inviata correttamente!
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
            Hai già inviato una mail!
          </Alert>
        </Snackbar>
        <Snackbar open={errorNetwork} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
            Qualcosa è andato storto, riprova
          </Alert>
        </Snackbar>

        <Grid item xs={12} lg={4} >
          <Paper className={maxHeightPaper} style={style} >
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <Grid item xs={12} lg={12}>
                <TextField
                  {...register('firstName', {
                    required: true,
                  })}
                  fullWidth
                  placeholder="Nome"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="firstName"
                  label="Nome"
                  type="text"
                  name="firstName"
                  error={(errors?.firstName?.type === "required" && true)}
                  helperText={(errors?.firstName?.type === "required" && 'Questo campo è obbligatorio')}
                />
                <TextField
                  {...register('lastName', {
                    required: true,
                  })}
                  fullWidth
                  placeholder="Cognome"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="lastName"
                  label="Cognome"
                  name="lastName"
                  type="text"
                  error={(errors?.lastName?.type === "required" && true)}
                  helperText={(errors?.lastName?.type === "required" && 'Questo campo è obbligatorio')}
                />
                <Space direction="vertical" style={{ display: 'flex' }}>
                  <ListItems listTitle={'Corsi'} listItems={coursesTitle} />
                  <ListItems listTitle={'Categoria'} listItems={categoriesTitle} />
                </Space>

                <TextField
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "indirizzo email non valido"
                    }
                  })}
                  fullWidth
                  placeholder="email"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="email"
                  label="E-Mail"
                  name="email"
                  type='email'
                  autoComplete="email"
                  error={(errors?.email?.type === "required" && true) || errors?.email?.message}
                  helperText={(errors?.email?.type === "required" && 'Questo campo è obbligatorio') || (errors?.email?.message && "invalid email address")}
                />

              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <Button
                  htmlType="submit"
                  type='primary'
                  className={classes.submit}
                  loading={sending} >
                  Aggiungi Corsista
                </Button>
              </Grid>
            </form>

          </Paper>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Paper className={maxHeightPaper}>

            <TableCourses coursesID={handleCoursesID} coursesTitle={handleCoursesTitle}
              loading={loading}
              selectedRowKeys={selectedRowKeys} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper className={maxHeightPaper}>

            <TableCategories categoriesID={handleCategoriesID} categoriesTitle={handleCategoriesTitle}
              loading={loading}
              selectedRowKeys={selectedRowKeys} />

          </Paper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper className={maxHeightPaper}>
            <Table
              size="small"
              bordered
              rowKey={record => record.id}
              loading={loading} columns={columns} dataSource={studentNotEnrolled}
              pagination={{
                showSizeChanger: true,
                current: page,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setPage(page)
                  setPageSize(pageSize)
                }
              }}
            />
          </Paper>
        </Grid>

      </Grid>
    </Container >

  );
}
