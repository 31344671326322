import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import { useStyles } from "../../components/MainTheme.js";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Result } from 'antd';
import { FilePdfOutlined, SmileOutlined, YoutubeOutlined, FilePptOutlined, FrownOutlined } from '@ant-design/icons';

function StudentSubscribedModule(props) {

    const { course_id } = useParams()
    const { section_id } = useParams()
    const { product_id } = useParams()
    const { user_id } = useParams()

    const classes = useStyles();
    const history = useHistory();

    const [modules, setModules] = useState([]);
    const [moduleStatus, setModuleStatus] = useState([]);

    const [answerScore, setScoreAnswers] = useState('')
    const [testScore, setTestScore] = useState('')
    const [courseType, setCourseType] = useState(null);
    const [countQuestion, setCountQuestion] = useState(null);
    const [timesPasseTest, setTimesPasseTest] = useState(null);


    useEffect(() => {
        getData()
    }, [])

    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }

    async function getData() {


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`).then(
            response => response.json()).then(
                json => setModules(json)
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserModuleStatus/${section_id}/${user_id}`).then(
            response => response.json()).then(
                json => setModuleStatus(json)
            )


        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestScore/${course_id}/${section_id}/${user_id}`).then(
            response =>
                response.json()
        ).then(
            json => setScoreAnswers(json)
        )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestionAdmin/${course_id}/${section_id}/${user_id}`).then(
            response => response.json()).then(
                json => setTestScore(json)
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCourseType/${course_id}`).then(
            response => response.json()).then(
                json => setCourseType(json)

            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCountQuestionAdmin/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {

                    setCountQuestion(json)
                }
            )
    }

    useEffect(() => {

        if (answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70)) {
            checkTimesPasseTest()
        }


    }, [testScore, answerScore])

    async function checkTimesPasseTest() {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkTimesPasseTestAdmin/${course_id}/${section_id}/${user_id}`).then(
            response => response.json()).then(
                json => {

                    setTimesPasseTest(json)
                }
            )
    }



    function testResult() {
        history.push({
            pathname: `/admin/corsi/${product_id}/studenti-iscritti/${user_id}/corsi/${course_id}/sezioni/${section_id}/moduli/test-risultati`,
        })
    }

    const moment = require('moment');
    return (

        <Container maxWidth="lg">
            <Grid container spacing={3} >
                <Breadcrumb />
                <Grid item xs={12} lg={10}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>

                                    <TableCell >#</TableCell>
                                    <TableCell >Modulo</TableCell>
                                    <TableCell >Apri file</TableCell>
                                    <TableCell >Termina lettura</TableCell>
                                    {courseType && courseType.type === 'Mandatory' ?
                                        <TableCell >Tempo</TableCell>
                                        : null}
                                    {courseType && courseType.type === 'Mandatory' ?
                                        <TableCell >Tempo rimasto</TableCell>
                                        : null}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {modules.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell >
                                            {index + 1}
                                        </TableCell>
                                        <TableCell >
                                            {item.type === 'pdf' ?
                                                <FilePdfOutlined style={{ color: 'red', fontSize: '20px', marginRight: '4px', display: 'content !important' }} />
                                                : null}
                                            {item.type === 'ppt' ?
                                                <FilePptOutlined style={{ color: "#ff9800", fontSize: "20px", marginRight: '4px', display: "content !important" }} />
                                                : null}
                                            {item.type === 'video' || item.type === 'video_to_server' ?
                                                <YoutubeOutlined style={{ color: "red", fontSize: "20px", marginRight: '4px', display: "content !important", }} />
                                                : null}
                                            {item.module_name}
                                        </TableCell>


                                        <TableCell>
                                            {moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                item.created_at).toString() ?
                                                moment(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                    item.created_at).toString()).format('DD/MM/YYYY, h:mm:ss a')
                                                : <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label="Non aperto"
                                                    color="secondary"
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                item.finished_reading).toString() === '1' ?

                                                moment(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                    item.updated_at).toString()).format('DD/MM/YYYY, h:mm:ss a')
                                                :
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label="Non terminato"
                                                    color="secondary"
                                                />}
                                        </TableCell>

                                        {courseType && courseType.type === 'Mandatory' ?
                                            <TableCell>
                                                {item.time / 60 > 10 ? item.time / 60 : `0${item.time / 60}`}:00
                                            </TableCell> : null}
                                        {courseType && courseType.type === 'Mandatory' ?
                                            <TableCell>

                                                {moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                    item.time).toString() ?
                                                    `${Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                        item.time).toString() / 60) > 10 ? Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                            item.time).toString() / 60) : `0${Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                                item.time).toString() / 60)}`}:${Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                                    item.time).toString() % 60) > 10 ? Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                                        item.time).toString() % 60) : `0${Math.floor(moduleStatus.filter(id => id.module_id === item.id).map((item) =>
                                                                            item.time).toString() % 60)}`}` : `${item.time / 60 > 10 ? item.time / 60 : `0${item.time / 60}`}:00`

                                                }

                                            </TableCell> : null}

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ?
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper>
                            <Result style={{ padding: '27px', wordBreak: 'break-all' }}
                                status='success'
                                icon={<SmileOutlined />}
                                title={`La prova è stata superata!`}
                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                extra={<Button type="primary" onClick={testResult} >Controlla I risultati</Button>}

                            />
                        </Paper>
                    </Grid>
                    : null}

                {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70) ?
                    <Grid item xs={12} sm={6} lg={3}>

                        <Paper >
                            <Result style={{ padding: '27px', wordBreak: 'break-all' }}
                                status='error'
                                icon={<FrownOutlined />}
                                title={timesPasseTest && timesPasseTest > 1 ? `Non hai superato il test per ${timesPasseTest} volte`
                                    : `Non hai superato il test`}
                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                extra={<Button type="primary" onClick={testResult} >Controlla i risultati</Button>}

                            />
                        </Paper>
                    </Grid> : null}
                {countQuestion && countQuestion > 0 && answerScore.length === 0 ?
                    <Grid item xs={12} lg={3} style={{ marginTop: '1.5rem' }}>
                        <Alert severity="info">Test finale non ancora superato</Alert>
                    </Grid> : null}
            </Grid>

        </Container >


    );
}

export default withRouter(StudentSubscribedModule);
