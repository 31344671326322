
import Grid from '@material-ui/core/Grid';
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from 'antd';
import Container from '@material-ui/core/Container';
import Context from '../Context'

export default function NotAuthorizedPage() {

    const history = useHistory();

    const { user } = useContext(Context);

    function backHome() {

        if (user && user.user_type === "ADM")
            history.push({
                pathname: '/admin/corsi'
            })
        else if (user && user.user_type === "USR")
            history.push({
                pathname: '/home'
            })
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='center'>

                <Result
                    status="403"
                    title="403"
                    subTitle="Non sei autorizzato ad accedere a questa pagina"
                    extra={<Button type="primary" onClick={() => backHome()}>Torna alla home dei corsi</Button>}
                />
            </Grid>
        </Container>

    );
}