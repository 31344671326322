import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';


function ModulePage() {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()

    const classes = useStyles();
    const history = useHistory();
    const [modules, setModules] = useState([]);
    const [coursePicture, setCoursePicture] = useState(null);


    useEffect(() => {
        getData()

    }, [])



    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCoursePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else if (json.picture_path) {
                        setCoursePicture(json.picture_path.replace('\\', "/"))
                    }
                }
            )

        // await fetch(`${process.env.REACT_APP_API_BASE_URL}/getSectionNameAdmin/${section_id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setSectionName(json)
        //         }
        //     )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

    }



    function goToModule(item) {

        if (item.type === 'pdf')
            history.push({
                pathname: `/admin/dashbord/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
        else if (item.type === 'ppt')
            history.push({
                pathname: `/admin/dashbord/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
        else if (item.type === 'video' || item.type === 'video_to_server')
            history.push({
                pathname: `/admin/dashbord/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
    }

    return (
        <Container maxWidth="lg">

            <Grid container spacing={3} justify='center'>

                <Grid item xs={12} sm={8} lg={8}>
                    <Breadcrumb />
                    <Paper>
                        <Grid item style={{ display: 'flex', padding: '20px 30px', marginTop: '1rem', justifyContent: 'space-between' }}>
                            <Typography variant='h5' style={{
                                color: '#252525',
                                fontWeight: '600',
                            }}>Moduli</Typography>
                            <span style={{
                                fontSize: '22px'
                            }}>{modules.length}</span>
                        </Grid>
                        <List component="nav" aria-label="main mailbox folders">
                            {modules.map((item, index) => (
                                <ListItem key={index} button onClick={() => goToModule(item)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <div className={classes.videoImage}>
                                        <img alt='' src={coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${coursePicture}` : null}
                                            style={{
                                                display: 'block',
                                                width: '125px',
                                                marginRight: '20px',
                                                height: '80px'
                                            }}></img>
                                    </div>
                                    <Grid item xs={12} className={classes.modulesName}>
                                        <Typography variant='h6' style={{
                                            marginTop: '0px',
                                            marginBottom: '.25rem',
                                            fontWeight: '500',
                                            color: '#000000',
                                        }} >
                                            {item.module_name}
                                        </Typography>
                                    </Grid>
                                </ListItem>

                            ))}

                        </List>
                    </Paper>
                </Grid>
                <Grid container spacing={3} item xs={12} sm={3} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Paper style={{ padding: '16px', textAlign: 'center' }}>
                            <Typography variant='h6'>
                                {section_name.replaceAll('-', " ")}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(ModulePage);
