import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function TableCourses(props) {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [courses, setCourses] = useState([]);



  useEffect(() => {
    getData()
    setSelectedRowKeys(props.selectedRowKeys)

  }, [props.selectedRowKeys])

  async function getData() {

    if (props.dataSourse) {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCoursesToAssign/${props.dataSourse}`).then(
        response => response.json()).then(
          json => setCourses(json)
        )
    }

    else {

      await fetch(`${process.env.REACT_APP_API_BASE_URL}/courses`).then(
        response => response.json()).then(
          json => {

            setCourses(json)
          }
        )

    }


  }

  const CourseColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,

    },
    {
      title: 'Nome del Corso',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        return <div style={{ padding: 8 }}>
          <Input autoFocus placeholder='Search...'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              // confirm({ closeDropdown: false })
            }}
            onBlur={() => {
              confirm()
            }}
            onPressEnter={() => {
              confirm()
            }}
            style={{ marginBottom: 8, display: 'block' }}
          ></Input>
          <Space>
            <Button
              type="primary"

              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>;
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase())
      },
      width: '100%'
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      props.coursesID(selectedRows.map((item) => { return item.id }))
      props.coursesTitle(selectedRows.map((item) => { return item.title }))
      setSelectedRowKeys(selectedRowKeys)
    }
  };


  return (
    <Table
      size="small"
      bordered
      rowKey={record => record.id}
      loading={props.loading} columns={CourseColumns} dataSource={courses}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      pagination={{
        showSizeChanger: true,
        current: page,
        pageSize: pageSize,
        onChange: (page, pageSize) => {
          setPage(page)
          setPageSize(pageSize)
        }
      }}
    />
  );
}

TableCourses.propTypes = {
  children: PropTypes.node,
};
