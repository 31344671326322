import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from 'antd';

export default function RequestCertificate(props) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [certificateType, setCertificateType] = useState(null);
    const [customUrl, setCustomUrl] = useState(null);
    const [requestSent, setRequestSent] = useState(false);
    const [errorNetwork, setErrorNetwork] = useState(false);
    const [numberOfCertificate, setNumberOfCertificate] = useState(null);

    useEffect(() => {
        setIsModalVisible(props.showModal)
        getData()
    }, [props])

    const handleCancel = () => {
        props.closeModal(false)
    };

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificateType/${props.course_id}`)
            .then((response) => response.json())
            .then(json => {
                if(json.length > 0)
                { setCertificateType(json[0].certificate_type)
                 setCustomUrl(json[0].custom_url)}
            }
            )

            getNumberOfCertificate()
    }

    async function RequestCertificat() {    
        setRequestSent(true)
          const formData = new FormData();

          formData.append('certificate_type', certificateType)
          formData.append('custom_url', customUrl)
          formData.append('course_id', props.course_id)

          await fetch(`${process.env.REACT_APP_API_BASE_URL}/requestCertificat`, {
              method: "POST",
              body: formData
          }).then((response) => response.json()).then((rpns) => {
              if (rpns[0].msg === "success") {
                  saveDownlaodCertificate()
                  setRequestSent(false)
              } else if (rpns[0].msg === "network_error") {
                  setErrorNetwork(true)
                  setRequestSent(false)
              } else {
                  setErrorNetwork(true)
                  setRequestSent(false)
              }
          })
           
          handleCancel() 
    }


    
  async function getNumberOfCertificate() {

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getNumberOfCertificate/${props.course_id}`)
    .then((response) => response.json())
    .then(json => setNumberOfCertificate(json));
  }


    async function saveDownlaodCertificate()
    {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/saveDownlaodCertificate/${props.course_id}`).then(
            response => response.json())
    }

    return (
        <Modal title="Gentile utente" visible={isModalVisible} onCancel={handleCancel}
            footer={[

                <Button loading={requestSent} disabled={numberOfCertificate && numberOfCertificate > 0 ? true : false} key="submit" type="primary" onClick={RequestCertificat}>
                    Okay
                </Button>
                
            ]}
                >

            {certificateType == 'certificato di partecipazione' ?
                <p>
                    Grazie per aver completato il corso da te scelto. Per ricevere il certificato di partecipazione da inserire nel tuo CV, occorre procedere all’acquisto del medesimo tramite questo link:<br></br> <br></br> <a href="https://www.alteredu.it/corsi/certificato-di-partecipazione">https://www.alteredu.it/corsi/certificato-di-partecipazione</a><br></br> <br></br>

                    Il costo del certificato di partecipazione è irrisorio, e comporta solamente i costi vivi nonché le spese uniche di rilascio.<br></br> <br></br>

                    Se hai già acquistato il certificato scrivici a certificazioni@alteredu.it indicandoci il numero d’ordine per il quale si richiede il certificato. Ti verrà generato entro 24h lavorative. <br></br> <br></br>

                    Grazie

                </p> : null}

            {certificateType === 'certificazione delle competenze' ?
                <p>
                    Grazie per aver completato il corso da te scelto. Ti ricordiamo che è possibile richiedere la certificazione delle competenze sul corso, valevole a livello internazionale, attestante che, con la fruizione del corso, ti sei dimostrato in possesso delle conoscenze, abilità e attributi personali richiesti. La Certificazione assicura, altresì, il rilascio di crediti formativi professionali (CFP) non formali<br></br>  <br></br> 

                    Per richiederla non devi fare altro che procedere all’acquisto tramite questo link: <br></br> <br></br> <a href="https://www.alteredu.it/corsi/certificazione-delle-competenze"> https://www.alteredu.it/corsi/certificazione-delle-competenze </a> <br></br>  <br></br> 

                    La Certificazione verrà rilasciata al superamento di un  test finale online, che dovrà essere concluso entro 7 giorni dall’invio delle medesime. L’esame si compone di 30 domande a risposta multipla e si può ripetere 2 volte. Sia il primo che l’eventuale secondo tentativo dovranno essere conclusi in 45 minuti. Il test finale si intende superato se si risponde correttamente ad almeno l’80% delle domande (24 domande su 30)<br></br>  <br></br> 

                    Se hai già acquistato la certificazione delle competenze e devi sostenere solo il test finale scrivici a certificazioni@alteredu.it indicandoci il numero d’ordine di acquisto della  certificazione.<br></br>  <br></br> 

                    Entro 24h riceverai la guida tecnica su come sostenere l’esame nonchè le credenziali di accesso alla piattaforma d’esame<br></br>  <br></br> 

                    Saluti dal team di Alteredu


                </p> : null}

            {certificateType === 'acquista corso completo' ?
                <p>
                    Grazie per aver completato il corso gratuito da te scelto. Ti ricordiamo che se vorrai potrai richiedere di acquistare il completo tramite questo link:
                    <br></br>
                    <br></br>
                    <a href="{customUrl}">{customUrl} </a> 
                    <br></br>
                    <br></br>
                    Così facendo, sarai in grado di padroneggiare al pieno gli argomenti da te studiati ed ottenere anche il certificato che attesta le competenze che hai acquisito
                    <br></br>
                    <br></br>
                    buono studio da Alteredu.
                </p> : null}

                {certificateType === 'corsi normati' ?
                <p>
               Complimenti per aver terminato il corso e <stong>superato il test finale!</stong>
               <br></br>  <br></br>
                Il tuo impegno, la tenacia e la costanza hanno dato i suoi frutti. Hai appena inserito un altro pezzo del puzzle della tua formazione.
                <br></br>  <br></br>
                Adesso sei ad un passo dalla fine: <stong>basta davvero poco per ottenere il Certificato finale.</stong>
                <br></br>  <br></br>
                <strong>Se non l’hai già acquistato</strong>, per <strong>ottenere l’Attestato</strong> dovrai effettuare il <strong>pagamento di € 30,33+IVA (totale € 37)</strong> - solo come contributo tasse e spese di produzione - tramite PayPal, Carta di credito o Bonifico bancario accedendo alla pagina: 
                <br></br>  <br></br>
                <a href="https://www.alteredu.it/corsi/certificato-corso-riconosciuto/">https://www.alteredu.it/corsi/certificato-corso-riconosciuto/</a>
                <br></br>  <br></br>
                <strong>Nota</strong>: è necessario inviare la <strong>ricevuta del pagamento</strong> prima di procedere con il rilascio.
                <br></br>  <br></br>
                <strong>Se invece hai già acquistato</strong> il certificato ti basta seguire la procedura a questo link:
                <br></br>  <br></br>
                <a href="https://www.alteredu.it/richiesta-attestato-corsi-sicurezza-dlgs-81-08/">https://www.alteredu.it/richiesta-attestato-corsi-sicurezza-dlgs-81-08/</a>
                <br></br>  <br></br>
                Per qualunque problema tu possa avere, non esitare a contattarci all’indirizzo <strong>assistenza@alteredu.it</strong>
                <br></br>  <br></br>
                <strong>Grazie per averci scelto.</strong> <br></br>
                Un abbraccio dal team Alteredu.


            </p>: null}

        </Modal>
    );

}

RequestCertificate.propTypes = {
    children: PropTypes.node,
};
