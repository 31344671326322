import CommentBtn from "./CommentBtn";

const CommentHeader = ({commentData, setReplying, setDeleting, setDeleteModalState, setEditing, time, currentUser}) => {
  return (
    <div className="comment--header">
      <div className={`profile-pic juliusomo`}></div>
      <div className="username">{commentData.username}</div>
      {currentUser ? <div className="you-tag">tu</div> : ""}
      <div className="comment-posted-time">{`${time} fa`}</div>
      <CommentBtn
        commentData={commentData}
        setReplying={setReplying}
        setDeleting={setDeleting}
        setDeleteModalState={setDeleteModalState}
        setEditing={setEditing}
        currentUser={currentUser}
      />
    </div>
  );
};

export default CommentHeader;
