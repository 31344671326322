import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withRouter, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Button, Tree } from "antd";


function CourseDetails() {

    const { course_id } = useParams()

    const classes = useStyles();

    const [course, setCourse] = useState(null);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);


    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    useEffect(async () => {

        if (categories.length > 0)
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCategoriesCourse/${course_id}`).then(
                response => response.json()).then(
                    json => setCheckedCategories(json)
                )


    }, [categories])



    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourse/${course_id}`).then(
            response => response.json()).then(
                json => setCourse(json)
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCategories`).then(
            response => response.json()).then(
                json => setCategories(json)
            )

    }



    async function addCategoriesToCourse() {
        setLoading(true)


        const formData = new FormData();

        formData.append('category_id', JSON.stringify(checkedCategories))
        formData.append('course_id', course_id)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/addCategoriesToCourse`, {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                    if (json.result === 'success') {
                        getData()
                        setLoading(false)
                        setCheckedKeys([])
                    }
                    else {
                        setLoading(false)
                    }
                }

            )

    }
    const onCheck = (checkedKeysValue) => {
        setCheckedCategories(checkedKeysValue);
        setCheckedKeys(checkedKeysValue)
    };
    return (
        <Container maxWidth="lg" >
            <Grid container spacing={3}>
                <Breadcrumb />

                <Grid item xs={12} sm={12} lg={9}>


                    <Grid item xs={12} sm={12} lg={12}>
                        <InputLabel htmlFor="age-native-helper">Descrizione del corso</InputLabel>
                    </Grid>
                    {course && course.description ? <Grid item xs={12} sm={12} lg={12}>
                        <Paper style={{ padding: '16px', backgroundColor: '#bdbdbd' }}>
                            <div dangerouslySetInnerHTML={{ __html: course.description }} >

                            </div>
                        </Paper>
                    </Grid> : null}
                </Grid>
                {course ?
                    <Grid container spacing={3} item xs={12} sm={12} lg={3} style={{ marginTop: '0.5rem' }}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Card className={classes.cardHeight} style={{ marginTop: '1rem', height: 'fit-content' }}>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={`${process.env.REACT_APP_API_BASE_URL_IMAGE}/${course.picture_path.replace('\\', "/")}`}

                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Corso
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {course.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>

                            </Card>
                        </Grid>

                        {categories.length > 0 ?
                            <Grid item xs={12} sm={12} lg={12}>
                                <Paper>
                                    <Tree checkable onCheck={onCheck} checkedKeys={checkedCategories}>
                                        {categories.map((item, index) =>
                                            <Tree.TreeNode key={item.id} title={item.category} />)
                                        }
                                    </Tree>
                                </Paper>
                            </Grid> : null}

                        {categories.length > 0 ?
                            <Grid item xs={12} sm={12} lg={12}>
                                <Button type="primary" disabled={checkedKeys.length === 0} loading={loading} onClick={() => addCategoriesToCourse()}>Update</Button>
                            </Grid> : null}

                    </Grid> : null}




            </Grid>

        </Container>


    );
}

export default withRouter(CourseDetails);
