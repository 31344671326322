import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useHistory, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../../components/MainTheme.js";
import { Table, Button, Space, Input } from 'antd';
import {SearchOutlined } from '@ant-design/icons';
import BreadcrumbSpecfic from "../../components/Breadcrumb/BreadcrumbSpecfic";


export default function AllSubscribedStudents() {
  
    const classes = useStyles();

    const [studentEnrolled, setStudentEnrolled] = useState([]);
    const [course_enrolled_btn, setCourseEnrolledBtn] = useState(true);
    const [student, setStudent] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const history = useHistory();

    const moment = require('moment');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        setLoading(true)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllSpecificUserSubscribed`).then(
            response => response.json()).then(
                json => {
                    setStudentEnrolled(json)
                    setLoading(false)}
            )

    }
    function getCourseEnrolled(courseEnrolled) {
        setStudent(courseEnrolled)
        setCourseEnrolledBtn(false)

    }

    function goToCourseEnrolled() {
        history.push({
            pathname: `/admin-spc/studenti-iscritti/${student.id}/corsi`
        })
    }
    
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
             render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
        },
        {
            title: 'Nome',
            dataIndex: 'first_name',
            key: 'first_name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.first_name.toLowerCase().includes(value.toLowerCase())
            },
            render: first_name => <a>{first_name}</a>,
            width:'250px'
        },

        {
            title: 'Cognome',
            dataIndex: 'last_name',
            key: 'last_name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.last_name.toLowerCase().includes(value.toLowerCase())
            }, width:'250px'
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.email.toLowerCase().includes(value.toLowerCase())
            },
            width:'250px'
        },
             {
            title: 'Inscritto a',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => <> {moment(created_at).format('DD/MM/YYYY, h:mm:ss a')}</>,
            sorter: (record1, record2) =>
            {
                return record1.created_at > record2.created_at
            }
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            getCourseEnrolled(selectedRows[0])
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} >
            <BreadcrumbSpecfic />
                    <Grid item xs={12} lg={12}>
                        <Paper >

                            <Grid item xs={12} lg={12} style={{ padding: '5px 15px 0 0', display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    disabled={course_enrolled_btn}
                                    type="primary"
                                    className={classes.button}
                                    onClick={goToCourseEnrolled}>
                                   Elenco corsi disponibili
                                </Button>
                                {course_enrolled_btn ? null :
                                    <Typography variant="h5" className={classes.title}>
                                        {student.name} {student.surname}
                                    </Typography>} 
                            </Grid>
                                  <Table 
                        size="small"
                        bordered
                        rowKey={record => record.id}
                        loading={loading} columns={columns} dataSource={studentEnrolled}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                          }}
                        pagination={{
                            showSizeChanger: true,
                            current: page,
                            pageSize: pageSize,
                            onChange: (page, pageSize)=>
                            {
                                setPage(page)
                                setPageSize(pageSize)
                            } 
                        }}
                       
                        
                        />
                        </Paper>
                    </Grid> 
            </Grid>
        </Container >

    );
}
