import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import BreadcrumbSpecfic from "../../components/Breadcrumb/BreadcrumbSpecfic";
import Grid from '@material-ui/core/Grid';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from '@mui/material';
import { Table,  Space, Input, Button } from 'antd';


function SpecificCourses() {
    const history = useHistory();
    const moment = require('moment');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countCourses, setCountCourses] = useState([]);


    useEffect(() => {
        getData()
    }, [])



    async function getData() {
        setLoading(true)
       
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getSpecificCourses`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(  
            response => response.json()).then(
                json => { 
                    setData(json)
                }
            )
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/countUserSubscribedInSpecificCourse`, {
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(  
                    response => response.json()).then(
                        json => { 
                            setCountCourses(json)
                            setLoading(false)
                        }
                    )

               

    }

    function goToUsersSubscribed(item) {
        history.push({
            pathname: `/admin-spc/corsi/${item.product_id}/studenti-iscritti`
        })
    }

   

    const columns = [
        {
            title: 'Corso',
            dataIndex: 'id',
            key: 'id',
             render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
        },
        {
            title: 'Nome del Corso',
            dataIndex: 'title',
            key: 'title',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                return <div style={{ padding: 8 }}>
                    <Input autoFocus placeholder='Search...'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            // confirm({ closeDropdown: false })
                        }}
                        onBlur={() => {
                            confirm()
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    ></Input>
                    <Space>
                        <Button
                            type="primary"

                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        {/* <Button size="small" style={{ width: 90 }}
                            onClick={() => { clearFilters() }}>
                            Reset
                        </Button> */}
                        {/* <Button
                            type="link"
                            size="small"
                            onClick={() => { }}
                        >
                            Filter
                        </Button> */}
                    </Space>
                </div>;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.title.toLowerCase().includes(value.toLowerCase())
            },
            render: (title, record) => <Link onClick={() => goToUsersSubscribed(record)}>  {title}</Link>,
            width:'250px'
        },

        {
            title: 'Tipologia',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'Corsi sola visualizzazione', value: 'Normal' },
                { text: 'Formazione Obbligatoria', value: 'Mandatory' },
              ],
              onFilter: (value, record)=> 
              {
                  return record.type === value
              },
              width: '10%',
            render: type => <>{type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (conTimer)'}</>,
        },
        {
            title:"Utenti Iscritti",
            dataIndex: "id",
            key:"id",
            render: (title, record) => <div> 
            {countCourses[record.product_id]}</div>,
           
        },
        {
            title: 'Data Creazione',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => <> {moment(created_at).format('DD/MM/YYYY, h:mm:ss a')}</>,
            sorter: (record1, record2) =>
            {
                return record1.created_at > record2.created_at
            }
        },
     
    ];


    return (

        <Container maxWidth="lg">
            <Grid container spacing={5} >
               
                <Grid item xs={8} sm={12} lg={12}>
                    <BreadcrumbSpecfic />
                    <Paper  style={{ marginTop: '1rem' }}>
                        <Table 
                        size="small"
                        bordered
                        rowKey={record => record.id}
                        loading={loading} columns={columns} dataSource={data} 
                        pagination={{
                            current: page,
                            pageSize: pageSize,
                            onChange: (page, pageSize)=>
                            {
                                setPage(page)
                                setPageSize(pageSize)
                            } 
                        }}
                        
                        />
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    );
}

export default SpecificCourses;
