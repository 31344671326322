import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withRouter, useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { Skeleton } from "antd";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function CourseReport() {

  const { user_id } = useParams()

  const [coursesID, setCoursesID] = useState([]);
  const [courseFirstDate, setCourseFirstDate] = useState([]);
  const [courseLastDate, setCourseLastDate] = useState([]);
  const [courseTotalTime, setCourseTotalTime] = useState([]);

  const [userInfo, setUserInfo] = useState(null);
  const moment = require('moment');


  useEffect(() => {
    getData()
  }, [])



  async function getData() {

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentcourseIDForAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          if (json.length > 0) {
            setCoursesID(json)
          }
        }
      )

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserProfileAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          setUserInfo(json[0])
        }
      )


  }

  async function getCourseTotalTime(course_id) {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTotalTimeAdmin/${course_id}`)
      .then((response) => response.json())
      .then((json) => setCourseTotalTime(courseTotalTime => [...courseTotalTime, json]))

  }


  async function getFirstDate(course_id) {

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserReportFirstDate/${course_id}/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          if (json.length > 0) {
            setCourseFirstDate(courseFirstDate => [...courseFirstDate, json[0]]);
          }
        }
      )
  }

  async function getLastDate(course_id) {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserReportLastDate/${course_id}/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          if (json.length > 0) {
            setCourseLastDate(courseLastDate => [...courseLastDate, json[0]]);
          }
        }
      )
  }

  useEffect(() => {

    if (coursesID.length > 0) {
      coursesID.map((item) => {
        if (item.course_id) {
          getFirstDate(item.course_id)
          getLastDate(item.course_id)
          getCourseTotalTime(item.course_id)
        }
        if (item.id) {
          getFirstDate(item.id)
          getLastDate(item.id)
          getCourseTotalTime(item.id)
        }
      })

    }
  }, [coursesID])


  return (
    <Container maxWidth="lg" >
      <Grid container spacing={3}>
        <Breadcrumb />
        <Grid lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Corsi</TableCell>
                  <TableCell align="right">Data Inizio</TableCell>
                  <TableCell align="right">Data fine</TableCell>
                  <TableCell align="right">Nome partecipante</TableCell>
                  <TableCell align="right">Durata</TableCell>
                  <TableCell align="right">Città</TableCell>
                  <TableCell align="right">Paese</TableCell>
                </TableRow>
              </TableHead>
              {userInfo && courseTotalTime.length === coursesID.length ? <TableBody>
                {userInfo && courseTotalTime.length === coursesID.length && courseFirstDate.length > 0 && courseLastDate.length > 0 && coursesID.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {item.title || item.course}
                    </TableCell>
                    <TableCell align="right">{courseFirstDate[index] != 'not_started' ?
                      moment(courseFirstDate[index]).format('DD/MM/YYYY, h:mm:ss a') : 'not_started'}</TableCell>
                    <TableCell align="right">{courseLastDate[index] != 'not_finished' ?
                      moment(courseLastDate[index]).format('DD/MM/YYYY, h:mm:ss a') : 'not_finished'}</TableCell>
                    <TableCell align="right">{userInfo.name} {userInfo.surname}</TableCell>
                    <TableCell align="right">{(((courseTotalTime[index].hours / 3600) > 9) ? (courseTotalTime[index].hours / 3600) : ("0" + (courseTotalTime[index].hours / 3600))) + ":" + (((courseTotalTime[index].minutes / 60) > 9) ? (courseTotalTime[index].minutes / 60) : ("0" + (courseTotalTime[index].minutes / 60))) + ":" + "00"}</TableCell>
                    <TableCell align="right">{userInfo.birth_city}</TableCell>
                    <TableCell align="right">{userInfo.birth_country}</TableCell>
                  </TableRow>
                ))}
              </TableBody> : <Skeleton size="large" />}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

    </Container>


  );
}

export default withRouter(CourseReport);
