import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import { withRouter, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import { Result } from 'antd';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

function TestResult(props) {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const { user_id } = useParams()
    const classes = useStyles();

    const [questions, setQuestion] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [minutes, setMinutes] = useState('0')
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const [answers, setAnswer] = useState([]);
    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [testTitle, setTestTitle] = useState(null);


    const studentData = [
        {
            id: 1,
            question: "Neeraj",
            answer_1: "neeraj@gmail.com",
            answer_2: 2015,
        },
        {
            id: 2,
            question: "Vikas",
            answer_1: "vikas@gmail.com",
            answer_2: 2013,
            answer_3: 785462,
        },

        {
            id: 3,
            question: "Rahul",
            answer_1: "rahul@gmail.com",
        }
    ]

    const columns = [
        { title: "#", field: "id", },
        { title: "Questions", field: "question", },
        { title: "Answers", field: "answer", },
        { title: "User answer", field: "user_answer", },
    ]

    const [names, setNames] = useState([]);

    useEffect(() => {

        if (questions.length > 0 && answers.length > 0 && userAnswers.length > 0) {

            questions.map((question, index) => {

                answers.map((answer, ind) => {
                    if (question.id === answer.question_final_id || question.id === answer.question_id)
                        setNames(current => [...current, {
                            id: index, question: question.question, answer: answer.answers,
                            correct_answer: answer.correct_answer, user_answer: userAnswers.filter(item => item.question_id === question.id)
                                .map(item => item.user_answers).toString()
                        }]);
                })
            })
        }

    }, [questions, answers, userAnswers])

    const downloadCertificate = async () => {

        const doc = new jsPDF();
        var array = [...questions, ...answers]
        autoTable(doc, ({
            theme: 'grid',
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            body: names
        }))

        doc.save('table.pdf')
    }

    useEffect(() => {
        getData()
    }, [])



    async function getData() {

        if (section_id === '0') {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTimeAdminFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        setMinutes(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserPublishedQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => setQuestion(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdminAnswerFinal/${course_id}`).then(
                response => response.json()).then(
                    json => setAnswer(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestScoreFinal/${course_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setScoreAnswers(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestionFinalAdmin/${course_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setTestScore(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserAnswersFinal/${course_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setUserAnswers(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitleFinal/${course_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.title)

                        setTestTitle(json.title)
                }
                );
        } else {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestTimeAdmin/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        setMinutes(json)
                    }
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserPublishedQuestion/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => setQuestion(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdminAnswer/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => setAnswer(json)
                )


            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserTestScore/${course_id}/${section_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setScoreAnswers(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTotalUserScoreQuestionAdmin/${course_id}/${section_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setTestScore(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserAnswers/${course_id}/${section_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setUserAnswers(json)
                )

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getTestTitle/${course_id}/${section_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.title)

                        setTestTitle(json.title)
                }
                );

        }




    }

    const answerStyle = {
        backgroundColor: '#f3f3f3', display: 'flex', alignItems: 'center',
        marginBottom: '10px', borderRadius: '12px',
        padding: '0px 15px 0 0', width: 'fit-content',
        marginLeft: '10px',
        marginTop: '15px'
    }
    return (
        <div >
            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={8} lg={8}>
                        {questions.length > 0 && answers.length > 0 ? <Breadcrumb /> : null}
                        {questions.length > 0 && answers.length > 0 ?
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                                        <Typography variant="h4">  {testTitle}</Typography>
                                    </Paper>

                                </Grid>
                                {questions.map((item, index) => (

                                    <Grid key={index} item xs={12} sm={8} lg={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Typography variant="h6" className={classes.title} style={{ marginRight: '1em' }}>
                                                            {index + 1}/{questions.length}
                                                        </Typography>
                                                        <Typography variant="h5" className={classes.title} >
                                                            {item.question}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="h6" className={classes.title} >
                                                        {item.score} punti
                                                    </Typography>
                                                </Grid>
                                            </AccordionSummary>
                                            <Divider variant='fullWidth' />
                                            {item.answers_type === 'MultipleAnswers' && answers.filter(id => (id.question_id === item.id) || (id.question_final_id === item.id)).map((item) => (
                                                <AccordionDetails style={{
                                                    padding: '0px 15px 0px 0px',
                                                    width: 'fit-content',
                                                    marginLeft: '10px',
                                                    alignItems: 'center'
                                                }}>
                                                    {item.correct_answer === 1 ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CheckCircleIcon style={{ color: 'green' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div> :
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CancelIcon style={{ color: 'red' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div>}
                                                </AccordionDetails>
                                            ))}
                                            {item.answers_type === 'OneAnswer' ?
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"  >
                                                    {answers.filter(id => (id.question_id === item.id) || (id.question_final_id === item.id)).map((item) => (
                                                        <AccordionDetails style={{
                                                            padding: '0px 15px 0px 0px',
                                                            width: 'fit-content',
                                                            marginLeft: '10px',
                                                            alignItems: 'center'
                                                        }}>
                                                            {item.correct_answer === 1 ?
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <CheckCircleIcon style={{ color: 'green' }} />
                                                                    <div >
                                                                        <FormControlLabel style={answerStyle} value={item.answers} control={<Radio
                                                                            checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                                ? true : false}
                                                                            disabled={true} />} label={item.answers} />
                                                                    </div>
                                                                </div> :
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <CancelIcon style={{ color: 'red' }} />
                                                                    <div >
                                                                        <FormControlLabel style={answerStyle} value={item.answers} control={<Radio
                                                                            checked={userAnswers.filter(ans => (ans.user_answers === item.answers) && ((ans.question_id === item.question_id) || (ans.question_id === item.question_final_id))).length > 0
                                                                                ? true : false}
                                                                            disabled={true} />} label={item.answers} />
                                                                    </div>
                                                                </div>}
                                                        </AccordionDetails>))}
                                                </RadioGroup> : null}
                                        </Accordion>
                                    </Grid>))}
                            </Grid> : null}
                    </Grid>
                    {questions.length > 0 ?
                        <Grid item xs={12} sm={4} lg={3} style={{ marginTop: '2rem' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper className={maxHeightPaper} style={{ textAlign: 'center' }}>

                                        <Grid item >
                                            <div style={{ backgroundColor: 'lawngreen' }}>
                                                <Typography variant="h6" >
                                                    Tempo
                                                </Typography>
                                            </div>
                                            <Typography variant="h6" style={{ padding: '8px 0 8px 0px' }}>
                                                {minutes < 10 ? 0 : null}{minutes}:00 Minuti
                                            </Typography>
                                        </Grid>
                                        <Divider variant='fullWidth' />
                                        <Grid item style={{ padding: '8px 0 8px 0px' }} >
                                            <Typography variant="h6" >
                                                Domande: {questions.length}
                                            </Typography>


                                            <Typography variant="h6" >
                                                Punteggio totale : {answerScore}/{testScore}
                                            </Typography>
                                        </Grid>

                                    </Paper>
                                </Grid>
                                {/* <button onClick={downloadCertificate}>
                    Download PDF
                </button> */}
                                {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) >= 70) ?
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <Paper>
                                            <Result style={{ padding: '27px' }}
                                                status='success'
                                                icon={<SmileOutlined />}
                                                title={`Test passed successfully`}
                                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                            />
                                        </Paper>
                                    </Grid>
                                    : null}

                                {answerScore.length > 0 && testScore && answerScore && (((answerScore * 100) / testScore) < 70) ?
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <Paper >
                                            <Result style={{ padding: '27px' }}
                                                status='error'
                                                icon={<FrownOutlined />}
                                                title={`Il test non è stato passato`}
                                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}

                                            />
                                        </Paper>
                                    </Grid> : null}
                            </Grid>
                        </Grid> : null}
                </Grid>

            </Container>
        </div>
    );

}

export default withRouter(TestResult);