import React, { useContext } from "react";
import { Route, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout.jsx";
import Login from "../UserPages/Login";
import NotAuthorizedPage from "../UserPages/NotAuthorizedPage";
import Context from "../Context";
import Home from "../UserPages/Home";
import Courses from "../AdminPages/Courses";
import SpecificCourses from "../AdminPages/SpecificUsers/Courses";

export default function Routerx({ component: Component, type, ...rest }) {

    const { auth } = useContext(Context)
    const { user } = useContext(Context)
    const location = useLocation();



    if (!auth && location.pathname !== '/entra' && type !== 'Guest' && type !== "forgetPassword" && type !== "restorePassword") {

        window.history.replaceState(null, "entra", "/entra")
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (type === "Guest") return <Layout Componentx={Component} propsx={props} userType={type} />;
                if (type === "forgetPassword") return <Layout Componentx={Component} propsx={props} />;
                if (type === "restorePassword") return <Layout Componentx={Component} propsx={props} />;
                if (!auth) {
                    return <Layout Componentx={Login} propsx={props} />;
                }
                else {
                    if (type === "Error") return <Layout Componentx={Component} propsx={props} />;
                    if (user && user.user_type === type) return <Layout Componentx={Component} propsx={props} />;
                    else if (auth && location.pathname === "/") {
                        if (user && user.user_type === "ADM") return <Layout Componentx={Courses} propsx={props} />
                        else if (user && user.user_type === "USR") return <Layout Componentx={Home} propsx={props} />
                        else if (user && user.user_type === "ADM_LMT") return <Layout Componentx={SpecificCourses} propsx={props} />
                        else return <Layout Componentx={NotAuthorizedPage} propsx={props} />
                    }
                    else return <Layout Componentx={NotAuthorizedPage} propsx={props} />

                }
            }

            }
        />
    );

}
