import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Routerx from "./Routerx";
import Home from "../UserPages/Home";
import Courses from "../AdminPages/Courses";
import AddQuestion from "../AdminPages/AddQuestion"
import TakeTest from "../UserPages/TakeTest"
import UpdateQuestion from "../AdminPages/UpdateQuestion"
import ListOfUsers from "../AdminPages/ListOfUsers"
import StudentCourseEnrolled from "../AdminPages/StudentCourseEnrolled"
import CourseDetails from "../AdminPages/CourseDetails";
import SectionModule from "../AdminPages/SectionModule";
import UpdateSection from "../AdminPages/UpdateSection";
import ReadPdf from "../AdminPages/ReadPdf";
import ReadModule from "../UserPages/ReadModule";
import CreateAccount from "../UserPages/CreateAccount";
import CourseBought from "../AdminPages/CourseBought";
import UsersCourses from "../AdminPages/UsersCourses";
import StudentModuleEnrolled from "../AdminPages/StudentModuleEnrolled";
import StudentSectionEnrolled from "../AdminPages/StudentSectionEnrolled";
import UserProfile from "../UserPages/UserProfile";
import Login from "../UserPages/Login";
import ForgetPassword from "../UserPages/ForgetPassword";
import RestorePassword from "../UserPages/RestorePassword";
import AddStudent from "../AdminPages/AddStudent";
import AddCourse from "../AdminPages/AddCourse";
import UpdateCourse from "../AdminPages/UpdateCourse";
import StudentEnrolled from '../AdminPages/StudentEnrolled'
import VideoPage from '../UserPages/VideoPage'
import VideoPageAdmin from '../AdminPages/VideoPageAdmin'
import CourseSections from "../UserPages/CourseSections";
import AdminCourseSections from "../AdminPages/AdminCourseSections";
import Modules from "../UserPages/Modules";
import TestResult from "../UserPages/TestResult";
import NotFoundPage from "../UserPages/NotFoundPage";
import UserPowerPoint from "../UserPages/UserPowerPoint";
import Context from '../Context'
import HomePage from "../AdminPages/HomePage";
import SectionPage from "../AdminPages/SectionPage";
import UserTestResult from "../AdminPages/UserTestResult";
import LoadingPage from "../LoadingPage";
import ReadPowerPoint from '../AdminPages/ReadPowerPoint';
import TestTitleFeedback from '../AdminPages/TestTitleFeedback';
import UpdateTestTitleFeedback from '../AdminPages/UpdateTestTitlefeedback';
import CheckResult from "../UserPages/CheckResult";
import Settings from "../AdminPages/Settings";
import GuestHome from "../GuestPages/GuestHome";
import GuestSection from "../GuestPages/GuestSection";
import GuestModule from "../GuestPages/GuestModule";
import GuestPdf from "../GuestPages/GuestPdf";
import GuestPpt from "../GuestPages/GuestPpt";
import GuestVideoPage from "../GuestPages/GuestVideoPage";
import ModulePage from "../AdminPages/ModulePage";
import AddCategory from "../AdminPages/CategoryPage";
import CourseReport from "../AdminPages/CourseReport";
import UserSubscribedCourse from "../AdminPages/UserSubscribedInCourse/UserSubscribedCourse"
import StudentSubscribedCourse from "../AdminPages/UserSubscribedInCourse/StudentSubscribedCourse"
import StudentSubscribedSection from "../AdminPages/UserSubscribedInCourse/StudentSubscribedSection"
import StudentSubscribedModule from "../AdminPages/UserSubscribedInCourse/StudentSubscribedModule"
import SpecificCourses from "../AdminPages/SpecificUsers/Courses"
import UserSubscribed from "../AdminPages/SpecificUsers/UserSubscribed"
import UserSubscribedSpecificCourse from "../AdminPages/SpecificUsers/UserSubscribedSpecificCourse"
import UserSubscribedSpecificSection from "../AdminPages/SpecificUsers/UserSubscribedSpecificSection"
import UserSubscribedSpecificModule from "../AdminPages/SpecificUsers/UserSubscribedSpecificModule"
import TestResultSpecific from "../AdminPages/SpecificUsers/UserTestResultSpecific"
import AllSubscribedStudents from "../AdminPages/SpecificUsers/AllSubscribedStudents"
import Chat from '../UserPages/chat'
import Comments from '../UserPages/Comments'

export default function Routes() {

  const [auth, setAuth] = useState(false);
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);

  const value = useMemo(() => ({ auth, setAuth, user, setUser }), [auth, setAuth, user, setUser])


  useEffect(() => {
    async function getData() {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/user`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
      }).then(
        response => response.json()).then(
          json => {
            if (json.id) {
              setAuth(true)
              setUser(json)

            }
            setReady(true)
          }

        )
    }
    getData()
  }, [])


  return ready ? (
    <Context.Provider value={value}>
      <BrowserRouter>
        <Switch>
          <Routerx exact path="/" component={Login} />
          <Routerx exact path="/entra" component={Login} />

          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_id/nome-del-test" component={TestTitleFeedback} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/nome-del-test" component={TestTitleFeedback} type="ADM" />

          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_id/inserisci-domande" component={AddQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/inserisci-domande" component={AddQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_id/aggiorna-nome-del-test" component={UpdateTestTitleFeedback} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/aggiorna-nome-del-test" component={UpdateTestTitleFeedback} type="ADM" />

          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_id/aggiorna-domande" component={UpdateQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/aggiorna-domande" component={UpdateQuestion} type="ADM" />

          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli" component={StudentSubscribedModule} type="ADM" />
          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni" component={StudentSubscribedSection} type="ADM" />
          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti/:user_id/corsi" component={StudentSubscribedCourse} type="ADM" />
          <Routerx exact path="/admin/corsi/:product_id/studenti-iscritti" component={UserSubscribedCourse} type="ADM" />



          <Routerx exact path="/password-dimenticata" component={ForgetPassword} type="forgetPassword" />
          <Routerx exact path="/recupera-password" component={RestorePassword} type="restorePassword" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id" component={SectionModule} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/dettagli-corso" component={CourseDetails} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/aggiorna-corso" component={UpdateCourse} type="ADM" />


          <Routerx exact path="/admin/corsi" component={Courses} type="ADM" />
          <Routerx exact path="/admin/settings" component={Settings} type="ADM" />
          <Routerx exact path="/admin/aggiungi-corsista" component={AddStudent} type="ADM" />
          <Routerx exact path="/admin/inserisci-corso" component={AddCourse} type="ADM" />
          <Routerx exact path="/admin/inserisci-categoria" component={AddCategory} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli" component={StudentModuleEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni" component={StudentSectionEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/report" component={CourseReport} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi" component={StudentCourseEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti" component={StudentEnrolled} type="ADM" />


          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/video-page/:module_name/:module_id" component={VideoPageAdmin} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/pdf-page/:module_name/:module_id" component={ReadPdf} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id/:section_name/:section_id/ppt-page/:module_name/:module_id" component={ReadPowerPoint} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_name/:course_id" component={AdminCourseSections} type="ADM" />
          <Routerx exact path="/admin/corsi/sezioni/aggiorna-sezione" component={UpdateSection} type="ADM" />
          {/* <Routerx exact path="/admin/list-users/:user_id/course-bougth" component={CourseBought} type="ADM" />
          <Routerx exact path="/admin/list-users/users-course" component={UsersCourses} type="ADM" />
          <Routerx exact path="/admin/list-users" component={ListOfUsers} type="ADM" /> */}
          <Routerx exact path="/admin/dashbord" component={HomePage} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_name/:course_id" component={SectionPage} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_name/:course_id/:section_name/:section_id" component={ModulePage} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_name/:course_id/:section_name/:section_id/pdf-page/:module_name/:module_id" component={ReadPdf} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_name/:course_id/:section_name/:section_id/ppt-page/:module_name/:module_id" component={ReadPowerPoint} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_name/:course_id/:section_name/:section_id/video-page/:module_name/:module_id" component={VideoPageAdmin} type="ADM" />

          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id/pdf-page/:module_name/:module_id" component={ReadModule} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id/ppt-page/:module_name/:module_id" component={UserPowerPoint} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id/video-page/:module_name/:module_id" component={VideoPage} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_id/test-risultati" component={TestResult} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id/test-risultati" component={TestResult} type="USR" />

          <Routerx exact path="/home/:course_name/:course_id/controlla-I-risultati/:section_name/:section_id" component={CheckResult} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/controlla-I-risultati/:section_id" component={CheckResult} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_id/test" component={TakeTest} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id/test" component={TakeTest} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id/:section_name/:section_id" component={Modules} type="USR" />
          <Routerx exact path="/home/:course_name/:course_id" component={CourseSections} type="USR" />
          <Routerx exact path="/home/profilo" component={UserProfile} type="USR" />
          <Routerx exact path="/home" component={Home} type="USR" />
          <Routerx exact path="/dettagli-account" component={CreateAccount} type="USR" />
          <Routerx exact path="/chat" component={Chat} type="USR" />
          <Routerx exact path="/comments" component={Comments} type="USR" />


          <Routerx exact path="/corsi" component={GuestHome} type="Guest" />
          <Routerx exact path="/corsi/:course_name/:course_id" component={GuestSection} type="Guest" />
          <Routerx exact path="/corsi/:course_name/:course_id/:section_name/:section_id" component={GuestModule} type="Guest" />
          <Routerx exact path="/corsi/:course_name/:course_id/:section_name/:section_id/pdf-page/:module_name/:module_id" component={GuestPdf} type="Guest" />
          <Routerx exact path="/corsi/:course_name/:course_id/:section_name/:section_id/ppt-page/:module_name/:module_id" component={GuestPpt} type="Guest" />
          <Routerx exact path="/corsi/:course_name/:course_id/:section_name/:section_id/video-page/:module_name/:module_id" component={GuestVideoPage} type="Guest" />

          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/test-risultati" component={TestResultSpecific} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli/test-risultati" component={TestResultSpecific} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli" component={UserSubscribedSpecificModule} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti/:user_id/corsi/:course_id/sezioni" component={UserSubscribedSpecificSection} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti/:user_id/corsi" component={UserSubscribedSpecificCourse} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi/:product_id/studenti-iscritti" component={UserSubscribed} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/corsi" component={SpecificCourses} type="ADM_LMT" />

          <Routerx exact path="/admin-spc/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/test-risultati" component={TestResultSpecific} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli/test-risultati" component={TestResultSpecific} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli" component={UserSubscribedSpecificModule} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/studenti-iscritti/:user_id/corsi/:course_id/sezioni" component={UserSubscribedSpecificSection} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/studenti-iscritti/:user_id/corsi" component={UserSubscribedSpecificCourse} type="ADM_LMT" />
          <Routerx exact path="/admin-spc/studenti-iscritti" component={AllSubscribedStudents} type="ADM_LMT" />

          <Routerx component={NotFoundPage} type="Error" />
          <Routerx path="/not-found" component={NotFoundPage} type="Error" />
        </Switch>
      </BrowserRouter>
    </Context.Provider >

  ) : <LoadingPage />
}
