import { Input, Modal, Button, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';

const EmailInput = (props) => {

  const [form] = Form.useForm();
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setIsModalVisible(props.showSendEmailInput)

  }, [props])


  const handleCancel = () => {
    reset()
    props.closeModal(false)
  };


  async function stopAccessToCourse(data) {

    setLoading(true)
    const formData = new FormData();


    formData.append('user_id', props.user_id)
    formData.append('course_id', props.course_id)
    formData.append('reason', data.reason)


    await fetch(`${process.env.REACT_APP_API_BASE_URL}/stopAccessToCourse`, {
      method: "POST",
      body: formData
    }).then((response) => response.json()).then((json) => {
      if (json[0].msg === 'success') {
        handleCancel()
      }
      else if (json[0].msg === 'network_error') {
        setErrorNetwork(true)
      }

    })

    setLoading(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseSubmit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorNetwork(false);
  }

  register("reason", { value: "Il corso che stai seguendo è stato sospeso. contattaci all'indirizzo assistenza@alteredu.it per maggiori dettagli." })
  return (
    <>
      <Modal title="Il corso che stai seguendo è stato sospeso" visible={isModalVisible} okText="Send" confirmLoading={loading}
        footer={[
          <Button key="back" disabled={loading} onClick={handleCancel}>
            Cancella
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
            Send
          </Button>]}>
        <Form form={form} onFinish={handleSubmit(stopAccessToCourse)}>
          <TextArea error={true} rows={4} placeholder="Il corso che stai seguendo è stato sospeso" defaultValue="Il corso che stai seguendo è stato sospeso. contattaci all'indirizzo assistenza@alteredu.it per maggiori dettagli." {...register('reason', { required: true })} onChange={(e) => setValue('reason', e.target.value)} />
          {errors.reason && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
        </Form>
      </Modal>

      <Snackbar open={errorNetwork} autoHideDuration={4000} onClose={handleCloseSubmit} >
        <Alert onClose={handleCloseSubmit} severity="error">
          Qualcosa è andato storto, riprova
        </Alert>
      </Snackbar>


    </>
  );
};

export default EmailInput;