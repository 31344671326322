import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import 'antd/dist/antd.css';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Input, Space, Form, DatePicker } from 'antd';
import { UserOutlined , PhoneOutlined, HomeOutlined} from '@ant-design/icons';
import { useForm } from "react-hook-form";
import moment from 'moment';


function Profile() {


    const { register, handleSubmit, setValue, reset,formState: { errors } } = useForm()

    const [form] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [error, setError] = useState(false);
    const [userProfile, setUserProfile] = useState([]);
    const [disable, setDisable] = useState(true)
    const [disableInput, setDisableInput] = useState(true)
   

    const [loading, setLoading] = useState(false);
    

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserProfile`).then(
            response => response.json()).then(
                json => setUserProfile(json)
            )

    }

    useEffect(() => {
        
        if (userProfile.length > 0) {
            setValue('first_name', userProfile[0].name)
            setValue('last_name', userProfile[0].surname)
            setValue('birth_country', userProfile[0].birth_country)
            setValue('phone', userProfile[0].phone)
            if (userProfile[0].birth_city)
            setValue('birth_city', userProfile[0].birth_city)
            if (userProfile[0].birth_date) {
                setValue('birth_date', userProfile[0].birth_date)
            }
        }


    }, [userProfile])





    // async function submit() {
    const submit = async (data) => {
        
        setLoading(true)

            const formData = new FormData();

            formData.append('name', data.first_name)
            formData.append('surname', data.last_name)
            if(data.birth_date)
            formData.append('birth_date', data.birth_date)
            formData.append('birth_city', data.birth_city)
            formData.append('birth_country', data.birth_country)
            formData.append('phone', data.phone)
           

            await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateUserData`, {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((json) => {
                if (json.msg === "success") {
                    setUserProfile([])
                    getData()
                    setDisable(true)
                    setDisableInput(true)
                    setSuccessSubmit(true)
                } else {
                    getData()
                    setDisable(true)
                    setError(true)
                }
            })

       
        setLoading(false)
    }

 
    function cancel() {
        setDisable(true)
        setDisableInput(true)
    }


    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
       
    };

    function onChangeBirthDate(date) {
        if(date)
        setValue('birth_date', date.format('YYYY-MM-DD').toString())
    }

    function updateProfile() {
        setDisable(false)
        setDisableInput(false)
        setOpen(true)
    }
    const handleCloseSubmit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessSubmit(false);
    }
    return (

        <Grid item xs={12} sm={5} lg={6}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                        <Alert onClose={handleClose} severity="info">
                            Prima di continuare, aggiungi le informazioni mancanti e salva
                        </Alert>
                    </Snackbar>
                    <Snackbar open={successSubmit} autoHideDuration={3000} onClose={handleCloseSubmit} >
                        <Alert onClose={handleCloseSubmit} severity="success">
                            Il profile è stato aggiornato correttamente
                        </Alert>
                    </Snackbar>
                    <Snackbar open={error} autoHideDuration={3000} onClose={handleError} >
                        <Alert onClose={handleError} severity="error">
                            Si è verificato un errore, riprova
                        </Alert>
                    </Snackbar>
            <Form style={{paddingLeft: '1rem'}} form={form} onFinish={handleSubmit(submit)}>
                {userProfile.length > 0 ?
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Input prefix={<UserOutlined />} disabled={disableInput} addonBefore="Nome:" defaultValue={userProfile[0].name} placeholder="Nome" {...register('first_name', { required: true })} onChange={(e) => setValue('first_name', e.target.value)} />
                        {errors.first_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input prefix={<UserOutlined />} addonBefore="Cognome:" disabled={disableInput}  defaultValue={userProfile[0].surname} placeholder="Cognome" {...register('last_name', { required: true })} onChange={(e) => setValue('last_name', e.target.value)} />
                        {errors.last_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input  prefix={<HomeOutlined />} addonBefore="Nazione:" disabled={disableInput}  defaultValue={userProfile[0].birth_country} placeholder="Nazione" {...register('birth_country', { required: true })} onChange={(e) => setValue('birth_country', e.target.value)} />
                        {errors.birth_country && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input  prefix={<HomeOutlined />} addonBefore="Luogo di Nascita:" disabled={disableInput}  defaultValue={userProfile[0].birth_city} placeholder="Luogo di Nascita" {...register('birth_city', { required: true })} onChange={(e) => setValue('birth_city', e.target.value)} />
                        {errors.birth_city && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input prefix={<PhoneOutlined />} addonBefore="Telefono:" disabled={disableInput}  defaultValue={userProfile[0].phone} placeholder="Telefono" {...register('phone', { required: true })} onChange={(e) => setValue('phone', e.target.value)} />
                        {errors.phone && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}

                        <Form.Item label="Data di nascita" >
                            <DatePicker disabled={disableInput} {...register('birth_date', setValue('birth_date', userProfile[0].birth_date) )}  onChange={onChangeBirthDate} defaultValue={userProfile[0].birth_date ? moment(userProfile[0].birth_date) : moment()} />
                        </Form.Item>

                        {disable ?
                            <Grid item xs={12} style={{ textAlign: 'center' }} >
                                <Button
                                    type="primary"
                                    onClick={updateProfile}

                                >
                                    Aggiorna
                                </Button>
                            </Grid> : null}

                        {disable ? null :
                            <Grid item xs={12} style={{ justifyContent: 'space-around', display:'flex' }} >
                                <Button
                                    type="primary"
                                    onClick={cancel}
                                    disabled={loading}
                                >
                                    Cancella
                                </Button>
                                <Button type="primary" htmlType="submit"  loading={loading}>
                            Salva
                        </Button>
                                
                            </Grid>}
                    </Space> : null}
            </Form>

        </Grid >
    );
}

export default withRouter(Profile);
