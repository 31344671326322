import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Space, TimePicker, Upload, Switch, Input, Form } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useForm } from 'react-hook-form';

const ModalAddModule = (props) => {

    const { TextArea } = Input;
    const { Option } = Select;
    const [form] = Form.useForm();
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileType, setFileType] = useState("pdf");
    const [file, setFile] = useState(null);
    const [courseType, setCourseType] = useState(null);
    const [time, setTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorFile, setErrorFile] = useState(false);


    useEffect(() => {
        setIsModalVisible(props.showModal)
        setCourseType(props.courseType)
    }, [props])

    const onSubmit = async (data) => {
        if (fileType === 'pdf' && !file) {
            setErrorFile(true)
        }
        if (fileType === 'video_to_server' && !file) {
            setErrorFile(true)
        }
        else {

            setLoading(true);

            const formData = new FormData();
            formData.append("section_id", props.section_id);
            formData.append("course_id", props.course_id);
            formData.append("course_name", props.course_name);
            formData.append("section_name", props.section_name);
            if (data.module_description) formData.append("description", data.module_description);
            formData.append("type", fileType);
            formData.append("module_name", data.module_name);
            if (data.module_url) formData.append("module_path", data.module_url);

            if (fileType === 'pdf') {
                var checked = 0;
                if (data.module_download) {
                    checked = 1;
                }

                formData.append("download", checked);
                formData.append("file", file);

            }

            if (fileType === 'video_to_server') {
                formData.append("file", file);
            }

            if (courseType && courseType.type === 'Mandatory') {
                var hours = data.module_time.hours()
                var minutes = data.module_time.minutes()
                var seconds = data.module_time.seconds()
                var total_in_second = hours * 3600 + minutes * 60 + seconds;

                formData.append("time", total_in_second);
            }
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/addmodule`, {
                method: "POST",
                body: formData,
            }).then(() => {
                setLoading(false)
                handleCancel();
            });

        }
    };


    const handleCancel = () => {
        reset()
        setTime(null)
        setFile(null);
        props.closeModal(false)
        props.saved()
    };



    function handleChangeFileType(value) {
        setFileType(value);
    }


    const pdf = {
        beforeUpload: (file) => {
            setErrorFile(false)
            setFile(file);
            return false;
        },
    };

    const video_to_server = {
        beforeUpload: (file) => {
            setErrorFile(false)
            setFile(file);
            return false;
        },
    };

    return (
        <Modal title="Aggiungi modulo" visible={isModalVisible} onOk={form.submit} onCancel={handleCancel}
            footer={[
                <Button key="back" disabled={loading} onClick={handleCancel}>
                    Cancella
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
                    Salva
                </Button>]}>
            <Form form={form} onFinish={handleSubmit(onSubmit)}>
                <Space direction="vertical" style={{ display: 'flex' }}>
                    <Select defaultValue="pdf" style={{ width: 120 }} onChange={handleChangeFileType}>
                        <Option value="pdf">Pdf</Option>
                        <Option value="ppt">Ppt</Option>
                        <Option value="video">Video</Option>
                        <Option value="video_to_server">Upload video</Option>
                    </Select>
                    <Input placeholder="Nome modulo" {...register('module_name', { required: true })} onChange={(e) => setValue('module_name', e.target.value)} />
                    {errors.module_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                    {fileType === 'pdf' ?
                        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Space direction="vertical">
                                <Upload
                                    {...pdf}
                                    listType="pdf"
                                    maxCount={1}
                                    accept="pdf/*"
                                    required
                                >
                                    <Button icon={<UploadOutlined />}>Aggiorna pdf</Button>
                                </Upload>
                                {errorFile && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                            </Space>
                            <Space>
                                <Switch size="small" {...register('module_download')} onChange={(checked) => setValue('module_download', checked)} />
                                <span>Abilita lo scaricamento</span>
                            </Space>
                        </Space>
                        : null
                    }
                    {fileType === 'ppt' || fileType === 'video' ?
                        <>
                            <Input placeholder="Url" {...register('module_url', { required: true })} onChange={(e) => setValue('module_url', e.target.value)} />
                            {errors.module_url && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}</>
                        : null}

                    {fileType === 'video_to_server' ?
                        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Space direction="vertical">
                                <Upload
                                    {...video_to_server}
                                    listType="video"
                                    maxCount={1}
                                    accept="video/*"
                                    required
                                >
                                    <Button icon={<UploadOutlined />}>Aggiorna video</Button>
                                </Upload>
                                {errorFile && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                            </Space>
                        </Space>
                        : null
                    }

                    {courseType && courseType.type === 'Mandatory' ?
                        <>
                            <TimePicker showNow={false} value={time} {...register('module_time', { required: true })} onChange={(time) => {
                                setValue('module_time', time)
                                setTime(time)
                            }} />
                            {errors.module_time && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}</>
                        : null}

                    <TextArea
                        placeholder="Descrizione modulo"
                        autoSize={{ minRows: 3 }}
                        {...register('module_description')} onChange={(e) => setValue('module_description', e.target.value)}
                    />
                </Space>
            </Form>
        </Modal>
    );
};

export default ModalAddModule;