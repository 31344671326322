import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import { Avatar } from 'antd';
import 'antd/dist/antd.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Upload } from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useForm } from "react-hook-form";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Profile from "./Components/Profile/Profile.js";

function UserProfile() {

    const classes = useStyles();

    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm()

    const [open, setOpen] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [error, setError] = useState(false);
    const [userProfile, setUserProfile] = useState([]);
    const [image, setImage] = useState('');
    const [disable, setDisable] = useState(true)
    const [savedPassword, setSavedPassword] = useState(false);

    const [file, setFile] = useState(null);


    const [loading, setLoading] = useState(false);
    const [isPassword, setIsPassword] = useState("password");


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserProfile`).then(
            response => response.json()).then(
                json => setUserProfile(json)
            )
    }

    const Imagefile = {
        beforeUpload: file => {
            setFile(file)
            setImage(URL.createObjectURL(file))
            return false;
        }
    };

    function removedImage() {
        setImage(null)
        setFile(null)
    }

    const onSubmit = async data => {

        setLoading(true)

        const formDataPassword = new FormData();

        formDataPassword.append('password', data.password)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/changePassword`, {
            method: "POST",
            body: formDataPassword
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setSavedPassword(true)
                        reset();
                    }
                    else setError(true)
                }
            )

        setLoading(false)
    }
    // async function submit() {
    const submit = async (data) => {
        console.log(data)

        setLoading(true)

        const formData = new FormData();

        if (file !== '')
            formData.append('file', file)

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateUserProfileImage`, {
            method: "POST",
            body: formData
        }).then((response) => response.json()).then((json) => {
            if (json.msg === "success") {
                setUserProfile([])
                getData()
                setDisable(true)
                setSuccessSubmit(true)
            } else {
                getData()
                setDisable(true)
                setError(true)
            }
        })


        setLoading(false)
    }

    function updateProfile() {
        setDisable(false)
        setOpen(true)
    }
    function cancel() {
        setImage('')
        setDisable(true)
    }

    const handleCloseSubmit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSavedPassword(false)
        setSuccessSubmit(false);
    }
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    return (
        <Container maxWidth="md">
            <Grid container spacing={3} justify="center">

                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                    <Alert onClose={handleClose} severity="info">
                        Prima di continuare, aggiungi le informazioni mancanti e salva
                    </Alert>
                </Snackbar>
                <Snackbar open={successSubmit} autoHideDuration={3000} onClose={handleCloseSubmit} >
                    <Alert onClose={handleCloseSubmit} severity="success">
                        Il profile è stato aggiornato correttamente
                    </Alert>
                </Snackbar>
                <Snackbar open={savedPassword} autoHideDuration={3000} onClose={handleCloseSubmit} >
                    <Alert onClose={handleCloseSubmit} severity="success">
                        La password è stata aggiornata
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={3000} onClose={handleError} >
                    <Alert onClose={handleError} severity="error">
                        Si è verificato un errore, riprova
                    </Alert>
                </Snackbar>


                <Grid item xs={12} sm={12} lg={12}>
                    <Breadcrumb />
                    <Paper>
                        <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>

                            Dettagli account
                        </Typography>
                        <Divider variant='fullWidth' style={{ marginBottom: '2rem' }} />
                        <Grid container spacing={3} justifyContent='space-between'>
                            <Profile />


                            <Grid item xs={12} sm={5} lg={4}>

                                {userProfile.length > 0 ?
                                    <Grid item xs={12} lg={12} style={{
                                        textAlign: 'center', padding: '10px'
                                    }} >
                                        {image ? <Avatar src={image}
                                            style={{ lineHeight: '60px' }} size={120} /> :
                                            <Avatar src={`${process.env.REACT_APP_API_BASE_URL_IMAGE}/${userProfile[0].image_path}`}
                                                style={{ lineHeight: '87px' }} size={120} icon={<UserOutlined />} />}


                                        {disable ? null :

                                            <div style={{ marginTop: '1rem' }}>
                                                <Upload
                                                    {...Imagefile}
                                                    listType="picture"
                                                    maxCount={1}
                                                    accept='image/*'
                                                    onRemove={removedImage} >
                                                    <Button icon={<UploadOutlined />} >Upload Immagine</Button>
                                                </Upload>
                                            </div>

                                        }
                                    </Grid> : null}
                                {disable ?
                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            type="primary"
                                            className={classes.button}
                                            onClick={updateProfile}

                                        >
                                            Aggiorna
                                        </Button>
                                    </Grid> : null}

                                {disable ? null :
                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            type="primary"
                                            className={classes.button}
                                            onClick={cancel}
                                            disabled={loading}
                                        >
                                            Cancella
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={classes.button}
                                            onClick={submit}
                                            loading={loading}>
                                            Salva
                                        </Button>

                                    </Grid>}



                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {userProfile.length > 0 ?
                    <Grid item xs={12} lg={12}>
                        <Grid item xs={12} lg={5}>
                            <Paper style={{ padding: '20px' }}>
                                <InputLabel htmlFor="age-native-helper" style={{ marginBottom: '2rem' }}>Cambia password</InputLabel>
                                <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <Grid item xs={12} sm={5} lg={12} style={{
                                        display: 'grid', gridGap: '20px'
                                    }}>
                                        <TextField
                                            {...register('password', { required: true })}
                                            variant="outlined"
                                            size="small"
                                            id="password"
                                            label='Password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}
                                            error={errors?.password?.type === "required" && true}
                                            helperText={errors?.password?.type === "required" && 'Il campo è obbligatorio'}
                                        />
                                        <TextField
                                            {...register('confirme_password', {
                                                required: true,
                                                validate: {
                                                    passwordMatch: value => (value === getValues().password) || 'La password non è corretta',
                                                }
                                            })}
                                            required
                                            variant="outlined"
                                            size="small"
                                            id="confirme_password"
                                            label='Conferma password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}

                                            error={(errors?.confirme_password?.type === 'passwordMatch' && true) || (errors?.confirme_password?.type === "required" && true)}
                                            helperText={(errors?.confirme_password?.type === 'passwordMatch' && errors?.confirme_password?.message) ||
                                                (errors?.confirme_password?.type === "required" && 'Il campo è obbligatorio')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className={classes.button}
                                            loading={loading}
                                            style={{ width: '120px' }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>
                                </form>

                            </Paper>
                        </Grid>
                    </Grid> : null}
            </Grid >
        </Container >

    );
}

export default withRouter(UserProfile);
