import React, { useState, useEffect } from 'react';
import { Modal, Button, Skeleton, Space, TimePicker, Upload, Switch, Input, Form } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useForm } from 'react-hook-form';

const ModalUpdateModule = (props) => {

    const { TextArea } = Input;
    const [form] = Form.useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileType, setFileType] = useState("pdf");
    const [file, setFile] = useState(null);
    const [courseType, setCourseType] = useState(null);
    const [time, setTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState(null);
    const [module_id, setModuleId] = useState(null);
    const [checkedDownload, setCheckedDownload] = useState(false);

    const moment = require('moment');

    useEffect(() => {
        setModuleId(props.module_id)
        setIsModalVisible(props.showModal)
        setCourseType(props.courseType)
    }, [props])

    useEffect(() => {

        if (module_id) getData()

    }, [module_id])

    useEffect(() => {

        if (module) {
            setTime(moment(moment.utc(module.time * 1000).format('HH:mm:ss'), 'HH:mm:ss'))
            if (module.download === 0) setCheckedDownload(false)
            if (module.download === 1) setCheckedDownload(true)
        }

    }, [module])

    async function getData() {
        setLoading(true);

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModule/${module_id}`).then(
            response => response.json()).then(
                json => setModule(json)).then(() => setLoading(false))
    }



    const onSubmit = async (data) => {

        setLoading(true);
        const formData = new FormData();
        formData.append('module_name', module.module_name)
        formData.append('type', module.type)
        formData.append('id', module_id)
        formData.append("section_id", props.section_id);
        formData.append("course_id", props.course_id);
        if (data.module_description) formData.append("description", data.module_description);
        // formData.append("type", fileType);
        formData.append("module_name", data.module_name);
        if (data.module_url) formData.append("module_path", data.module_url);

        if (module.type === 'pdf') {
            var checked = 0;
            if (checkedDownload) {
                checked = 1;
            }

            formData.append("download", checked);
            if (file) formData.append("file", file);

        }

        if (courseType && courseType.type === 'Mandatory') {
            var hours = data.module_time.hours()
            var minutes = data.module_time.minutes()
            var seconds = data.module_time.seconds()
            var total_in_second = hours * 3600 + minutes * 60 + seconds;

            formData.append("time", total_in_second);
        }

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateModule`, {
            method: "POST",
            body: formData,
        }).then(() => {
            setLoading(false)
            handleCancel();
        });


    };

    const handleCancel = () => {
        props.module_id_clear()
        setModule(null)
        setTime(null)
        props.closeModal(false)
        props.saved()
    };



    function handleChangeFileType(value) {
        setFileType(value);
    }


    const pdf = {
        beforeUpload: (file) => {
            setFile(file);
            return false;
        },
    };


    return (
        <Modal title="Aggiorna modulo" visible={isModalVisible} onOk={form.submit} onCancel={handleCancel}
            footer={[
                <Button key="back" disabled={loading} onClick={handleCancel}>
                    Cancella
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
                    Salva
                </Button>]}>
            {module ?
                <Form form={form} onFinish={handleSubmit(onSubmit)}>
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Input defaultValue={module.module_name} placeholder="Nome modulo" {...register('module_name', setValue('module_name', module.module_name), { required: true })} onChange={(e) => setValue('module_name', e.target.value)} />
                        {errors.module_name && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        {module.type === 'pdf' ?
                            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Space direction="vertical">
                                    <Upload
                                        {...pdf}
                                        listType="pdf"
                                        maxCount={1}
                                        accept="pdf/*"
                                        required
                                    >
                                        <Button icon={<UploadOutlined />}>Aggiorna pdf</Button>
                                    </Upload>

                                </Space>
                                <Space>
                                    <Switch checked={checkedDownload} size="small" {...register('module_download', setValue('module_download', checkedDownload))} onChange={(checked) => setCheckedDownload(checked)} />
                                    <span>Abilita lo scaricamento</span>
                                </Space>
                            </Space>
                            : null
                        }
                        {(module.type === 'ppt') || (module.type === 'video') ?
                            <>
                                <Input defaultValue={module.module_path} placeholder="Url" {...register('module_url', setValue('module_url', module.module_path), { required: true })} onChange={(e) => setValue('module_url', e.target.value)} />
                                {errors.module_url && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}</>
                            : null}

                        {courseType && courseType.type === 'Mandatory' ?
                            <>
                                <TimePicker format="HH:mm:ss" value={time} showNow={false} {...register('module_time', setValue('module_time', time), { required: true },)} onChange={(time) => {
                                    setValue('module_time', time)
                                    setTime(time)
                                }} />
                                {errors.module_time && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}</>
                            : null}

                        <TextArea
                            placeholder="Descrizione modulo"
                            autoSize={{ minRows: 3 }}
                            {...register('module_description', setValue('module_description', module.description))} onChange={(e) => setValue('module_description', e.target.value)}
                            defaultValue={module.description}
                        />
                    </Space>
                </Form> : <Skeleton active />}
        </Modal>
    );
};

export default ModalUpdateModule;