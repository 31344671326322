import React, { useState, useEffect } from "react";
import { useStyles } from "../../../components/MainTheme";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';
import { LockOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default function ModuleList(props) {


    const classes = useStyles();
    const history = useHistory();
    const [allowedModule, setAllowedModule] = useState([])
    const [allowedModuleNormal, setAllowedModuleNormal] = useState([])
    const [courseType, setCourseType] = useState(null);


    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourseTitlePicture/${props.course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCourseType(json.type)
                    }
                }
            )
        getAllowedResources()
    }

    async function getAllowedResources() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllowedModule/${props.course_id}/${props.section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setAllowedModule(json)
                })

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllowedModuleNormal/${props.course_id}/${props.section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setAllowedModuleNormal(json)
                })

    }

    function goToModule(item) {

        if (item.type === 'pdf')
            history.push({
                pathname: `/home/${props.course_name}/${props.course_id}/${props.section_name}/${props.section_id}/pdf-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
        else if (item.type === 'ppt')
            history.push({
                pathname: `/home/${props.course_name}/${props.course_id}/${props.section_name}/${props.section_id}/ppt-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
        else if (item.type === 'video' || item.type === 'video_to_server')
            history.push({
                pathname: `/home/${props.course_name}/${props.course_id}/${props.section_name}/${props.section_id}/video-page/${item.module_name.toLowerCase().replaceAll(' ', "-")}/${item.id}`,
            })
    }

    return (
        <Paper>
            <Grid item style={{ display: 'flex', padding: '20px 30px', justifyContent: 'space-between' }}>
                <Typography variant='h5' style={{
                    color: '#252525',
                    fontWeight: '600',
                }}>Moduli</Typography>
                <span style={{
                    fontSize: '22px'
                }}>{props.modules.length}</span>
            </Grid>
            <List component="nav" >
                {props.modules.map((item, index) => (
                    <ListItem key={index} button onClick={() => goToModule(item)}
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start'
                        }}
                        disabled={courseType && courseType === 'Mandatory' ?
                            (index <= allowedModule.length) || index === 0 ? false : true

                            : (index <= allowedModuleNormal.length) || index === 0 ? false : true}

                    >
                        <div className={classes.videoImage}>
                            <img alt='' src={props.coursePicture ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${props.coursePicture.replace('\\', "/")}` : null}
                                style={{
                                    display: 'block',
                                    width: '125px',
                                    marginRight: '20px',
                                    height: '80px'
                                }}></img>
                        </div>
                        <Grid item xs={12} className={classes.modulesName}>
                            <Typography variant='h6' style={{
                                marginTop: '0px',
                                marginBottom: '.25rem',
                                fontWeight: '500',
                                color: '#000000',
                            }} >
                                {item.module_name}
                            </Typography>
                            {props.moduleStatus.filter(function (val) {
                                return (val.module_id === item.id && val.finished_reading === 1);
                            }).length > 0 ?
                                <DoneIcon style={{ color: '#33eb91' }} />
                                : null}

                            {courseType && courseType === 'Mandatory' ?
                                (index <= allowedModule.length) || index === 0 ? false :
                                    <LockOutlined style={{ fontSize: '25px' }} />
                                : (index <= allowedModuleNormal.length) || index === 0 ? false :
                                    <LockOutlined style={{ fontSize: '25px' }} />}
                        </Grid>
                    </ListItem>

                ))}

            </List>
        </Paper>
    );
}

ModuleList.propTypes = {
    children: PropTypes.node,
};
