import React, { useState, useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from "@material-ui/core/Grid";
import MuiAlert from '@material-ui/lab/Alert';
import { SearchOutlined } from '@ant-design/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { Table, Button, Popconfirm, Space, Input } from 'antd';
import { Link } from '@mui/material';
import { useStyles } from "../components/MainTheme.js";
import ListItems from "../components/Lists/List.js";
import TableCourses from "../components/Tables/TableCourses.js";
import TableCategories from "../components/Tables/TableCategories.js";
import EmailInput from "../components/Modal/SendStopEmailInput";

function StudentCourseEnrolled() {

  const { user_id } = useParams()
  const classes = useStyles();

  const [courses, setCourses] = useState([]);
  const [studentInfo, setStudentInfo] = useState(null);
  const [noCourse, setNoCourses] = useState(false);
  const [adding, setAdding] = useState(false);
  const [coursesID, setCoursesID] = useState([]);

  const history = useHistory();
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorNetwork, setErrorNetwork] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showSendEmailInput, setShowSendEmailInput] = useState(false);
  const [stopAccessToCourseId, setStopAccessToCourseId] = useState(null);

  const [categoriesTitle, setCategoriesTitle] = useState([]);
  const [categoriesID, setCategoriesID] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [stopedAccessCourses, setStopedAccessCourses] = useState([]);

  function Alert(props) {
    return <MuiAlert elevation={2} variant="outlined" {...props} />;
  }

  function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  useEffect(() => {
    getData()
  }, [])

  async function getData() {

    setLoading(true)
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStudentcourseForAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          if (json.length > 0) {
            setCourses(json)
            setNoCourses(false)
          }
          else if (json.length === 0) {
            setCourses([])
            setNoCourses(true)
          }
        }
      )

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserProfileAdmin/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          setStudentInfo(json[0])
          setLoading(false)
        }
      )

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getStopedAccessToCourse/${user_id}`).then(
      response => response.json()).then(
        (json) => {
          setStopedAccessCourses(json)
          setLoading(false)
        }
      )
  }


  async function addAssignedCourses() {
    setAdding(true)

    const formData = new FormData();


    formData.append('user_id', user_id)
    formData.append('courses', JSON.stringify(selectedCourses))
    formData.append('coursesID', JSON.stringify(coursesID))
    formData.append('categoriesID', JSON.stringify(categoriesID))


    await fetch(`${process.env.REACT_APP_API_BASE_URL}/addAssignedCourses`, {
      method: "POST",
      body: formData
    }).then((response) => response.json()).then((json) => {
      if (json[0].msg === 'success') {
        setSuccessSubmit(true)
        setSelectedCourses([])
        setCoursesID([])
        setCategoriesTitle([])
        setCategoriesID([])
        getData()
        setAdding(false)
        setSelectedRowKeys([])
      }
      else if (json[0].msg === 'network_error') {
        setErrorNetwork(true)
        setSelectedCourses([])
        setCoursesID([])
        getData()
        setAdding(false)


      }

    })
  }

  function courseReport() {
    history.push({
      pathname: `/admin/studenti-iscritti/${user_id}/corsi/report`
    })
  }

  function goToSections(record) {
    if (record.course) {
      history.push({
        pathname: `/admin/studenti-iscritti/${user_id}/corsi/${record.course_id}/sezioni`
      })
    } else if (record.title) {
      history.push({
        pathname: `/admin/studenti-iscritti/${user_id}/corsi/${record.id}/sezioni`,

      })
    }
  }

  async function deleteAssignedCourse(id) {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteAssignedCourse/${id}/${user_id}`, {
      method: "DELETE"
    }).then(() => {
      getData()
      setSelectedRowKeys([])
    })
  }

  async function stopAccessToCourse(course_id) {

    // const formData = new FormData();


    // formData.append('user_id', user_id)
    // formData.append('course_id', course_id)


    // await fetch(`${process.env.REACT_APP_API_BASE_URL}/stopAccessToCourse`, {
    //   method: "POST",
    //   body: formData
    // }).then((response) => response.json()).then((json) => {
    //   if (json.msg === 'success') {
    //     getData()
    //   }
    //   else if (json.msg === 'network_error') {
    //     setErrorNetwork(true)
    //     getData()
    //   }

    // })
    setStopAccessToCourseId(course_id)
    setShowSendEmailInput(true)

  }

  async function giveAccessToCourse(course_id) {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/giveAccessToCourse/${course_id}/${user_id}`, {
      method: "DELETE"
    }).then(() => {
      getData()
    })
  }

  const handleCloseSubmit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSubmit(false);
    setErrorNetwork(false);
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => <> {(page - 1) * pageSize + index + 1} </>,
    },

    {
      title: 'Nome del Corso',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        return <div style={{ padding: 8 }}>
          <Input autoFocus placeholder='Search...'
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              // confirm({ closeDropdown: false })
            }}
            onBlur={() => {
              confirm()
            }}
            onPressEnter={() => {
              confirm()
            }}
            style={{ marginBottom: 8, display: 'block' }}
          ></Input>
          <Space>
            <Button
              type="primary"

              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>;
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.title) {
          return record.title.toLowerCase().includes(value.toLowerCase())
        } else if (record.course) {
          return record.course.toLowerCase().includes(value.toLowerCase())
        }

      },
      render: (title, record) => <Link onClick={() => goToSections(record)}>  {record.course ? record.course : record.title}</Link>,
      width: '500px'
    },

    {
      title: 'Tipologia',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Corsi sola visualizzazione', value: 'Normal' },
        { text: 'Formazione Obbligatoria', value: 'Mandatory' },
      ],
      onFilter: (value, record) => {
        return record.type === value
      },
      width: '20%',
      render: type => <>{type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (conTimer)'}</>,
    },
    {
      title: 'Stop access',
      key: 'stop',
      render: (text, record) => <>
        {record.course_id ? stopedAccessCourses.includes(record.course_id) ?
          <Popconfirm
            onConfirm={() => { record.course_id ? giveAccessToCourse(record.course_id) : giveAccessToCourse(record.id) }}
            title="Sei sicuro?" okText="Si" cancelText="No">
            <Button type="link" style={{ padding: "0px", color: 'green' }}>Access</Button>
          </Popconfirm>
          : <Button type="link" style={{ padding: "0px", color: 'red' }} onClick={() => {
            if (record.course_id) stopAccessToCourse(record.course_id)
            else stopAccessToCourse(record.id)
          }}>Stop</Button>
          //   <Popconfirm
          //   onConfirm={() => {record.course_id ? stopAccessToCourse(record.course_id): stopAccessToCourse(record.id) }}
          //   title="Sei sicuro?" okText="Si" cancelText="No">
          //     <Button type="link" style={{ padding: "0px", color: 'red' }}>Stop</Button>
          // </Popconfirm>
          : stopedAccessCourses.includes(record.id) ?
            <Popconfirm
              onConfirm={() => { record.course_id ? giveAccessToCourse(record.course_id) : giveAccessToCourse(record.id) }}
              title="Sei sicuro?" okText="Si" cancelText="No">
              <Button type="link" style={{ padding: "0px", color: 'green' }}>Access</Button>
            </Popconfirm>
            : <Button type="link" style={{ padding: "0px", color: 'red' }} onClick={() => {
              if (record.course_id) stopAccessToCourse(record.course_id)
              else stopAccessToCourse(record.id)
            }}>Stop</Button>
          //   <Popconfirm 
          //   onConfirm={() => {record.course_id ? stopAccessToCourse(record.course_id): stopAccessToCourse(record.id) }}
          //   title="Sei sicuro?" okText="Si" cancelText="No">
          //     <Button type="link" style={{ padding: "0px", color: 'red' }}>Stop</Button>
          // </Popconfirm>
        }
      </>
    },
    {
      title: 'Elimina corsista dal corso',
      key: 'delete',
      render: (text, record) =>
        <Popconfirm
          onConfirm={() => deleteAssignedCourse(record.id)}
          title="Sei sicuro?" okText="Si" cancelText="No">
          <Button type="link" style={{ padding: "0px" }}>Elimina</Button>
        </Popconfirm>,
    }
  ];


  const handleCoursesID = (childData) => {
    setCoursesID(childData)
  }
  const handleCoursesTitle = (childData) => {
    setSelectedCourses(childData)
  }

  const handleCategoriesID = (childData) => {
    setCategoriesID(childData)
  }
  const handleCategoriesTitle = (childData) => {
    setCategoriesTitle(childData)
  }

  const hideSendEmailInput = (childData) => {
    setShowSendEmailInput(childData);
    getData()
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} justify='flex-start'>
        <Breadcrumb />

        <Snackbar open={successSubmit} autoHideDuration={4000} onClose={handleCloseSubmit} >
          <Alert2 onClose={handleCloseSubmit} severity="success">
            Email inviata correttamente!
          </Alert2>
        </Snackbar>

        <EmailInput showSendEmailInput={showSendEmailInput} closeModal={hideSendEmailInput} user_id={user_id} course_id={stopAccessToCourseId} />

        <Snackbar open={errorNetwork} autoHideDuration={4000} onClose={handleCloseSubmit} >
          <Alert2 onClose={handleCloseSubmit} severity="error">
            Qualcosa è andato storto, riprova
          </Alert2>
        </Snackbar>
        <Grid container spacing={3} item xs={12} lg={9}>
          <Grid item xs={12} lg={12}>
            <Paper>
              <Table
                size="small"
                bordered
                rowKey={record => record.id}
                loading={loading} columns={columns} dataSource={courses}

                pagination={{
                  showSizeChanger: true,
                  current: page,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }
                }}
              />
            </Paper>

            {noCourse ? <Alert severity="info">Non sei registrato in nessun corso!</Alert> : null}
          </Grid>

          <Grid item xs={12} lg={12}>
            <Paper>
              <Grid item xs={12} lg={12} style={{ background: 'lightgreen', padding: '5px' }}>
                <Typography variant='h6'> Categoria </Typography>
              </Grid>
              <TableCategories
                categoriesID={handleCategoriesID}
                categoriesTitle={handleCategoriesTitle}
                loading={loading}
                selectedRowKeys={selectedRowKeys} />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Paper>
              <Grid item xs={12} lg={12} style={{ background: 'lightgreen', padding: '5px' }}>
                <Typography variant='h6'> Corsi </Typography>
              </Grid>
              <TableCourses coursesID={handleCoursesID} coursesTitle={handleCoursesTitle}
                loading={loading}
                selectedRowKeys={selectedRowKeys} dataSourse={user_id} />
            </Paper>
          </Grid>
        </Grid>
        {studentInfo ?
          <Grid item xs={12} lg={3} >
            <Grid item xs={12} lg={12}>
              <Paper style={{ textAlign: 'center', marginBottom: '2rem' }}  >
                <Grid item xs={12} style={{ background: 'lightgreen' }}>
                  <Typography variant='h6'> Nome Corsista </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '10px' }}>
                  <Typography>  {studentInfo.name} {studentInfo.surname}</Typography>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Button type='primary' style={{ marginBottom: '1rem' }} onClick={courseReport}>Course report</Button>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Paper>
                  <ListItems listTitle={'Iscrivi utente al categoria'} listItems={categoriesTitle} />
                </Paper>
                <Paper>
                  <ListItems listTitle={'Iscrivi utente al corso'} listItems={selectedCourses} />
                </Paper>
              </Space>

            </Grid>
            <Grid item xs={12} lg={5}>
              <Button
                onClick={addAssignedCourses}
                type='primary'
                className={classes.submit}
                disabled={selectedCourses.length > 0 || categoriesTitle.length > 0 ? false : true}
                loading={adding}
                style={{ width: '100%' }}>
                Iscrivi
              </Button>
            </Grid>
          </Grid> : null}

      </Grid>
    </Container>

  );
}

export default withRouter(StudentCourseEnrolled);