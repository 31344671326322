import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Countdown from 'react-countdown';

export default function CountDown(props) {

    const ref = useRef(null);

    const [time, setTime] = useState(Date.now + 1000)


    function onTick() {
        if (ref && (((ref.current.state.timeDelta.total) / 1000) % 30) === 1) {

            props.updateTime();
        }
    }

    useEffect(() => {

        if (props.pause) {
            ref.current.pause()
        }
        else if (!props.pause) {
            ref.current.start()
        }

        if (props.timer)
            setTime(props.timer)
    }, [props])

    return (
        <Countdown autoStart={false} ref={ref} onTick={onTick} date={time} daysInHours={true} onComplete={props.completed} />
    );
}

CountDown.propTypes = {
    children: PropTypes.node,
};
