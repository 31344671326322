import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withRouter, useHistory, useParams } from "react-router-dom"
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useForm } from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from 'antd';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

function UpdateQuestion(props) {

    const { course_id } = useParams()
    const { section_id } = useParams()
    const { question_id } = props.location.state

    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [notSaved, setNotSaved] = useState(false);
    const [errorScore, setErrorScore] = useState(false);
    const [errorCorrectAnswer, setErrorCorrectAnswer] = useState(false);
    const [loading, setLoading] = useState(false);
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const { handleSubmit } = useForm()
    const [answersType, setAnswersType] = useState(null);



    const [oldAnswers, setOldAnswers] = useState([]);
    const [oldQuestion, setOldQuestion] = useState(null);

    const [errorQuestion, setErrorQuestion] = useState(false);


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleChangeInput = (index, event) => {
        const values = [...oldAnswers]
        if (event.target.id === "correct_answer") {
            if (event.target.checked === true)
                values[index][event.target.id] = true
            else { values[index][event.target.id] = false }
        }
        else {
            values[index][event.target.id] = event.target.value
        }

        setOldAnswers(values)

    }

    const handleAddAnswer = () => {
        setOldAnswers([...oldAnswers, { answers: '', score: "0", correct_answer: false }])
    }

    const handleRemoveAnswer = (index) => {
        if (index !== 0 && index !== 1) {
            const values = [...oldAnswers]
            values.splice(index, 1)
            setOldAnswers(values)
        }
    }


    useEffect(() => {

        getData()

    }, [])

    async function getData() {

        if (section_id === '0') {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUpdateQuestionFinal/${question_id}`).then(
                response => response.json()).then(
                    json => setOldQuestion(json)
                )
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUpdateAnswerFinal/${question_id}`).then(
                response => response.json()).then(
                    json => setOldAnswers(json),
                )
        }
        else {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUpdateQuestion/${question_id}`).then(
                response => response.json()).then(
                    json => setOldQuestion(json)
                )
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUpdateAnswer/${question_id}`).then(
                response => response.json()).then(
                    json => setOldAnswers(json),
                )
        }
    }

    function cancel() {
        history.goBack(1)
    }

    const handleChangeType = (event) => {
        setAnswersType(event.target.value)
    };


    const addtest = async data => {

        setLoading(true)
        if (oldQuestion.question === '' || oldQuestion.score === '0') {
            setErrorQuestion(true)
            setLoading(false)

        }

        else if (oldAnswers.filter(item => item.answers === '').map((item, index) => index).toString()) {
            setError(true)
            setLoading(false)

        } else if (oldAnswers.filter(item => item.score === "0").map((item, index) => index).length === oldAnswers.length) {
            setErrorScore(true)
            setLoading(false)

        }
        else if (oldAnswers.filter(item => item.correct_answer === false || item.correct_answer === 0).map((item, index) => index).length === 0) {
            setErrorCorrectAnswer(true)
            setLoading(false)

        }
        else {

            const formData = new FormData();

            formData.append('question_id', question_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)
            formData.append('question', oldQuestion.question)
            formData.append('score', oldQuestion.score)
            formData.append('answers', JSON.stringify(oldAnswers))
            if (answersType)
                formData.append("answers_type", answersType);

            if (section_id === '0') {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateQuestAnswFinal`, {
                    method: "POST",
                    body: formData
                }).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === 'success') {
                                setLoading(false)
                                history.goBack(1)
                            } else {
                                setLoading(false)
                                setNotSaved(true)
                            }
                        }
                    )
            }
            else {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateQuestAnsw`, {
                    method: "POST",
                    body: formData
                }).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === 'success') {
                                setLoading(false)
                                history.goBack(1)
                            } else {
                                setLoading(false)
                                setNotSaved(true)
                            }
                        }
                    )
            }

        }


    };

    const style = {
        padding: '20px',
    }

    const gridStyle = {
        display: 'flex',
        justifyContent: 'space-between'
    }

    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
        setErrorScore(false);
        setErrorCorrectAnswer(false)
        setNotSaved(false)
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='flex-end' >
                <Breadcrumb />

                <Grid item xs={12} lg={12}>
                    <Paper className={maxHeightPaper} style={style}>
                        <Typography variant="h6" className={classes.title}>
                            Aggiorna domande
                        </Typography>
                        {oldQuestion && oldAnswers.length > 0 ?
                            <form className={classes.root} onSubmit={handleSubmit(addtest)} noValidate autoComplete="off">
                                <Grid item xs={12} lg={12} style={{ marginBottom: '20px', display: 'flex' }}>
                                    <Grid item xs={12} sm={10} lg={10} style={{ marginRight: '2rem' }}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="outlined-full-width"
                                            label="Domande"
                                            placeholder="Domande"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            size='small'
                                            onChange={(e) => setOldQuestion({ question: e.target.value, score: oldQuestion.score })}
                                            variant="outlined"
                                            defaultValue={oldQuestion.question}
                                            error={errorQuestion}
                                            helperText={errorQuestion ? 'Il campo è obbligatorio' : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} lg={1}>
                                        <TextField
                                            size='small'
                                            required
                                            id="outlined-full-width"
                                            label="Punteggio"
                                            placeholder="Punteggio"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(e) => setOldQuestion({ question: oldQuestion.question, score: e.target.value })}
                                            type="number"
                                            variant="outlined"
                                            id="score"
                                            defaultValue={oldQuestion.score}
                                            error={errorQuestion}
                                            helperText={errorQuestion ? 'Il campo è obbligatorio' : null}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sx={12} sm={12} lg={12}>
                                    <InputLabel htmlFor="age-native-helper">Choose answers type</InputLabel>
                                    <NativeSelect
                                        required
                                        onChange={handleChangeType}
                                        inputProps={{
                                            name: 'type',
                                        }}
                                        value={answersType ? answersType : oldQuestion.answers_type}
                                    >
                                        <option value='OneAnswer'>One answer</option>
                                        <option value='MultipleAnswers'>Multiple answers</option>
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                                    <Typography variant="h6" className={classes.title}>
                                        Risposte
                                    </Typography>
                                    <Typography className={classes.title}>
                                        Risposte esatte
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    {oldAnswers.map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={12} lg={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography color='primary' style={{ marginRight: '1rem' }}>
                                                    {index + 1}
                                                </Typography>
                                                <Grid item xs={12} sm={10} lg={10} style={{ marginRight: '2rem' }}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        required
                                                        id="outlined-full-width"
                                                        label={index + 1}
                                                        placeholder="risposte"
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={item.answers}
                                                        variant="outlined"
                                                        id="answers"
                                                        onChange={event => handleChangeInput(index, event)}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2} lg={1}>
                                                    <TextField
                                                        required
                                                        size='small'
                                                        id="outlined-full-width"
                                                        label="Punteggio"
                                                        placeholder="punteggio"
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={0}
                                                        type="number"
                                                        variant="outlined"
                                                        id="score"
                                                        defaultValue={item.score}
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        onChange={event => handleChangeInput(index, event)}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2} lg={1}>
                                                    <Checkbox
                                                        checked={item.correct_answer === 1 || item.correct_answer === true}
                                                        id="correct_answer"
                                                        onChange={(event) => handleChangeInput(index, event)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}

                                                    />
                                                    {index >= 2 ?
                                                        <IconButton onClick={() => handleRemoveAnswer(index)}>
                                                            <RemoveIcon />
                                                        </IconButton> : null}
                                                </Grid>


                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12} sm={8} lg={12} style={{ marginBottom: '3rem', marginRight: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Fab size="small" style={{ background: '#1890ff', color: '#fff' }} aria-label="add" onClick={() => handleAddAnswer()} >
                                            <AddIcon />
                                        </Fab>

                                    </Grid>
                                    <Snackbar open={notSaved} autoHideDuration={2000} onClose={handleError} >
                                        <Alert onClose={handleError} severity="error">
                                            Something went wrong, please try again!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={error} autoHideDuration={2000} onClose={handleError} >
                                        <Alert onClose={handleError} severity="error">
                                            Please, add an answer!!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={errorScore} autoHideDuration={2000} onClose={handleError} >
                                        <Alert onClose={handleError} severity="error">
                                            Add at least one score for an answer!!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={errorCorrectAnswer} autoHideDuration={2000} onClose={handleError} >
                                        <Alert onClose={handleError} severity="error">
                                            Set at least one correct answer!!
                                        </Alert>
                                    </Snackbar>
                                </Grid>

                                <Grid item xs={12} lg={12} style={gridStyle}>
                                    <Button
                                        style={{ width: '120px' }}
                                        className={classes.button}
                                        onClick={cancel}>
                                        Cancella
                                    </Button>

                                    <Button
                                        loading={loading}
                                        style={{ width: '120px' }}
                                        type='primary'
                                        className={classes.button}
                                        htmlType='submit'>
                                        Salva
                                    </Button>


                                </Grid>
                            </form> : null}
                    </Paper>
                </Grid>

            </Grid>


        </Container>


    );
}

export default withRouter(UpdateQuestion);
