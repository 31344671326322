import { withRouter, useHistory, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';
import GuestBreadcrumb from "../components/Breadcrumb/GuestBreadcrumb";
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import { useStyles } from "../components/MainTheme.js";
import { Button, Collapse } from "antd";


function GuestPdf(props) {

    const classes = useStyles();

    const { section_id } = useParams()
    const { module_id } = useParams()
    const { course_id } = useParams()
    const { course_name } = useParams()
    const { section_name } = useParams()
    const history = useHistory();

    const [downloadcheck, setDownload] = useState('');
    const [url, setUrl] = useState(null)


    const { Panel } = Collapse;
    const [description, setDescription] = useState(null);

    const [modules, setModules] = useState(null);





    useEffect(() => {
        getData()

    }, [])


    async function getData() {

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getGuestModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setUrl(`${process.env.REACT_APP_API_BASE_URL}/guestDownload/${path.module_path}/${course_id}`)
                }
            )
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/guestCheckDownload/${course_id}/${module_id}`).then(
            response => response.json()).then(
                download => {
                    if (download.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setDownload(download)
                }
            )

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/getGuestModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )


    }



    const renderToolbar = (Toolbar: ((props: ToolbarProps) => ReactElement)) => (
        <Toolbar>
            {
                (slots: ToolbarSlot) => {
                    const {
                        CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage,
                        NumberOfPages, Zoom, ZoomIn,
                        ZoomOut,
                    } = slots;
                    return (
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >

                            <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <CurrentPageInput /> / <NumberOfPages />
                            </div>
                            <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <EnterFullScreen />
                            </div>


                            {downloadcheck && downloadcheck === 1 ?
                                <div style={{ padding: '0px 2px' }}>
                                    <Download />
                                </div> : null}

                        </div>
                    )
                }
            }
        </Toolbar>
    );


    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: defaultTabs => [defaultTabs[0]],
        renderToolbar


    });


    useEffect(() => {

        setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
            return item.description;
        }))
    }, [modules]);

    function nextModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })


        if (type === 'pdf') {
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            window.location.reload(false);
        }

        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`
            });
        if (type === 'ppt')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }
    function prevModule() {
        var index;
        var id;
        var type;
        var module_name;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
        modules && modules.map((item, i) => {
            if (i === index) {
                id = item.id
                type = item.type
                module_name = item.module_name
            }
            return true;
        })


        if (type === 'pdf') {
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });
            window.location.reload(false);
        }

        if (type === 'video' || type === 'video_to_server')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`
            });
        if (type === 'ppt')
            history.push({
                pathname: `/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
            });

    }


    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <GuestBreadcrumb />

                </Grid>
                {url ?
                    <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                            : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}>Precedente</Button>}
                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                            : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}

                    </Grid> : null}

                {url ? <Grid item xs={12} lg={10}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '480px',
                        }}>

                            <Viewer
                                fileUrl={url}
                                defaultScale={SpecialZoomLevel.ActualSize}
                                plugins={[
                                    defaultLayoutPluginInstance

                                ]}
                            />
                        </div>
                    </Worker>
                </Grid> : null}
                {url ?
                    <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
                        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
                            : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}>Precedente</Button>}
                        {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
                            : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}

                    </Grid> : null}

                {url && description && (description[0] !== null) ?
                    <Grid item xs={12} lg={10} style={{ marginTop: '2rem' }}>
                        <Collapse accordion>
                            <Panel header="Informazioni aggiuntive" key="1">
                                <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                                    {description}
                                </Typography>
                            </Panel>

                        </Collapse>
                    </Grid>
                    : null}

            </Grid>

        </Container>

    );
}

export default withRouter(GuestPdf);
