import { withRouter, useParams, useHistory } from "react-router-dom";
import React, { useState, ReactElement, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../components/MainTheme.js";
import { Button, Collapse } from "antd";
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Typography from '@material-ui/core/Typography';


function ReadPdf(props) {
  const { module_id } = useParams();
  const { course_id } = useParams();
  const { section_id } = useParams();
  const { course_name } = useParams();
  const { section_name } = useParams();
  const { Panel } = Collapse;

  const history = useHistory();
  const [download, setDownload] = useState(false);
  const [modules, setModules] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [url, setUrl] = useState(null);
  const [description, setDescription] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulePathAdmin/${module_id}`)
      .then((response) => response.json())
      .then((path) => setUrl(`${process.env.REACT_APP_API_BASE_URL}/downloadAdmin/${path.module_path}`));

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkDownloadAdmin/${module_id}`)
      .then((response) => response.json())
      .then((download) => {
        setDownload(download);
      });

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getModulesAdmin/${section_id}`)
      .then((response) => response.json())
      .then((json) => setModules(json));
  }

  useEffect(() => {

    setDescription(modules && modules.filter(item => item.id === parseInt(module_id)).map((item) => {
      return item.description;
    }))
  }, [modules]);

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          SwitchSelectionMode,
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <Grid item xs={12} lg={12} style={{ display: "flex" }}>
            <div className={classes.nextPrev} style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <CurrentPageInput /> / <NumberOfPages />
            </div>
            <div className={classes.nextPrev} style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <ZoomIn />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>

            {download && download === 1 ? (
              <div style={{ padding: "0px 2px" }}>
                <Download />
              </div>
            ) : null}
            {/* <div style={{ padding: '0px 2px', marginLeft: 'auto' }} >
                                <SwitchSelectionMode  mode={SelectionMode.Hand} />
                            </div>
            <div style={{ padding: '0px 2px' }}>
                                <SwitchSelectionMode mode={SelectionMode.Text} >
                              
                                </SwitchSelectionMode>
                            </div> */}

          </Grid>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar
  });


  function nextModule() {
    var index;
    var id;
    var type;
    var module_name;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) + 1;
    modules && modules.map((item, i) => {
      if (i === index) {
        id = item.id
        type = item.type
        module_name = item.module_name
      }
      return true;
    })


    if (type === 'pdf') {
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
      window.location.reload(false);
    }

    if (type === 'video' || type === 'video_to_server')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
    if (type === 'ppt')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });

  }
  function prevModule() {
    var index;
    var id;
    var type;
    var module_name;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) - 1;
    modules && modules.map((item, i) => {
      if (i === index) {
        id = item.id
        type = item.type
        module_name = item.module_name
      }
      return true;
    })


    if (type === 'pdf') {
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/pdf-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
      window.location.reload(false);
    }

    if (type === 'video' || type === 'video_to_server')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/video-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
    if (type === 'ppt')
      history.push({
        pathname: `/admin/corsi/${course_name}/${course_id}/${section_name}/${section_id}/ppt-page/${module_name.toLowerCase().replaceAll(' ', "-")}/${id}`,
      });
  }

  function handlPageChange(event) {
    setCurrentPage(event.currentPage)
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={12} lg={10}>
          <Breadcrumb />
        </Grid>
        {url ?
          <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
            >precedente</Button>}
            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
              : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
          </Grid> : null}
        {url ? (
          <Grid item xs={12} lg={10}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "480px",
                }}
              >

                <Viewer fileUrl={url}
                  initialPage={currentPage}
                  onPageChange={handlPageChange}
                  defaultScale={SpecialZoomLevel.ActualSize}
                  plugins={[defaultLayoutPluginInstance]} />

              </div>
            </Worker>
          </Grid>
        ) : null}
        {url ?
          <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'end' }}>
            {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{ marginRight: '20px' }}
            >precedente</Button>}
            {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id)) + 1 === modules.length ? null
              : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
          </Grid > : null}
        {url && description && (description[0] != null) ?
          <Grid item xs={12} lg={10} style={{ marginTop: '2rem' }}>
            <Collapse accordion>
              <Panel header="Informazioni aggiuntive" key="1">
                <Typography variant="h6" color="inherit" style={{ fontWeight: '400', fontSize: '1rem' }}>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Typography>
              </Panel>

            </Collapse>
          </Grid>
          : null}


      </Grid>
    </Container>
  );
}

export default withRouter(ReadPdf);
