import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "../components/MainTheme.js";
import Context from '../Context'
import { Button, Alert } from 'antd';
import { useForm } from "react-hook-form";
import InputAdornment from '@material-ui/core/InputAdornment';
import { UserOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';

export default function ForgetPassword() {

        const history = useHistory();

        const classes = useStyles();
        const [error, seterror] = useState(false);
        const [success, setSuccess] = useState(false);

        const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm()


        const onSubmit = async data => {

                const formData = new FormData();

                formData.append('email', data.email)

                let result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/forgotPassword`, {
                        method: "POST",
                        body: formData,
                });

                result = await result.json();



                if (result.msg === "success") {
                        setSuccess(true)
                        seterror(false)
                        reset();

                } else {
                        seterror(true)
                        setSuccess(false)

                }

        }

        return (
                <Container component="main" maxWidth="sm">
                        <Grid container spacing={3} justify='center' style={{ marginTop: "6rem" }}>

                                <Grid item xs={12} sm={12} lg={12} >
                                        <Paper >
                                                <Typography style={{ padding: '10px 0 10px 10px', backgroundColor: '#e0e0e0' }}>

                                                        Reset password
                                                </Typography>

                                                <form className={classes.form} style={{ padding: '0px 30px 30px 30px' }} noValidate onSubmit={handleSubmit(onSubmit)}>

                                                        {error ?
                                                                <Alert message="Indirizzo email non valido" type="error" showIcon />
                                                                : null}
                                                        {success ?
                                                                <Alert message="Abbiamo inviato alla tua mail il link per recuperare la password" type="success" showIcon />
                                                                : null}


                                                        <TextField
                                                                {...register('email', {
                                                                        required: true,
                                                                        pattern: {
                                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                                message: "email non corretti"
                                                                        }
                                                                })}
                                                                required
                                                                variant="outlined"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                size='small'
                                                                id="email"
                                                                label="Indirizzo email"
                                                                name="email"
                                                                autoComplete="email"
                                                                autoFocus
                                                                type='email'
                                                                InputProps={{
                                                                        startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                        <UserOutlined fontSize="small" />
                                                                                </InputAdornment>
                                                                        ),


                                                                }}
                                                                error={(errors?.email?.type === "required" && true) || (errors?.email?.message)}
                                                                helperText={(errors?.email?.type === "required" && 'Il campo è obbligatorio') || (errors?.email?.message && "invalid email address")}
                                                        />
                                                        <Button
                                                                htmlType="submit"
                                                                type="primary"
                                                                block={true}
                                                                size='middle'
                                                                className={classes.submit}
                                                                loading={isSubmitting}
                                                                style={{
                                                                        whiteSpace: 'normal',
                                                                        height: '100%',
                                                                        width: '90%'
                                                                }}
                                                        >
                                                                Invia un link per recuperare la password sulla mia mail
                                                        </Button>
                                                        <Grid container>
                                                                <Grid item xs>
                                                                        <Link href="/entra" variant="body2">
                                                                                Accedi?
                                                                        </Link>
                                                                </Grid>

                                                        </Grid>

                                                </form>

                                        </Paper>
                                </Grid>
                        </Grid>
                </Container>

        );
}