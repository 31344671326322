import { withRouter, useHistory, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { Upload, Button, Image, Space, Input, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import ControlledEditor from "../components/EditorContainer"
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';

function UpdateCourse(props) {

  const { course_id } = useParams()
  const history = useHistory();
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [image, setImage] = useState("");
  const [displayImage, setDisplayImage] = useState(null);
  const [certificateInfo, setCertificateInfo] = useState([]);
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [courseType, setCourseType] = useState("Normal");
  const [releaseCertificat, setReleaseCertificat] = useState(false);
  const [hasECP, setHasECP] = useState(false);
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [certificateType, setCertificateType] = useState(null);
  const [getType, setGetType] = useState(false);

  const [course, setCourse] = useState(null);
  const [data, setData] = useState(null);
  const [customUrl, setCustomUrl] = useState(null);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm()

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }



  useEffect(() => {
    getData()

  }, [])

  async function getData() {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCourse/${course_id}`).then(
      response => response.json()).then(
        json => {
          setData(json)
          setContent(json.description)
          setCourse(json)
          setTitle(json.title)
          setDescription(json.description)
          setCourseType(json.type)
        }
      )

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificateAdmin/${course_id}`).then(
      response => response.json()).then(
        json => {
          setCertificateInfo(json)
          if (json.length > 0) {
            setReleaseCertificat(true)
            if (json[0].authorisation_number) setHasECP(true)
          }
        }
      ).then(() => setCheckboxValue(true))

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCertificateTypeAdmin/${course_id}`)
      .then((response) => response.json())
      .then(json => {
        if (json.length > 0) {
          setCertificateType(json[0].certificate_type)
          setCustomUrl(json[0].custom_url)
        }
        setGetType(true)
      })
  }


  const onSubmit = async data => {

    if (hasECP && !data.authorisation_number) {
      setErrorNumber(true)
    }
    else {

      setLoading(true)

      const formData = new FormData();

      formData.append('title', data.course_name)
      formData.append('course_id', course_id)
      formData.append('product_id', data.product_id)
      formData.append('type', courseType)
      formData.append('certificate_type', certificateType)
      formData.append('custom_url', customUrl)

      // if(releaseCertificat)
      // {
      //   formData.append('has_certificate', releaseCertificat)
      //   formData.append('hasECP', hasECP)
      //   formData.append('ecp', data.ecp)
      //   if(data.authorisation_number)
      //   formData.append('authorisation_number', data.authorisation_number)
      // }
      if (image)
        formData.append('file', image)
      if (description && description.trim().length !== 7)
        formData.append('description', description)

      await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateCourse`, {
        method: "POST",
        body: formData
      }).then(
        response => response.json()).then(
          json => {
            if (json.msg === 'success') {
              setLoading(false)
              history.push("/admin/corsi")
            }

          })
    }
  }


  const handleChangeType = (event) => {
    setCourseType(event.target.value)
  };


  const file = {
    beforeUpload: file => {
      setImage(file)
      setDisplayImage(URL.createObjectURL(file))

      return false;
    }
  };

  function removedImage() {
    setDisplayImage(null)
  }

  const handleCallback = (childData) => {
    setDescription(childData)
  }
  function cancel() {
    history.goBack()
  }

  function ChangeCertificatAdd(e) {
    setReleaseCertificat(e.target.checked)
  }


  function ChangeHasECP(e) {
    setHasECP(e.target.checked)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorNumber(false);
  }

  const onChange = (e) => {
    if (e.target.value === 'certificazione delle competenze' || e.target.value === 'certificato di partecipazione') setCustomUrl(null)
    setCertificateType(e.target.value);
  };

  const onChangeCustomUrl = (e) => {
    setCustomUrl(e.target.value);
  };

  return (

    <Container maxWidth="lg">
      <Grid item xs={12} sm={12} lg={12}>
        <Snackbar open={errorNumber} autoHideDuration={3000} onClose={handleClose} >
          <Alert onClose={handleClose} severity="error">
            Error Authorisation number
          </Alert>
        </Snackbar>
        {course ?
          <Paper style={{ padding: '16px' }}>
            <Typography variant="h6" className={classes.title} style={{ marginBottom: '1rem' }}>
              Aggiorna corso
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item sx={12} sm={12} lg={12}>
                  <TextField
                    {...register('course_name', { required: true })}
                    size="small"
                    fullWidth
                    variant='outlined'
                    required
                    id="standard-helperText"
                    label="Nome del Corso"
                    defaultValue={data.title}
                    error={errors?.course_name?.type === "required" && true}
                    helperText={errors?.course_name?.type === "required" && 'Il campo è obbligatorio'}
                  />
                </Grid>
                <Grid item sx={12} sm={12} lg={12}>
                  <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item sx={12} sm={12} lg={12}>
                      <InputLabel htmlFor="age-native-helper">Tipologia Corso</InputLabel>
                      <NativeSelect
                        required
                        fullWidth
                        value={courseType}
                        onChange={handleChangeType}
                        inputProps={{
                          name: 'type',
                        }}
                      >
                        <option value='Normal'>Corsi sola visualizzazione</option>
                        <option value='Mandatory' >Formazione Obbligatoria (con Timer)</option>
                      </NativeSelect>
                      <FormHelperText>Seleziona la tipologia</FormHelperText>
                    </Grid>
                    <TextField
                      {...register('product_id', { required: true })}
                      required
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Product id"
                      id="standard-size-small"
                      size="small"
                      variant='outlined'
                      defaultValue={data.product_id}
                      error={errors?.product_id?.type === "required" && true}
                      helperText={errors?.product_id?.type === "required" && 'Il campo è obbligatorio'}
                    />
                  </Space>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <InputLabel htmlFor="age-native-helper">Descrizione Corso </InputLabel>
                  <ControlledEditor description={handleCallback} content={content} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Image
                        width={300}
                        src={displayImage ? displayImage : `${process.env.REACT_APP_API_BASE_URL_IMAGE}/${course.picture_path}`}
                      />
                    </Grid>
                    {/* <Space direction="vertical">
                    {checkBoxValue ? <Checkbox defaultChecked={releaseCertificat} onChange={(e) => ChangeCertificatAdd(e)} >Release certificat</Checkbox> : null}
                    {releaseCertificat && checkBoxValue ? <Checkbox onChange={(e) => ChangeHasECP(e)} defaultChecked={hasECP}
                    >Has ECP</Checkbox> : null}
                    {hasECP && releaseCertificat? 
                     <Space direction="vertical">
                       <Input defaultValue={certificateInfo.map((item)=> {return item.ecp})} placeholder="ECP" type='number'
                       {...register('ecp', setValue('ecp', certificateInfo.map((item)=> {return item.ecp})[0]) , { required: true })} onChange={(e) => setValue('ecp', e.target.value)}/>
                       {errors.ecp && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                        <Input defaultValue={certificateInfo.map((item)=> {return item.authorisation_number})}  
                        placeholder="Authorisation number" 
                       {...register('authorisation_number', setValue('authorisation_number', certificateInfo.map((item)=> {return item.authorisation_number})[0]), { required: true })} onChange={(e) => setValue('authorisation_number', e.target.value)}/>
                       {errors.authorisation_number && <span style={{ color: 'red' }}>Il campo è obbligatorio</span>}
                    </Space>: null}

                      </Space> */}
                    {getType ?
                      <Space direction="vertical">
                        <Radio.Group onChange={onChange} defaultValue={certificateType}>
                          <Space direction="vertical">
                            <Radio value={'corsi normati'}>corsi normati</Radio>
                            <Radio value={'certificato di partecipazione'}>certificato di partecipazione</Radio>
                            <Radio value={'certificazione delle competenze'}>certificazione delle competenze</Radio>
                            <Radio value={'acquista corso completo'}>acquista corso completo</Radio>
                            {certificateType === 'acquista corso completo' ? <Input defaultValue={customUrl} key={1} allowClear onChange={onChangeCustomUrl} /> : null}

                          </Space>
                        </Radio.Group>
                      </Space> : null}
                  </Space>
                </Grid>
                <Grid item sx={12} sm={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>

                  <Upload
                    {...file}
                    listType="picture"
                    maxCount={1}
                    accept='image/*'
                    onRemove={removedImage}
                  >
                    <Button icon={<UploadOutlined />} >Immagine del Corso</Button>
                  </Upload>


                  <Button
                    htmlType="submit"
                    type="primary"
                    className={classes.button}
                    style={{
                      width: '120px',
                    }}
                    loading={loading}
                  >
                    Salva
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => cancel()}
                    style={{
                      width: '120px',
                    }}
                    className={classes.button}>
                    Cancella
                  </Button>
                </Grid>
              </Grid>
            </form>

          </Paper>
          : null}
      </Grid>

    </Container>

  );
}

export default withRouter(UpdateCourse);
